import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, Box } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const segmentTypes = [
  { label: "Alphanumeric", value: "alphanumeric" },
  { label: "Fixed", value: "fixed" },
  { label: "Numeric", value: "numeric" },
  { label: "Alphabets", value: "alphabets" },
];

const CustomRulesEditor = ({ control, watch, name, propertyIndex }) => {
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `${name}.value`,
  });

  const location = useLocation();

  const {
    trigger,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useFormContext();

  const selectedAttributeIndex = useSelector((state) => state.attributes.selectedAttributeIndex);

  const selectedTableIndex = useSelector((state) => state.attributes.selectedTableIndex);

  const fixedLength = watch(name, []).find((prop) => prop.key === "fixed")?.value;

  const useCustomRules = watch(name, []).find((prop) => prop.key === "useCustomRules")?.value;
  const customRules = watch(name, []).find((prop) => prop.key === "customFormattingRules")?.value;

  const fixedLengthError = location.pathname.includes("synthetic")
    ? errors?.attributes?.[selectedAttributeIndex]?.properties?.find((prop) => prop?.value)?.value
    : errors?.selectedTables?.[selectedTableIndex]?.attributes?.[selectedAttributeIndex]?.properties?.find(
        (prop) => prop?.value,
      )?.value;

  const isFixedLengthValid = !isNaN(parseInt(fixedLength, 10)) && parseInt(fixedLength, 10) > 0 && !fixedLengthError;

  const customFormattingRulesPath = `${name}[${propertyIndex}]`;

  const ruleLengths = watch(name, [])
    ?.find((prop) => prop.key === "customFormattingRules")
    ?.value?.map((rule) => parseInt(rule.length, 10) || 0);

  const totalRulesLength = ruleLengths?.reduce((total, length) => total + length, 0);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (useCustomRules && customRules.length > 0 && fields.length === 0) {
      replace(customRules);
    }
  }, [useCustomRules, customRules, fields.length, replace]);

  useEffect(() => {
    if (useCustomRules && fields.length > 0 && totalRulesLength !== parseInt(fixedLength, 10)) {
      if (isFixedLengthValid) {
        setError(`totalLength`, {
          type: "manual",
          message: `Total length of rules (${totalRulesLength}) does not match the fixed length (${fixedLength}).`,
        });
      } else {
        clearErrors(`totalLength`);
      }
    } else {
      clearErrors(`totalLength`);
    }
  }, [totalRulesLength, fixedLength, setError, clearErrors, name, useCustomRules, fields, isFixedLengthValid]);

  const addNewRule = () => {
    if (totalRulesLength < parseInt(fixedLength, 10)) {
      const newRule = {
        segmentType: segmentTypes[0].value,
        content: "",
        length: "",
      };
      append(newRule);
    }
  };

  const removeRule = (index) => {
    remove(index);
  };

  return (
    <Box sx={{ my: 1 }}>
      {fields.map((rule, index) => (
        <div
          key={`custom-rule-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "8px",
            gap: "8px",
          }}>
          <Controller
            control={control}
            rules={{ required: "Segment type is required" }}
            defaultValue={rule.segmentType}
            name={`${customFormattingRulesPath}.value[${index}].segmentType`}
            render={({ field }) => (
              <TextField {...field} label="Segment Type" size="small" fullWidth select>
                {segmentTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          {watch(`${customFormattingRulesPath}.value[${index}].segmentType`) === "fixed" ? (
            <Controller
              control={control}
              name={`${customFormattingRulesPath}.value[${index}].content`}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Content"
                  size="small"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setValue(`${customFormattingRulesPath}.value[${index}].length`, e.target.value.length);
                  }}
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name={`${customFormattingRulesPath}.value[${index}].length`}
              render={({ field }) => <TextField {...field} label="Length" type="number" size="small" />}
            />
          )}
          <IconButton onClick={() => removeRule(index)} color="error">
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={addNewRule}
        disabled={!isFixedLengthValid || !useCustomRules || totalRulesLength >= parseInt(fixedLength, 10)}>
        Add Rule
      </Button>

      {!isFixedLengthValid && useCustomRules && (
        <Alert severity="error">Please provide a valid fixed length to add rules.</Alert>
      )}

      {errors?.totalLength && <Alert severity="error">{errors.totalLength?.message}</Alert>}
    </Box>
  );
};

export default CustomRulesEditor;
