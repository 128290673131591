import React, { useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import { CancelOutlined } from "@mui/icons-material";
import { reorderAttributes, setCurrentSelectedAttributeIndex } from "../app/features/attributesSlice";
import { Box, IconButton } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { useDrop } from "react-dnd";
import { useFormContext } from "react-hook-form";
import { definePropertiesBasedOnAttributes } from "../utils/formInfo";

const DroppableArea = ({ watch, finalErrors, appendAttribute, fields, remove }) => {
  const { getValues, setValue } = useFormContext();

  const cardRef = useRef(null);
  const attributeCustomNames = watch("attributes", []).map((attr) => attr.attributeCustomName);

  const entityRelations = watch("entityRelations", []);

  const selectedAttributes = useSelector((state) => state.attributes.selectedAttributes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fields?.length === 0) {
      dispatch(setCurrentSelectedAttributeIndex(null));
    } else {
      dispatch(setCurrentSelectedAttributeIndex(fields?.length - 1));
    }
  }, [dispatch, fields]);

  const handleRemoveAttribute = (item, index) => {
    if (item?.isForeignKey) {
      const filteredRelations = entityRelations.filter(
        (relation) => relation?.selectedTables[0]?._id !== item?.entityId,
      );
      setValue("entityRelations", filteredRelations);
    }
    remove(index);
    dispatch(setCurrentSelectedAttributeIndex(null));
  };

  const [, drop] = useDrop(() => ({
    accept: "attribute",
    drop: async (item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }

      const currentFields = getValues().attributes;
      const dropPositionIndex = calculateDropIndex(clientOffset, currentFields);
      if (item.isNew) {
        delete item.icon;
        delete item.isNew;
        const newAttribute = {
          ...item,
          _id: nanoid(),
          properties: definePropertiesBasedOnAttributes(item?.attributeName, false),
          IsPrimaryKey: false,
        };
        appendAttribute({ ...newAttribute });
      } else {
        const currentIndex = selectedAttributes.findIndex((attr) => attr._id === item._id);
        if (currentIndex !== dropPositionIndex) {
          dispatch(
            reorderAttributes({
              oldIndex: currentIndex,
              newIndex: dropPositionIndex,
            }),
          );
        }
      }
    },
    collect: (monitor) => ({
      isOver: Boolean(monitor.isOver()),
    }),
  }));
  const calculateDropIndex = useCallback((clientOffset, items) => {
    if (!cardRef.current || !cardRef.current.parentNode) {
      return 0;
    }

    const droppableRect = cardRef.current.parentNode.getBoundingClientRect();
    const itemRect = cardRef.current.getBoundingClientRect();
    const gapSize = 8;
    const scrollY = cardRef.current.parentNode.scrollTop;
    const scrollX = cardRef.current.parentNode.scrollLeft;

    const columnWidth = itemRect.width + gapSize;
    const itemsPerRow = Math.floor(droppableRect.width / columnWidth);

    const relativeY = clientOffset.y - droppableRect.top + scrollY;
    const relativeX = clientOffset.x - droppableRect.left + scrollX;

    let column = Math.floor(relativeX / columnWidth);
    let row = Math.floor(relativeY / (itemRect.height + gapSize));

    column = Math.max(0, Math.min(column, itemsPerRow - 1));
    row = Math.max(0, row);
    const indexRow = row * itemsPerRow;
    let newIndex = indexRow + column;
    newIndex = Math.max(0, Math.min(newIndex, items.length));
    return newIndex;
  }, []);

  const handleClickAttribute = (attribute, index) => {
    dispatch(setCurrentSelectedAttributeIndex(index));
  };

  return (
    <>
      <Typography variant="h6" mb={1}>
        Attributes{" "}
      </Typography>
      {fields?.length > 0 ? (
        <Box
          ref={drop}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gap: "8px",
            overflowY: "auto",
            backgroundColor: "#fff",
            padding: "12px",
            borderRadius: "4px",
          }}>
          {fields?.map((item, index) => {
            const hasError = finalErrors?.includes(item?._id) || !item.attributeName || !attributeCustomNames[index];
            const isForeignKey = item?.isForeignKey ? item?.isForeignKey : false;
            return (
              <React.Fragment key={index}>
                <Box
                  ref={cardRef}
                  sx={{
                    background: isForeignKey ? "linear-gradient(to right,  #9e00d7, #80daeb)" : "#fff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: "none",
                    p: 1,
                    borderRadius: 1,
                    border: hasError ? "1px solid #F9594B" : "1px solid #09A057",
                    cursor: "pointer",
                    "& h6": {
                      color: isForeignKey ? "white" : hasError ? "#F9594B" : "#09A057",
                    },
                  }}
                  onClick={() => handleClickAttribute(item, index)}
                  className="droppedItemMain">
                  {/* Box content */}
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}>
                      {attributeCustomNames[index]}
                    </Typography>
                    <Typography sx={{ color: isForeignKey && "white" }}>{item.attributeName}</Typography>
                  </Box>
                  {isForeignKey && (
                    <Box
                      sx={{
                        borderRadius: "50%",
                        background: "white",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 1,
                      }}>
                      <Typography sx={{ fontWeight: "bold" }}>FK</Typography>
                    </Box>
                  )}

                  <IconButton
                    color="error"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveAttribute(item, index);
                    }}>
                    <CancelOutlined />
                  </IconButton>
                </Box>
              </React.Fragment>
            );
          })}
        </Box>
      ) : (
        <Box
          ref={drop}
          style={{
            border: "2px dashed #ccc",
            padding: "2rem",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography sx={{ textAlign: "center", fontSize: 16 }}>Drag and drop attributes here</Typography>
        </Box>
      )}
    </>
  );
};

export default DroppableArea;
