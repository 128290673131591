import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const CustomTable = ({ data: columnData = [], headCells = [] }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="column information table">
        <TableHead>
          <TableRow>
            {headCells?.map((header, indx) => (
              <TableCell key={indx} align="left" sx={{ fontWeight: "bold" }}>
                {header === "key" ? "Attribute" : header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {columnData?.map((columnData, index) => (
            <TableRow key={index}>
              {headCells?.map((column) => (
                <TableCell key={column} align="left">
                  {columnData[column]?.toString()}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
