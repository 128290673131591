import { useState } from "react";
import { Box, Drawer, Typography } from "@mui/material";
import CreateConnection from "../CreateDS/CreateConnection";
import { customComparator } from "../../AgGrid/CustomSort";

const headCellsDSDetails = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
    maxWidth: 50,
  },
  {
    field: "connectionName",
    headerName: "Data Source Name",
    cellRenderer: AgGridTitle,
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "connectionType",
    headerName: "Database Type",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "server",
    headerName: "Server",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "dataBase",
    headerName: "Database",
    sortable: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {},
];

function AgGridTitle(props) {
  const connectionData = props.data;
  const [state, setState] = useState(false);

  const onSuccessCreate = (newData) => {
    const { setRows, setType, setSelected, refreshData } = props.context;
    setRows?.((prevState) => [newData, ...prevState]);
    setType?.("All");
    setSelected([]);
    refreshData();
  };

  const openDrawer = () => setState(true);
  const closeDrawer = () => setState(false);

  return (
    <>
      <Typography variant="outlined" onClick={openDrawer}>
        <Box sx={{ color: "#0e71b7", cursor: "pointer" }}>{props.data.connectionName}</Box>
      </Typography>
      <Drawer anchor={"right"} open={state} onClose={closeDrawer}>
        <CreateConnection
          type={"View"}
          connectionType={connectionData.connectionType}
          connectionData={connectionData}
          handleSuccessCreate={onSuccessCreate}
          onClose={closeDrawer}
        />
      </Drawer>
    </>
  );
}

export { headCellsDSDetails };
