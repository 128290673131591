import { SnackbarContext } from "../../../App";
import { CircularProgress, Grid, MenuItem, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import APIServices from "../../../services/app.service";

export default function SelectSchema(props) {
  const {
    connectionType,
    requiredFieldsChanges,
    setRequiredFieldsChanged,
    errors,
    control,
    formData,
    getValues,
    setValue,
    clearErrors,
    setError,
    schemaLoading,
    setSchemaLoading,
  } = props;

  const { setSnack } = useContext(SnackbarContext);
  const [schemaList, setSchemaList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);

  let values = getValues();

  useEffect(() => {
    if (requiredFieldsChanges) {
      clearErrors();
      setValue("schema", "");
      setValue("warehouseId", "");
      setSchemaList([]);
      setWarehouseList([]);
    }
  }, [requiredFieldsChanges]);

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      handleSchemaFocus();
    }
  }, [formData]);

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      if (schemaList?.length !== 0 && !values?.schema) setValue("schema", formData?.schema);
      if (warehouseList?.length !== 0 && !values?.warehouseId) setValue("warehouseId", formData?.warehouseId);
    }
  }, [schemaList, warehouseList]);

  const handleSchemaFocus = async () => {
    values = getValues();
    const fields =
      connectionType === "Databricks"
        ? ["catalogName", "httpPath", "token", "serverHostname"]
        : ["dataBase", "password", "server", "user"];

    const data = fields.reduce(
      (acc, field) => {
        acc[field] = values[field];
        return acc;
      },
      {
        connectionType: formData?.connectionType || connectionType,
        connectionName: formData?.connectionName || values?.connectionName,
      },
    );

    const requiredFields = fields.map((field) => values[field]);
    if (requiredFields.every(Boolean)) {
      if (requiredFieldsChanges) {
        try {
          setSchemaLoading(true);
          const schemaResponse =
            connectionType === "Databricks"
              ? await APIServices.GetSchema_WarehouseList(data)
              : await APIServices.GetSchemaList(data);

          if (connectionType === "Databricks") {
            setWarehouseList(schemaResponse?.data?.warehouseRows);
          }
          setSchemaList(schemaResponse?.data?.rows || schemaResponse?.data?.schemaRows);
        } catch (error) {
          fields.forEach((field) => {
            setError(field);
          });
          const errorMessage = error?.response?.data?.message || error.message;
          setSnack({ message: errorMessage, open: true, colour: "error" });
        } finally {
          setSchemaLoading(false);
          setRequiredFieldsChanged(false);
        }
      }
    } else {
      fields.forEach((field) => {
        if (!values[field]) {
          setError(field);
        }
      });

      setSnack({
        message: "Fill in all required fields",
        open: true,
        colour: "warning",
      });
    }
  };

  const getSchemaMenuList = () => {
    if (schemaLoading) return <MenuItem key="loading">Loading...</MenuItem>;

    return schemaList?.map((option) => (
      <MenuItem key={option?.SCHEMA_NAME} value={option?.SCHEMA_NAME}>
        {option?.SCHEMA_NAME}
      </MenuItem>
    ));
  };

  const getWarehouseMenuList = () => {
    if (schemaLoading) return <MenuItem key="loading">Loading...</MenuItem>;

    return warehouseList?.map((option) => (
      <MenuItem key={option?.warehouse_id} value={option?.warehouse_id}>
        {option?.name}
      </MenuItem>
    ));
  };
  return (
    <>
      <Grid item sm={12}>
        <Controller
          control={control}
          name="schema"
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              sx={{ mt: 2 }}
              fullWidth
              select
              label={"Select Schema"}
              required
              size="small"
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              error={Boolean(errors.schema)}
              defaultValue={formData?.schema || ""}
              disabled={Boolean(schemaLoading)}
              InputProps={{
                endAdornment: (
                  <React.Fragment>{schemaLoading && <CircularProgress color="inherit" size={20} />}</React.Fragment>
                ),
              }}
              onFocus={handleSchemaFocus}>
              {getSchemaMenuList()}
            </TextField>
          )}
        />
      </Grid>
      {connectionType === "Databricks" && (
        <Grid item sm={12}>
          <Controller
            control={control}
            name="warehouseId"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ mt: 2 }}
                fullWidth
                select
                label={"Select Warehouse"}
                required
                size="small"
                value={field.value || ""}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                error={Boolean(errors.warehouseId)}
                defaultValue={formData?.warehouseId || ""}
                disabled={Boolean(schemaLoading)}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>{schemaLoading && <CircularProgress color="inherit" size={20} />}</React.Fragment>
                  ),
                }}
                onFocus={handleSchemaFocus}>
                {getWarehouseMenuList()}
              </TextField>
            )}
          />
        </Grid>
      )}
    </>
  );
}
