import axios from "axios";
import { Decryption, Encryption } from "../_helpers/Encryption";
import { AuthBaseURLENV, ProductCode, azureLogin, userLocalSession } from "../_helpers/Constant";

const instance = axios.create();
delete instance.defaults.headers.common.Authorization;

class AuthService {
  async login(Email, Password) {
    const data = JSON.stringify({
      Email: Email,
      Password: Password,
      ProductCode,
      IsIdServerExists: azureLogin,
    });
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${AuthBaseURLENV}/api/Login/V3/Userlogin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: await Encryption(data),
    };

    return instance.request(config).then(async (response) => {
      return await Decryption(response.data);
    });
  }
  async logout(data) {
    try {
      const reqData = JSON.stringify({
        UserId: data?.UserId,
        SessionId: data?.SessionId,
        ProductCode,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${AuthBaseURLENV}/api/Login/V1/UserLogout`,
        headers: {
          "Content-Type": "application/json",
        },
        data: await Encryption(reqData),
      };
      return instance.request(config).then(async (response) => {
        return await Decryption(response.data);
      });
    } catch (e) {
      return null;
    }
  }
  async refreshToken(userData) {
    try {
      const tokenData = { token: userData?.Token, ProductCode };
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${AuthBaseURLENV}/api/Login/V1/RefreshToken`,
        headers: {
          "Content-Type": "application/json",
        },
        data: tokenData,
      };
      return instance.request(config).then(async (response) => {
        const newToken = response?.data?.ResponseObject;
        const newData = {
          ...userData,
          Token: newToken?.Token,
          LoginExpiry: newToken?.LoginExpiry,
        };
        localStorage.setItem(userLocalSession, JSON.stringify(newData));
      });
    } catch (e) {
      return null;
    }
  }
}

export default new AuthService();
