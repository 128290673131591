import { Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import GenerateDrawer from "./GenerateDrawer";

const CustomDrawer = ({ entity, open, onClose, setSelectedEntity }) => {
  const { entities } = useSelector((state) => state.entity);
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      sx={{
        pt: 4,
        minWidth: "30%",
        maxWidth: "30%",
        "& .MuiDrawer-paper": {
          minWidth: "30%",
          maxWidth: "30%",
        },
      }}>
      <GenerateDrawer allEntities={entities} onClose={onClose} entity={entity} setSelectedEntity={setSelectedEntity} />
    </Drawer>
  );
};

export default CustomDrawer;
