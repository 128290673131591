import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, Box, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";

import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ApiService } from "../services/app.service";
import CustomTable from "./CustomTable";
import SkeletonLoader from "./SkeletonLoader";
const apiService = new ApiService();

const TableCheckbox = (props) => {
  const { table, tableData, connectionId } = props;
  const methods = useFormContext();
  const selectedTables = methods.watch("selectedTables") || [];

  const [expanded, setExpanded] = useState(false);
  const [attributes, setAttributes] = useState([]);

  const removeObjects = (array1, array2) => {
    const namesToRemove = array2.map((item) => item.TableName);
    return array1.filter((item) => !namesToRemove.includes(item.TableName));
  };

  const handleCheckboxChange = (tableName, e) => {
    let selectedTables = methods.getValues("selectedTables") || []; // Get current state
    let newSelectedTables;
    let newTableDepends = dependentTables(table, e.target.checked);

    if (!e.target.checked) {
      // Table already exists removed from selected tables
      newTableDepends = [...newTableDepends, { ...table, IsChecked: false }];
      newSelectedTables = removeObjects(selectedTables, newTableDepends);
    } else {
      // add new selected exists

      for (const tb of newTableDepends) {
        selectedTables = selectedTables
          .filter((r) => r.TableName !== tb.TableName)
          .map((et) => (et.TableName === tb.TableName ? { ...et, IsDisabled: true } : et));
      }
      newSelectedTables = [...selectedTables, { ...table, IsChecked: e.target.checked }, ...newTableDepends];
    }
    methods.setValue("selectedTables", newSelectedTables);
  };
  const dependentTables = (newSelectedTables, status) => {
    let allTables = [];
    const getDependents = (depTables) => {
      for (const dep of depTables) {
        const foreignTable = tableData.find(
          (e) => e.TableName === dep?.ForeignTableName || e.TableName === dep?.pk_table_name,
        );
        allTables.push(foreignTable);
        foreignTable?.DependentTables && getDependents(foreignTable?.DependentTables);
      }
    };
    getDependents(newSelectedTables?.DependentTables);
    allTables = allTables.map((e) => {
      return { ...e, IsChecked: status, IsDisabled: status };
    });
    return allTables;
  };

  const handleAccordionChange = async () => {
    setExpanded((prev) => !prev);
    if (!expanded) {
      try {
        const responseAttributes = await apiService.TableAttributes({
          connectionId,
          tablesNames: [table.TableName],
        });
        responseAttributes?.data[0]?.Attributes && setAttributes(responseAttributes?.data[0]?.Attributes);
      } catch (e) {
        console.log("Error getting table attributes", e);
      }
    }
  };

  return (
    <Accordion
      sx={{
        "&.Mui-expanded": {
          margin: 0,
        },
        "&.MuiPaper-root": {
          padding: "0 !important",
        },
      }}
      expanded={expanded}
      onChange={handleAccordionChange}>
      <AccordionSummary sx={{ backgroundColor: "#FFF" }} expandIcon={<ExpandMore />}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "space-between",
          }}>
          {" "}
          {/* For layout */}
          <Controller
            key={table.TableName}
            control={methods.control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    disabled={selectedTables.find((t) => t.TableName === table.TableName)?.IsDisabled || false}
                    checked={selectedTables.find((t) => t.TableName === table.TableName)?.IsChecked || false}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      handleCheckboxChange(table.TableName, e);
                    }}
                  />
                }
                label={
                  table?.TableName?.length > 16 ? (
                    <Tooltip title={table?.TableName}>
                      <span>{table?.TableName?.substring(0, 16)}...</span>
                    </Tooltip>
                  ) : (
                    table?.TableName
                  )
                }
              />
            )}
            name={"selectedTables"}
          />
          <Typography variant="body2" color="text.secondary">
            {table?.AttributesCount}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: 200, overflow: "auto" }}>
        {attributes.length > 0 ? (
          <CustomTable data={attributes} headCells={["Name", "DataType"]} />
        ) : (
          <SkeletonLoader />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TableCheckbox;
