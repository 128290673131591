import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  dataSourceServices,
  getAllConnections,
  getConnectionByDataSourceType,
  getConnectionPreviewData,
  getExistingDataSources,
} from "../../services/dataSources.services";
import axios from "axios";

export const deleteConnection = createAsyncThunk("dataSources/deleteConnection", async (arg, thunkAPI) => {
  try {
    const response = await axios.delete(`/datasource/connection/delete/${arg.id}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState = {
  dataSources: [],
  loading: false,
  sourceTypes: [],
  connections: [],
  selectedDataSource: null,
  selectedConnection: null,
  error: null,
  // source_types: [],
  // sources_list: [],
  // loading_data_sources: false,
  // connection_details: [],
  // connections: [],
  // existingDataSources: [],
  // connectionTables: [],
  // selectedDataSource: {},
  // selectedConnection: {},
  // connectionDetailsLoading: false,
};

const { getDataSourcesList, getDataSourceByType } = dataSourceServices;

export const dataSourcesSlice = createSlice({
  name: "dataSources",
  initialState,
  reducers: {
    setSelectedDataSource: (state, action) => {
      state.selectedDataSource = action.payload;
    },
    setSelectedConnection: (state, action) => {
      state.selectedConnection = action.payload;
    },
    setConnections: (state, action) => {
      state.connections = action.payload;
    },
    setLoading: (state, action) => {
      console.log("payload", action.payload);
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataSourcesList.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.sourceTypes = [];
        state.dataSources = [];
      })
      .addCase(getDataSourcesList.fulfilled, (state, action) => {
        state.loading = false;
        state.sourceTypes = action.payload.types;
        state.dataSources = action.payload.sources;
      })
      .addCase(getDataSourcesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getDataSourceByType.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDataSourceByType.fulfilled, (state, action) => {
        state.loading = false;
        state.connections = action.payload;
      })
      .addCase(getDataSourceByType.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllConnections.pending, (state) => {
        state.connectionDetailsLoading = true;
      })
      .addCase(getAllConnections.fulfilled, (state, action) => {
        state.connectionDetailsLoading = false;
        state.connections = action.payload;
      })
      .addCase(getAllConnections.rejected, (state) => {
        state.connectionDetailsLoading = false;
        state.connectionDetails = [];
      })
      .addCase(getExistingDataSources.pending, (state) => {
        state.connectionDetailsLoading = true;
      })
      .addCase(getExistingDataSources.fulfilled, (state, action) => {
        state.connectionDetailsLoading = false;
        state.existingDataSources = action.payload;
      })
      .addCase(getExistingDataSources.rejected, (state) => {
        state.connectionDetailsLoading = false;
        state.existingDataSources = [];
      })
      .addCase(getConnectionByDataSourceType.pending, (state) => {
        state.connectionDetailsLoading = true;
      })
      .addCase(getConnectionByDataSourceType.fulfilled, (state, action) => {
        state.connectionDetailsLoading = false;
        state.connections = action.payload;
      })
      .addCase(getConnectionByDataSourceType.rejected, (state) => {
        state.connectionDetailsLoading = false;
        state.existingDataSources = [];
      })
      .addCase(getConnectionPreviewData.pending, (state) => {
        state.connectionDetailsLoading = true;
      })
      .addCase(getConnectionPreviewData.fulfilled, (state, action) => {
        state.connectionDetailsLoading = false;
        state.connectionTables = action.payload;
      })
      .addCase(getConnectionPreviewData.rejected, (state) => {
        state.connectionDetailsLoading = false;
        state.connectionTables = [];
      });
  },
});

export const { setSelectedDataSource, setSelectedConnection, setConnections, setLoading } = dataSourcesSlice.actions;

export default dataSourcesSlice.reducer;
