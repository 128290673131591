import PropTypes from 'prop-types';
import { TextField, MenuItem, FormControl, InputLabel, Select, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

function FormTextField({
    name,
    control,
    label,
    required = false,
    errors,
    rules = {},
    type = 'text',
    options = [],
    rows = 4,
    onChange
}) {
    return (
        <Controller
            name={name}
            control={control}
            rules={{ required, ...rules }}
            render={({ field }) => (
                type === 'select' ? (
                    <FormControl fullWidth size="small" error={Boolean(errors[name])}>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            {...field}
                            label={label}
                            error={Boolean(errors[name])}
                            onChange={(event) => {
                                field.onChange(event);
                                if (onChange) onChange(event);
                            }}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors[name]?.message}</FormHelperText>
                    </FormControl>
                ) : (
                    <TextField
                        {...field}
                        fullWidth
                        label={label}
                        size="small"
                        error={Boolean(errors[name])}
                        helperText={errors[name]?.message}
                        multiline={type === 'textarea'}
                        rows={type === 'textarea' ? rows : undefined}
                        onChange={(event) => {
                            field.onChange(event); // Ensure React Hook Form updates
                            if (onChange) onChange(event); // Call custom onChange
                        }}
                    />
                )
            )}
        />
    );
}

FormTextField.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    errors: PropTypes.object,
    rules: PropTypes.object,
    type: PropTypes.oneOf(['text', 'select', 'textarea']),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ),
    rows: PropTypes.number, // Number of rows for textarea
    onChange: PropTypes.func // Add PropType for onChange function
};

export default FormTextField;
