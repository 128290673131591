import React from "react";
import { styled } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { NavLink } from "react-router-dom";

const MenuItemButton = styled(ListItemButton)(({ theme }) => ({
  "&.active": {
    background: "transparent linear-gradient(91deg,  #611EB6 25%, #66A2EE 100%)",
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.contrastText,
    },
    "& .MuiListItemText-root": {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const AppMenuItemComponent = (props) => {
  const { onClick, link, children } = props;

  if (!link || typeof link !== "string") {
    return <MenuItemButton onClick={onClick}>{children}</MenuItemButton>;
  }

  const ForwardedNavLink = React.forwardRef(function NavLinkWithRef(props, ref) {
    return <NavLink to={link} ref={ref} {...props} />;
  });

  // Assign a display name for better debugging
  ForwardedNavLink.displayName = "NavLinkWithRef";

  return <MenuItemButton component={ForwardedNavLink}>{children}</MenuItemButton>;
};

export default AppMenuItemComponent;
