import { customComparator } from "../AgGrid/CustomSort";
import { formatDate } from "../../_helpers/utils";
import { Chip } from "@mui/material";

export const datasetHeaderCells = [
  {
    sortable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPosition: "left",
    cellClass: "locked-col",
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
    maxWidth: 50,
  },
  {
    field: "datasetName",
    headerName: "Dataset Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "entityDisplayName",
    headerName: "Entity Name",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agTextColumnFilter",
    comparator: customComparator,
  },
  {
    field: "createdAt",
    headerName: "Created Date",
    sortable: true,
    suppressColumnsToolPanel: true,
    filter: "agDateColumnFilter",
    cellRenderer: createdDate,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: true,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    filter: false,
    comparator: customComparator,
    cellRenderer: setStatusByCode,
  },

  {},
];

function createdDate(props) {
  return <>{formatDate(props.data.createdAt)}</>;
}

function setStatusByCode(props) {
  const getStatus = (status) => {
    switch (status) {
      case 1:
        return { lable: "Todo", color: "primary" };
      case 2:
        return { lable: "In Progress", color: "warning" };
      case 3:
        return { lable: "Success", color: "success" };
      case 4:
        return { lable: "Failed", color: "error" };
      default:
        return { lable: "Todo", color: "primary" };
    }
  };
  const chipStatus = getStatus(props?.data?.status);

  return <Chip sx={{ textTransform: "capitalize" }} label={chipStatus.lable} size="small" color={chipStatus.color} />;
}
