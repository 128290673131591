import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  continent: "Asia",
  country: "",
  state: "",
  city: "",
  dateFormat: "MM/DD/YYYY",
};

export const dropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  reducers: {
    setContinent: (state, action) => {
      state.continent = action.payload;
      // Optionally reset country, state, city
    },
    setCountry: (state, action) => {
      state.country = action.payload;
      // Optionally reset state, city
    },
    setState: (state, action) => {
      state.state = action.payload;
      // Optionally reset city
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    updateGlobalDropdown: (state, action) => {
      const { key, value } = action.payload;

      if (key in state) {
        state[key] = value;

        // Reset dependent dropdowns if necessary
        if (key === "continent") {
          state.country = "";
          state.state = "";
          state.city = "";
        } else if (key === "country") {
          state.state = "";
          state.city = "";
        } else if (key === "state") {
          state.city = "";
        }
        if (key === "dateFormat") {
          state.selectedFormat = value;
        }
      }
    },
    resetGlobalDropdown: (state, action) => {
      const { key } = action.payload;
      if (key === "dateFormat") {
        state.dateFormat = "MM/DD/YYYY";
      } else {
        state[key] = "";
      }
    },
  },
});

export const {
  setContinent,
  setCountry,
  setState,
  setCity,
  updateGlobalDropdown,
} = dropdownSlice.actions;

export default dropdownSlice.reducer;
