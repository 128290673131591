import { useState, useEffect } from "react";
import { Box, Typography, CircularProgress, IconButton, Tooltip, Menu, MenuItem, Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import DiscFullOutlinedIcon from "@mui/icons-material/DiscFullOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import ApiService from "../../../services/app.service";

const ServiceStatusPopup = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [services, setServices] = useState({
    DQGEngine: null,
    DQGService: null,
  });
  const [loading, setLoading] = useState({
    DQGEngine: false,
    DQGService: false,
  });

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkServiceStatus = async (serviceName, serviceKey) => {
    setLoading((prev) => ({ ...prev, [serviceKey]: true }));
    try {
      await ApiService[serviceName]();
      setServices((prev) => ({ ...prev, [serviceKey]: true }));
    } catch {
      setServices((prev) => ({ ...prev, [serviceKey]: false }));
    } finally {
      setLoading((prev) => ({ ...prev, [serviceKey]: false }));
    }
  };

  useEffect(() => {
    if (open) {
      checkServiceStatus("DQGServerHealthcheck", "DQGService");
      checkServiceStatus("DQGEngineHealthcheck", "DQGEngine");
    } else {
      setServices({
        DQGEngine: null,
        DQGService: null,
      });
      setLoading({
        DQGEngine: false,
        DQGService: false,
      });
    }
  }, [open]);

  const ServiceStatusItem = ({ loading, status, name }) => (
    <MenuItem className={status && "statusOnline"}>
      {loading ? (
        <CircularProgress
          style={{
            width: "20px",
            height: "20px",
            marginRight: "8px",
          }}
        />
      ) : (
        <>
          <Avatar sx={{ width: 20, height: 20 }}>
            <CircleIcon />
          </Avatar>
          <Typography>
            {name} - <Box component="span">{status ? "Online" : "Offline"}</Box>
          </Typography>
        </>
      )}
    </MenuItem>
  );

  return (
    <Box>
      <Tooltip title={t("service_status")} arrow>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{
            background: "linear-gradient(45deg, #611EB6, #66A2EE)",
            color: "#fff",
          }}
          aria-controls={open ? "service-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}>
          <DiscFullOutlinedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="service-menu"
        className="notItems statusItems"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <Typography variant="h6">Status of TDM Services:</Typography>
        <Box className="listBox webSerStatus">
          <ServiceStatusItem loading={loading.DQGService} status={services.DQGService} name="TDM Service" />
          <ServiceStatusItem loading={loading.DQGEngine} status={services.DQGEngine} name="TDM Engine" />
        </Box>
      </Menu>
    </Box>
  );
};

export default ServiceStatusPopup;
