import { Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function DatabricksForm(props) {
  const { schemaLoading, handleChnageInput, errors, control, formData } = props;

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Controller
          control={control}
          name="catalogName"
          defaultValue={formData?.catalogName ?? ""}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              // disabled={Boolean(formData?.catalogName)}
              disabled={Boolean(schemaLoading)}
              label={"Catalog Name"}
              required
              fullWidth
              error={Boolean(errors?.catalogName)}
              variant="outlined"
              size="small"
              onChange={(e) => handleChnageInput(e, field)}
            />
          )}
        />
      </Grid>

      <Grid item sm={12}>
        <Controller
          control={control}
          name="serverHostname"
          defaultValue={formData?.serverHostname ?? ""}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              // disabled={Boolean(formData?.serverHostname)}
              disabled={Boolean(schemaLoading)}
              label={"Server Host Name"}
              placeholder="eg: adb-4327469464531522.2.azuredatabricks.net"
              required
              fullWidth
              error={Boolean(errors?.serverHostname)}
              variant="outlined"
              size="small"
              onChange={(e) => handleChnageInput(e, field)}
            />
          )}
        />
      </Grid>

      <Grid item sm={12}>
        <Controller
          control={control}
          name="httpPath"
          defaultValue={formData?.httpPath ?? ""}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              // disabled={Boolean(formData?.httpPath)}
              disabled={Boolean(schemaLoading)}
              label={"Http Path"}
              placeholder="eg: /sql/1.0/warehouses/f11f65200dfe6353"
              required
              fullWidth
              error={Boolean(errors?.httpPath)}
              variant="outlined"
              size="small"
              onChange={(e) => handleChnageInput(e, field)}
            />
          )}
        />
      </Grid>

      <Grid item sm={12}>
        <Controller
          control={control}
          name="token"
          defaultValue={formData?.token ?? ""}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              // disabled={Boolean(formData?.token)}
              disabled={Boolean(schemaLoading)}
              label={"Token"}
              required
              fullWidth
              error={Boolean(errors?.token)}
              variant="outlined"
              size="small"
              onChange={(e) => handleChnageInput(e, field)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
