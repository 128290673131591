import { useState } from "react";
import AppMenuItemComponent from "./AppMenuItemComponent";
import { Box, Collapse, List, ListItemIcon, Tooltip, useTheme } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

const AppMenuItem = (props) => {
  const { text, path, icon, items = [] } = props;
  const theme = useTheme();
  const isExpandable = items.length > 0;
  const [open] = useState(true);

  const MenuItemRoot = (
    <AppMenuItemComponent link={path}>
      {Boolean(icon) && (
        <Tooltip title={text} placement="top-start" arrow>
          <ListItemIcon sx={{ pl: items ? 1 : 0 }}>{<props.icon />}</ListItemIcon>
        </Tooltip>
      )}
      <ListItemText primary={text} inset={!icon} />
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable && (
    <Collapse in={open} timeout={"auto"} unmountOnExit>
      <List
        component="div"
        disablePadding
        sx={{
          "& a": {
            "& .MuiListItemText-root": {
              "& .MuiTypography-root": {
                fontWeight: "normal",
              },
            },
            paddingLeft: "40px",
            "& .MuiListItemIcon-root": {
              minWidth: "35px",
            },
            "& svg": {
              fontSize: "16px",
            },
          },
          backgroundColor: theme?.palette?.mode === "dark" ? "transparent" : "#eee",
        }}>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  );
  return (
    <>
      <Box sx={{ "& .MuiListItemText-root": { "& .MuiTypography-root": { fontWeight: "600" } } }}>{MenuItemRoot}</Box>
      {MenuItemChildren}
    </>
  );
};
export default AppMenuItem;
