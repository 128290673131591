import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  selectedAttributes: [],
  currentSelectedAttribute: null,
  selectedAttributeIndex: null,
  selectedTableIndex: null
};

const attributesSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
    setAllAttributes: (state, action) => {
      state.selectedAttributes = action.payload;
    },
    addAttribute: (state, action) => {
      state.selectedAttributes.push(action.payload);
    },
    updateAttribute: (state, action) => {
      const { _id, changes } = action.payload;
      const attributeIndex = state.selectedAttributes.findIndex(
        (attr) => attr._id === _id
      );
      if (attributeIndex !== -1) {
        state.selectedAttributes[attributeIndex] = {
          ...state.selectedAttributes[attributeIndex],
          ...changes,
        };
      }
    },
    removeAttribute: (state, action) => {
      // Updated to directly use the action.payload as the ID
      state.selectedAttributes = state.selectedAttributes.filter(
        (attr) => attr._id !== action.payload
      );
    },
    reorderAttributes: (state, action) => {
      const { draggedIndex, targetIndex } = action.payload;
      const draggedItem = state.selectedAttributes[draggedIndex];
      state.selectedAttributes.splice(draggedIndex, 1);
      state.selectedAttributes.splice(targetIndex, 0, draggedItem);
    },
    setCurrentSelectedAttribute: (state, action) => {
      state.currentSelectedAttribute = action.payload;
    },
    setCurrentSelectedAttributeIndex: (state, action) => {
      state.selectedAttributeIndex = action.payload;
    },
    setCurrentSelectedTableIndex: (state, action) => {
      state.selectedTableIndex = action.payload;
    },
    updateAttributeProperty: (state, action) => {
      const { attributeId, propertyName, value, customName, change } =
        action.payload;
      const attribute = state.selectedAttributes.find(
        (attr) => attr._id === attributeId
      );

      if (attribute) {
        if (propertyName) {
          const propertyIndex = attribute.properties.findIndex(
            (prop) => prop.key === propertyName
          );

          if (propertyIndex >= 0) {
            if (propertyName === "dateFormat") {
              attribute.properties[propertyIndex].value = value;
              if (change?.selectedFormat) {
                attribute.properties[propertyIndex].selectedFormat =
                  change?.selectedFormat;
              }
            } else {
              attribute.properties[propertyIndex].value = value;
            }
          }
        }

        if (customName !== undefined) {
          attribute.attributeCustomName = customName;
        }
      }
    },
    updateGlobalProperty: (state, action) => {
      const { propertyName, value } = action.payload;

      state.selectedAttributes.forEach((attr) => {
        const property = attr.properties.find((p) => p.key === propertyName);
        if (property) {
          property.value = value;
        }
      });
    },
    resetSelectedAttributes: (state) => {
      state.selectedAttributes = [];
      state.currentSelectedAttribute = null;
    },
    updateAttributeValidity: (state, action) => {
      const { attributeId, isValid } = action.payload;
      const attributeIndex = state.selectedAttributes.findIndex(
        (attr) => attr._id === attributeId
      );

      if (attributeIndex !== -1) {
        state.selectedAttributes[attributeIndex].isValid = isValid;
      }
    },
  },
});

export const {
  addAttribute,
  updateAttribute,
  removeAttribute,
  reorderAttributes,
  setCurrentSelectedAttribute,
  setCurrentSelectedAttributeIndex,
  setCurrentSelectedTableIndex,
  updateAttributeProperty,
  updateGlobalProperty,
  setAllAttributes,
  resetSelectedAttributes,
  updateAttributeValidity,
} = attributesSlice.actions;
export default attributesSlice.reducer;
