import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "../../services/app.service";
import axios from "axios";

// Thunks
export const fetchAllEntities = createAsyncThunk(
  "entity/get",
  async (arg, thunkAPI) => {
    try {
      const { type } = arg;
      const response = await axios.get(`/entities/get-by-type/${type}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchEntityById = createAsyncThunk(
  "entity/getById",
  async (arg, thunkAPI) => {
    try {
      const { id, type } = arg;
      const response = await axios.post(`/entities/get/${id}`, { type });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createNewEntity = createAsyncThunk(
  "entity/create",
  async (newEntity, thunkAPI) => {
    try {
      const response = await ApiService.createEntity(newEntity);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editEntity = createAsyncThunk(
  "entity/edit",
  async (editInfo, thunkAPI) => {
    try {
      const response = await axios.post("/entities/update", { ...editInfo });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteEntity = createAsyncThunk(
  "entity/delete",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post("/entities/delete", { ...args });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAllEntitiesByIds = createAsyncThunk(
  "entity/getByIds",
  async (arg, thunkAPI) => {
    try {
      const { ids, type } = arg;
      const response = await axios.post("/entities/get-entities-by-id", {
        entityNames: [...ids],
        type,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice
const entitySlice = createSlice({
  name: "entity",
  initialState: {
    loading: false,
    entities: [],
    currentEntity: null,
    selectedEntityId: "",
    status: "idle",
    error: null,
    deleteLoading: false,
  },
  reducers: {
    setEntity: (state, action) => {
      state.entities = action.payload;
    },
    setSelectedEntityId: (state, action) => {
      state.selectedEntityId = action.payload;
    },
    setCurrentEntity: (state, action) => {
      state.currentEntity = { ...state.currentEntity, ...action.payload };
    },
    updateEntityInfo: (state, action) => {
      state.currentEntity[action.payload.key] = action.payload.value;
    },
    updateEntitySuccess: (state, action) => {
      const updatedEntity = action.payload;
      const index = state.entities.findIndex((e) => e.id === updatedEntity.id);
      if (index !== -1) {
        state.entities[index] = updatedEntity;
      }
    },
    updateTypes: (state, action) => {
      const { id, types } = action.payload;
      const index = state.entities.findIndex((e) => e.id === id);
      if (index !== -1) {
        state.entities[index].types = types;
      }
    },
    resetCurrentEntityInfo: (state) => {
      state.currentEntity = {
        entityName: "",
        entityDisplayName: "",
        entityType: "custom",
        entityRelations: [],
        entityDescription: "",
        attributes: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch All Entities
      .addCase(fetchAllEntities.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchAllEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.entities = action.payload;
      })
      .addCase(fetchAllEntities.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
      })
      // Fetch Entity By ID
      .addCase(fetchEntityById.pending, (state) => {
        state.status = "loading";
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchEntityById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.currentEntity = action.payload;
      })
      .addCase(fetchEntityById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.loading = false;
      })
      // Edit Entity
      .addCase(editEntity.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(editEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        const index = state.entities.findIndex(
          (entity) => entity._id === action.payload._id
        );
        if (index !== -1) {
          state.entities[index] = action.payload;
        }
      })
      .addCase(editEntity.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
      })
      // Create Entity
      .addCase(createNewEntity.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(createNewEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.entities.push(action.payload);
      })
      .addCase(createNewEntity.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
      })
      // Delete Entity
      .addCase(deleteEntity.pending, (state) => {
        state.deleteLoading = true;
        state.status = "loading";
      })
      .addCase(deleteEntity.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.status = "succeeded";
        state.entities = action.payload.data.entities;
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.deleteLoading = false;
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const {
  updateEntityInfo,
  setSelectedEntityId,
  setCurrentEntity,
  updateEntitySuccess,
  setEntity,
  resetCurrentEntityInfo,
  updateTypes,
} = entitySlice.actions;

export default entitySlice.reducer;
