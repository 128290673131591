import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { updateGlobalDropdown } from "../app/features/dropdownSlice";
import * as validationFunctions from "../utils/validation";
import CustomRulesEditor from "./CustomRulesEditor";
import citiesData from "./Data/Cities.json";
import CountriesData from "./Data/CountriesData.json";
import statesData from "./Data/States.json";

const PropertiesContainer = ({ control, watch }) => {
  const location = useLocation();
  const isCloned = useMemo(() => {
    return location.pathname.includes("cloned");
  }, [location]);

  const [disablePrimaryKey, setDisablePrimaryKey] = useState(false);
  const [disableHasEmpty, setDisableHasEmpty] = useState(false);

  const { continent, country, state } = useSelector((state) => state.dropdown);

  const dispatch = useDispatch();

  const fetchDropdownOptions = (type) => {
    const continents = ["Europe", "Oceania", "Asia", "South America", "Africa", "North America"];
    const filteredCountries = continent ? CountriesData.filter((country) => country.Continent === continent) : [];
    const filteredStates = country ? statesData.filter((state) => state.country === country) : [];
    const filteredCities = state ? citiesData.filter((city) => city.state === state) : [];
    switch (type) {
      case "continent":
        return continents?.map((continent) => ({
          label: continent,
          value: continent,
        }));

      case "country":
        return filteredCountries.map((country) => ({
          label: country.Country,
          value: country.Country,
        }));
      case "state":
        // Fetch and return state options
        return filteredStates.map((state) => ({
          label: state.states,
          value: state.states,
        }));
      case "city":
        return filteredCities.map((city) => ({
          label: city.city,
          value: city.city,
        }));
      // ... other cases ...
      default:
        return []; // Default empty array for types with no predefined options
    }
  };

  const { selectedAttributeIndex, selectedTableIndex } = useSelector((state) => state.attributes);
  const globalDropdownState = useSelector((state) => state.dropdown);

  let selectedAttribute = watch(`attributes[${selectedAttributeIndex}]`);
  let attributes = watch("attributes");
  let attributeName = `attributes[${selectedAttributeIndex}].attributeCustomName`;
  const hasPrimaryKey = watch(`attributes[${selectedAttributeIndex}].IsPrimaryKey`);
  let attributePath = `attributes[${selectedAttributeIndex}]`;

  const {
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  if (isCloned) {
    attributePath = `selectedTables[${selectedTableIndex}].attributes[${selectedAttributeIndex}]`;
    selectedAttribute = watch(attributePath);
    attributes = watch(`selectedTables[${selectedTableIndex}].attributes`);
    attributeName = watch(`${attributePath}.attributeCustomName`);
  }

  const properties = watch(`${attributePath}.properties`, []);
  const getProperty = (key, defaultValue = null) => {
    const property = (properties || []).find((prop) => prop.key === key);
    return property ? property.value : defaultValue;
  };

  const getSelectedFormat = (key, defaultValue = null) => {
    const property = (properties || []).find((prop) => prop.key === key);
    return property ? property.selectedFormat : defaultValue;
  };

  const selectedAttributeType = watch(`${attributePath}.type`);
  const isFixedLength = getProperty("isFixedLength");
  const fixedLength = getProperty("fixed");
  const isDecimal = getProperty("isDecimal");
  const dateFormat = getSelectedFormat("dateFormat");
  const startsWith = getProperty("startsWith");
  const endsWith = getProperty("endsWith");
  const minLength = getProperty("min");
  const useCustomRules = getProperty("useCustomRules");

  const prevDateFormat = useRef(dateFormat);

  useEffect(() => {
    // Trigger validation for the properties of the selected attribute
    if (selectedAttributeIndex !== null) {
      trigger(attributePath).then();
    }
  }, [trigger, selectedAttributeIndex, attributePath]);

  useEffect(() => {
    if (hasPrimaryKey) {
      setDisableHasEmpty(true);
      attributes?.forEach((attribute, attrIndex) => {
        const hasEmptyPropertyIndex = attribute?.properties?.findIndex((prop) => prop.key === "hasEmpty");
        if (hasEmptyPropertyIndex && hasEmptyPropertyIndex !== -1) {
          if (isCloned) {
            setValue(
              `selectedTables[${selectedTableIndex}].attributes[${attrIndex}].properties[${hasEmptyPropertyIndex}].value`,
              false,
            );
          } else {
            setValue(`attributes[${attrIndex}].properties[${hasEmptyPropertyIndex}].value`, false);
          }
        }
      });
    } else {
      setDisableHasEmpty(false);
    }
  }, [hasPrimaryKey]);

  // Handling global change of dropdown
  useEffect(() => {
    if (attributes) {
      attributes?.forEach((attribute, attrIndex) => {
        const continentPropertyIndex = attribute?.properties?.findIndex((prop) => prop.key === "continent");
        const countryPropertyIndex = attribute?.properties?.findIndex((prop) => prop.key === "country");

        const statePropertyIndex = attribute?.properties?.findIndex((prop) => prop.key === "state");

        const cityPropertyIndex = attribute?.properties?.findIndex((prop) => prop.key === "city");
        if (continentPropertyIndex && continentPropertyIndex !== -1) {
          if (isCloned) {
            setValue(
              `selectedTables[${selectedTableIndex}].attributes[${attrIndex}].properties[${continentPropertyIndex}].value`,
              globalDropdownState?.continent,
            );
          } else {
            setValue(
              `attributes[${attrIndex}].properties[${continentPropertyIndex}].value`,
              globalDropdownState.continent,
            );
          }
        }
        if (countryPropertyIndex && countryPropertyIndex !== -1) {
          if (isCloned) {
            setValue(
              `selectedTables[${selectedTableIndex}].attributes[${attrIndex}].properties[${countryPropertyIndex}].value`,
              globalDropdownState?.country,
            );
          } else {
            setValue(`attributes[${attrIndex}].properties[${countryPropertyIndex}].value`, globalDropdownState.country);
          }
        }
        if (statePropertyIndex && statePropertyIndex !== -1) {
          if (isCloned) {
            setValue(
              `selectedTables[${selectedTableIndex}].attributes[${attrIndex}].properties[${statePropertyIndex}].value`,
              globalDropdownState?.state,
            );
          } else {
            setValue(`attributes[${attrIndex}].properties[${statePropertyIndex}].value`, globalDropdownState.state);
          }
        }

        if (cityPropertyIndex && cityPropertyIndex !== -1) {
          if (isCloned) {
            setValue(
              `selectedTables[${selectedTableIndex}].attributes[${attrIndex}].properties[${cityPropertyIndex}].value`,
              globalDropdownState?.city,
            );
          } else {
            setValue(`attributes[${attrIndex}].properties[${cityPropertyIndex}].value`, globalDropdownState.city);
          }
        }
      });
    }
  }, [globalDropdownState, setValue, attributes]);

  useEffect(() => {
    if (isFixedLength === false) {
      // Reset useCustomRules to false
      const useCustomRulesIndex = watch(`${attributePath}.properties`, []).findIndex(
        (prop) => prop.key === "useCustomRules",
      );
      if (useCustomRulesIndex !== -1) setValue(`${attributePath}.properties[${useCustomRulesIndex}].value`, false);
      trigger(attributePath);
    } else {
      trigger(attributePath);
    }
  }, [isFixedLength, setValue, attributeName, attributePath, watch, selectedAttributeIndex, trigger]);

  useEffect(() => {
    if (!dateFormat || !(selectedAttributeType === "date")) return;
    const attributeProperties = getValues(`${attributePath}.properties`);

    attributeProperties.forEach((property, propIndex) => {
      if (property.key === "min" || property.key === "max") {
        const dateValuePath = `${attributePath}.properties[${propIndex}].value`;
        console.log(dateValuePath, 230);
        const dateValue = getValues(dateValuePath);
        console.log(dateValue, 231);

        // Check if dateValue is null or undefined
        if (dateValue === null || dateValue === undefined) {
          console.error("dateValue is null or undefined");
          return;
        }

        // Check if prevDateFormat.current is properly initialized
        if (!prevDateFormat.current) {
          console.error("prevDateFormat.current is not properly initialized");
          return;
        }

        // Check if dateFormat is properly initialized
        if (!dateFormat) {
          console.error("dateFormat is not properly initialized");
          return;
        }

        // Use the previous format to parse the date
        if (dayjs(dateValue, prevDateFormat.current)?.isValid()) {
          const reformattedDate = dayjs(dateValue, prevDateFormat.current).format(dateFormat);
          setValue(dateValuePath, reformattedDate, { shouldValidate: true });
        } else {
          console.error("Invalid date format:", dateValue, prevDateFormat.current);
        }
      }
    });

    prevDateFormat.current = dateFormat;
    trigger(attributePath);
  }, [dateFormat, getValues, selectedAttributeIndex, selectedAttributeType, setValue, trigger]);

  useEffect(() => {
    if (selectedAttributeType === "string") {
      if (isFixedLength && !useCustomRules) {
        const length = parseInt(fixedLength, 10);
        if (!isNaN(length) && length > 0) {
          if (startsWith?.trim()?.length > 0 && endsWith?.trim()?.length === 0) {
            if (startsWith?.trim()?.length >= length) {
              setError(`lengthValidation`, {
                type: "custom",
                message: `Length of "starts with" ${startsWith?.trim()?.length} should be less than or equal to length`,
              });
            } else {
              clearErrors(`lengthValidation`);
            }
          } else if (endsWith?.trim()?.length > 0 && startsWith?.trim()?.length === 0) {
            if (endsWith.length >= length) {
              setError(`lengthValidation`, {
                type: "custom",
                message: `Length of "ends with" (${endsWith?.trim()?.length}) should be less than total fixed length`,
              });
            } else {
              clearErrors(`lengthValidation`);
            }
          } else if (startsWith?.trim()?.length > 0 && endsWith?.trim()?.length > 0) {
            if (startsWith?.trim()?.length + endsWith?.trim()?.length >= length) {
              setError(`lengthValidation`, {
                type: "custom",
                message: `Total Length of "starts with" and "ends with" (${
                  startsWith?.trim()?.length + endsWith?.trim()?.length
                }) should be less than fixed length (${length})`,
              });
            } else {
              clearErrors(`lengthValidation`);
            }
          } else {
            clearErrors(`lengthValidation`);
          }
        } else {
          if (
            startsWith?.trim()?.length > 0 ||
            endsWith?.trim()?.length > 0 ||
            startsWith?.trim()?.length + endsWith?.trim()?.length > 0
          ) {
            setError(`lengthValidation`, {
              type: "custom",
              message: "Enter a valid min length",
            });
          } else {
            clearErrors(`lengthValidation`);
          }
        }
      } else if (!isFixedLength) {
        // Check if starts with and ends with are lesser than min length
        const length = parseInt(minLength, 10);
        if (!isNaN(length) && length > 0) {
          if (startsWith?.trim()?.length > 0 && endsWith?.trim()?.length === 0) {
            if (startsWith?.trim()?.length >= length) {
              setError(`lengthValidation`, {
                type: "custom",
                message: `Length of "starts with" ${
                  startsWith?.trim()?.length
                } should be less than or equal to min length (${length})`,
              });
            } else {
              clearErrors(`lengthValidation`);
            }
          } else if (endsWith?.trim()?.length > 0 && startsWith?.trim()?.length === 0) {
            if (endsWith?.trim()?.length >= length) {
              setError(`lengthValidation`, {
                type: "custom",
                message: `Length of "ends with" (${
                  endsWith?.trim()?.length
                }) should be less than min length (${length})`,
              });
            } else {
              clearErrors(`lengthValidation`);
            }
          } else if (startsWith?.trim()?.length > 0 && endsWith?.trim()?.length > 0) {
            if (startsWith?.trim()?.length + endsWith?.trim()?.length >= length) {
              setError(`lengthValidation`, {
                type: "custom",
                message: `Total Length of "starts with" and "ends with" (${
                  startsWith?.trim()?.length + endsWith?.trim()?.length
                }) should be less than min length (${length})`,
              });
            } else {
              clearErrors(`lengthValidation`);
            }
          } else {
            clearErrors(`lengthValidation`);
          }
        } else {
          if (
            startsWith?.trim()?.length > 0 ||
            endsWith?.trim()?.length > 0 ||
            startsWith?.trim()?.length + endsWith?.trim()?.length > 0
          ) {
            setError(`lengthValidation`, {
              type: "custom",
              message: "Enter a valid min length",
            });
          } else {
            clearErrors(`lengthValidation`);
          }
        }
      } else {
        clearErrors(`lengthValidation`);
      }
    }
  }, [
    useCustomRules,
    startsWith,
    endsWith,
    minLength,
    isFixedLength,
    setError,
    clearErrors,
    selectedAttributeType,
    watch,
    attributePath,
    fixedLength,
  ]);

  useEffect(() => {
    if (isDecimal) {
      console.log("attributePath", attributePath);
      setDisablePrimaryKey(true);
      setValue(`${attributePath}.IsPrimaryKey`, false);
    } else {
      setDisablePrimaryKey(false);
    }
  }, [attributePath, isDecimal, setValue]);

  const isDependencyMet = (property, properties) => {
    if (!property.dependsOn) {
      return true; // Always render if there are no dependencies
    }

    return property?.dependsOn?.every((dep) => {
      const dependentProperty = properties.find((p) => p.key === dep.property);
      return dependentProperty && dependentProperty.value === dep.value;
    });
  };

  const isValidDate = (dateStr, format) => {
    return dayjs(dateStr, format).isValid();
  };

  const renderPropertyInput = (field, error, property, propertyIndex, properties) => {
    if (!isDependencyMet(property, properties)) {
      return null; // Don't render this property as its dependencies are not met
    }
    const dropdownOptions = property?.options || fetchDropdownOptions(property.key);

    switch (property?.type) {
      case "text":
      case "number":
        if (hasPrimaryKey && selectedAttribute?.type === "number") {
          return "";
        }
        return (
          <TextField
            type={property.type === "number" ? "number" : "text"}
            {...field}
            InputProps={{
              inputProps: property.type === "number" ? { min: "1", step: "1" } : "",
            }}
            label={property.keyDisplayName}
            onChange={(event) => {
              const value = event.target.value.trim().replace(/^0+/, "");
              field.onChange(property.type === "number" ? parseInt(value, 10) : event.target.value);
              trigger(attributePath);
            }}
            size="small"
            sx={{ mb: 1 }}
            fullWidth
            error={Boolean(error)}
            helperText={error?.message}
            variant="outlined"
          />
        );
      case "toggle":
        return (
          <FormControlLabel
            sx={{ m: 0 }}
            disabled={property?.key === "hasEmpty" && disableHasEmpty}
            control={<Switch {...field} size="small" sx={{ mb: 1 }} checked={field.value} />}
            label={property.keyDisplayName}
          />
        );
      case "radio":
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{property.keyDisplayName}</FormLabel>
            <RadioGroup sx={{ m: 0 }} row {...field}>
              {property.options.map((option, optIndex) => (
                <FormControlLabel
                  sx={{ m: 0 }}
                  key={`radio-${propertyIndex}-${optIndex}`}
                  value={option.value}
                  control={<Radio size="small" color="primary" />}
                  label={option.key}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "customRuleArray":
        return (
          <CustomRulesEditor
            control={control}
            watch={watch}
            name={`${attributePath}.properties`}
            propertyIndex={propertyIndex}
          />
        );
      case "select":
        if (property?.dropdownType === "simple") {
          return (
            <TextField
              select
              label={property.keyDisplayName}
              disabled={dropdownOptions?.length === 0}
              size="small"
              sx={{ mb: 1 }}
              fullWidth
              error={Boolean(error)}
              helperText={error?.message}
              variant="outlined"
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                dispatch(
                  updateGlobalDropdown({
                    key: property.key,
                    value: e.target.value,
                  }),
                );
                if (property.key === "dateFormat") {
                  const format = property.options.find((option) => option.value === e.target.value)?.label;
                  setValue(`${attributePath}.properties[${propertyIndex}].selectedFormat`, format);
                }
                trigger(attributePath);
              }}>
              {dropdownOptions.map((option, optIndex) => (
                <MenuItem key={`option-${propertyIndex}-${optIndex}`} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </TextField>
          );
        } else if (property?.dropdownType === "multiselect") {
          return (
            <TextField
              select
              label={property.keyDisplayName}
              size="small"
              sx={{ mb: 1 }}
              fullWidth
              error={Boolean(error)}
              helperText={error?.message}
              variant="outlined"
              {...field}
              multiple>
              {property.options.map((option, optIndex) => (
                <MenuItem key={`option-${propertyIndex}-${optIndex}`} value={option.value}>
                  {option.key}
                </MenuItem>
              ))}
            </TextField>
          );
        } else if (property?.dropdownType === "autocomplete") {
          return (
            <Box sx={{ width: "100%", my: 1 }}>
              <CreatableSelect
                {...field}
                isClearable
                isMulti
                required={Boolean(error)}
                openMenuOnClick={false}
                placeholder={property.keyDisplayName}
                onChange={(e) => {
                  field.onChange(e);
                  trigger();
                }}
                components={{
                  DropdownIndicator: null,
                }}
              />
              <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
            </Box>
          );
        } else if (property?.dropdownType === "date") {
          return (
            <Box sx={{ width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={property.keyDisplayName}
                  slotProps={{
                    textField: {
                      error: Boolean(error),
                      size: "small",
                    },
                  }}
                  {...field}
                  sx={{ width: "100%", mt: 1 }}
                  size="small"
                  value={isValidDate(field.value, dateFormat) ? dayjs(field.value, dateFormat) : null}
                  onChange={(newValue) => {
                    field.onChange(newValue ? dayjs(newValue).format(dateFormat) : null);
                    trigger(attributePath);
                  }}
                  disableFuture
                  format={dateFormat}
                />
              </LocalizationProvider>
              <FormHelperText error={Boolean(error)}>{error?.message}</FormHelperText>
            </Box>
          );
        }
        break;
      default:
        return null;
    }

    return null;
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <Box sx={{ my: 1 }}>
      <Controller
        key={`${attributePath}.attributeCustomName`}
        name={`${attributePath}.attributeCustomName`}
        defaultValue={selectedAttribute?.attributeCustomName}
        rules={{
          required: "Attribute name is required",
          minLength: {
            value: 2,
            message: "Attribute name must be at least 2 characters",
          },
          validate: {
            noLeadingSpaces: (value) => !/^\s/.test(value) || "Cannot start with a space",
            noOnlySpaces: (value) => !/^\s+$/.test(value) || "Cannot be only spaces",
            noTrailingSpaces: (value) => !/\s$/.test(value) || "Cannot end with a space",
            noSpecialChars: (value) =>
              /^[A-Za-z][A-Za-z0-9_ \s]*[A-Za-z0-9]$/.test(value) ||
              "Special characters not allowed except _ and spaces",
            noLeadingDigits: (value) => !/^[0-9]/.test(value) || "Cannot start with a digit",
            uniqueAttribute: (value) => {
              const attributeNames = attributes
                .filter((attr) => attr.attributeCustomName === value)
                .map((attr) => attr.attributeCustomName);
              return attributeNames.length === 1 || "Attribute name must be unique";
            },
          },
        }}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            name={attributeName}
            {...field}
            variant="outlined"
            label="Attribute Name"
            fullWidth
            size="small"
            sx={{ mb: 1 }}
            error={Boolean(error)}
            helperText={error?.message}
          />
        )}
      />

      {selectedAttribute?.properties?.map((property, index) => {
        const propertyName = `${attributePath}.properties[${index}].value`;
        if (!isDependencyMet(property, selectedAttribute?.properties)) {
          return null; // Don't render this property as its dependencies are not met
        }
        const globalValue = globalDropdownState[property.key];

        const validationFunc = property.validationFuncName ? validationFunctions[property.validationFuncName] : null;

        const rules = {
          ...(property?.validationRules || {}),
          validate: validationFunc
            ? () =>
                property?.type === "select" && property?.dropdownType === "date"
                  ? validationFunc(property.value, prevDateFormat.current, selectedAttribute?.properties)
                  : validationFunc(property.value, selectedAttribute?.properties)
            : undefined,
        };

        let defaultValue;

        if (property.type === "toggle") {
          defaultValue = property.value || false;
        } else if (property.type === "select") {
          defaultValue = globalValue;
          if (property?.dropdownType === "date") {
            defaultValue = dayjs(property.value).format(dateFormat);
          }
        } else {
          defaultValue = property.value;
        }

        return (
          <Box key={`prop-attr-${selectedAttributeIndex}-prop-${index}`}>
            <Controller
              key={propertyName}
              name={propertyName}
              control={control}
              defaultValue={defaultValue}
              rules={rules}
              render={({ field, fieldState: { error } }) => (
                <>
                  {renderPropertyInput(field, error, property, index, selectedAttribute?.properties)}
                  {property?.note && (
                    <Alert sx={{ my: 2 }} severity="info">
                      {property?.note}
                    </Alert>
                  )}
                </>
              )}
            />
          </Box>
        );
      })}
      {!selectedAttribute?.IsForeignKey &&
        (selectedAttribute?.type === "string" || selectedAttribute?.type === "number") && (
          <Controller
            key={`${attributePath}.IsPrimaryKey`}
            name={`${attributePath}.IsPrimaryKey`}
            defaultValue={selectedAttribute?.isPrimaryKey || false}
            control={control}
            rules={{
              required: false, // Adjust if needed
              validate: {
                uniquePrimaryKey: () => {
                  const primaryKeyCount = attributes.filter((attr) => attr.IsPrimaryKey === true).length;
                  return primaryKeyCount <= 1 || "Only one primary key is allowed per entity.";
                },
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormControlLabel
                  sx={{ m: 0 }}
                  disabled={disablePrimaryKey || attributePath?.isForeignKey}
                  control={<Switch {...field} size="small" sx={{ mb: 1 }} checked={field.value} />}
                  label={"Is Primary Key"}
                />
                {disablePrimaryKey && <FormHelperText>Primary keys cannot be decimals</FormHelperText>}
                {error?.message && <Alert severity="error">{error?.message}</Alert>}
              </>
            )}
          />
        )}
      {errors?.lengthValidation && <Alert severity="error">{errors?.lengthValidation?.message}</Alert>}
    </Box>
  );
};

export default PropertiesContainer;
