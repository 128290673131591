import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  connections: [],
  loading: false,
  error: null,
};

export const createConnection = createAsyncThunk(
  "connection/create",
  async (data, thunkAPI) => {
    try {
      const res = await axios.post(`datasource/connection/create`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue({
        error: error.response ? error.response.data.message : error.message,
      });
    }
  }
);

const connectionSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    setConnections: (state, action) => {
      state.connections = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createConnection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createConnection.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.connections.push(action.payload);
      })
      .addCase(createConnection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export const { setConnections, setLoading } = connectionSlice.actions;

export default connectionSlice.reducer;
