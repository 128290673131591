// src/app/features/formSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	entityName: '',
	entityDisplayName: '',
	entityDescription: "",
	entityRelations: [],
	attributes: [],
	selectedContinent: "",
	selectedCountry: "",
	selectedState: "",
	selectedCity: "",
	selectedDateFormat: "MM/DD/YYYY",
};

export const formSlice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		initializeFormData: (state, action) => {
			return { ...state, ...action.payload }; // Merge fetched data
		},
		updateEntityName: (state, action) => {
			state.entityName = action.payload;
			state.entityDisplayName = action.payload;
		},
		updateEntityDescription: (state, action) => {
			state.entityDescription = action.payload;
		},
		addEntityRelation: (state, action) => {
			state.entityRelations.push(action.payload);
		},
		removeEntityRelation: (state, action) => {
			state.entityRelations = state.entityRelations.filter(
				(relation) => relation._id !== action.payload // Assuming relation has an _id
			);
		},
		addAttribute: (state, action) => {
			state.attributes.push(action.payload);
		},
		updateAttribute: (state, action) => {
			const { index, updatedAttribute } = action.payload;
			state.attributes[index] = {
				...state.attributes[index], // Keep existing properties
				...updatedAttribute, // Overwrite with updates
			};
		},
		removeAttribute: (state, action) => {
			state.attributes = state.attributes.filter(
				(_, idx) => idx !== action.payload
			);
		},
		updateDateFormat: (state, action) => {
			state.selectedDateFormat = action.payload;
		},
		setSelectedContinent: (state, action) => {
			state.selectedContinent = action.payload;
		}
	},
});

// Export actions
export const {
	initializeFormData,
	updateEntityName,
	updateEntityDescription,
	addEntityRelation,
	removeEntityRelation,
	addAttribute,
	updateAttribute,
	removeAttribute,
	updateDateFormat,
	setSelectedContinent
} = formSlice.actions;

// Export reducer as default
export default formSlice.reducer;
