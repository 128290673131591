import { dateFormatOptionsValue } from "../_helpers/utils";
export const continentOptions = [
  { label: "Select", value: "" },
  { label: "Asia", value: "Asia" },
  { label: "Europe", value: "Europe" },
  { label: "Africa", value: "Africa" },
  { label: "Oceania", value: "Oceania" },
  { label: "North America", value: "North America" },
  { label: "South America", value: "South America" },
];
export const definePropertiesBasedOnAttributes = (attributeType, isNullable = false) => {
  switch (attributeType?.toLowerCase()) {
    case "string":
      return [
        {
          key: "isFixedLength",
          value: false,
          type: "toggle",
          keyDisplayName: "Fixed Length",
        },
        {
          key: "min",
          value: 4,
          type: "number",
          keyDisplayName: "Minimum Length",
          validationFuncName: "minValidation",
          dependsOn: [{ property: "isFixedLength", value: false }],
          validationRules: {
            required: true,
            min: {
              value: 4,
              message: "Minimum value should be 4",
            },
          },
        },
        {
          key: "max",
          value: 10,
          type: "number",
          keyDisplayName: "Maximum Length",
          dependsOn: [{ property: "isFixedLength", value: false }],
          validationFuncName: "maxValidation",
          validationRules: { required: true },
        },
        {
          key: "fixed",
          value: 4,
          type: "number",
          keyDisplayName: "Fixed Length",
          dependsOn: [{ property: "isFixedLength", value: true }],
          validationRules: {
            required: true,
            min: {
              value: 4,
              message: "Minimum value should be 4",
            },
          },
        },
        {
          key: "useCustomRules",
          value: false,
          type: "toggle",
          keyDisplayName: "Use Custom Rules",
          dependsOn: [{ property: "isFixedLength", value: true }],
        },
        {
          key: "customFormattingRules",
          value: [],
          type: "customRuleArray",
          keyDisplayName: "Custom Formatting Rules",
          dependsOn: [
            { property: "isFixedLength", value: true },
            { property: "useCustomRules", value: true },
          ],
        },
        {
          key: "startsWith",
          value: "",
          type: "text",
          keyDisplayName: "Starts With",
          dependsOn: [{ property: "useCustomRules", value: false }],
        },
        {
          key: "endsWith",
          value: "",
          type: "text",
          keyDisplayName: "Ends With",
          dependsOn: [{ property: "useCustomRules", value: false }],
        },
        {
          key: "caps",
          value: "initCaps",
          type: "radio",
          keyDisplayName: "Letter case",
          options: [
            { value: "initCaps", key: "Initial caps" },
            { value: "upperCase", key: "Uppercase" },
          ],
        },
        {
          key: "alphaNumeric",
          value: false,
          type: "toggle",
          keyDisplayName: "Alpha numeric",
          dependsOn: [{ property: "useCustomRules", value: false }],
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "number":
      return [
        {
          key: "min",
          value: 0,
          type: "number",
          validationFuncName: "minValidation",
          validationRules: {
            required: true,
            min: {
              value: 0,
              message: "Minimum value cannot be negative",
            },
          },
          keyDisplayName: "Minimum Value",
        },
        {
          key: "max",
          value: 2147483647,
          type: "number",
          keyDisplayName: "Maximum Value",
          validationFuncName: "maxValidation",
          validationRules: { required: true },
        },
        {
          key: "isDecimal",
          value: false,
          type: "toggle",
          keyDisplayName: "Is a decimal?",
        },
        {
          key: "noOfDecimals",
          value: "",
          type: "number",
          keyDisplayName: "No of decimals",
          dependsOn: [{ property: "isDecimal", value: true }],
          validationRules: {
            required: true,
            min: {
              value: 1,
              message: "Minimum value should be 1",
            },
          },
        },
        // {
        // 	key: "hasEmpty",
        // 	value: isNullable,
        // 	type: "toggle",
        // 	keyDisplayName: "Has Empty",
        // },
      ];
    case "date":
      return [
        {
          key: "dateFormat",
          keyDisplayName: "Date Format",
          value: "%m/%d/%Y",
          selectedFormat: "MM/DD/YYYY",
          type: "select",
          dropdownType: "simple",
          options: [
            { label: "MM/DD/YYYY", value: "%m/%d/%Y" },
            { label: "DD/MM/YYYY", value: "%d/%m/%Y" },
            { label: "YYYY/MM/DD", value: "%Y/%m/%d" },
          ],
        },
        {
          keyDisplayName: "Minimum Date",
          key: "min",
          value: "",
          type: "select",
          dropdownType: "date",
          validationRules: {
            required: true,
          },
          // validationFuncName: "minDateValidation",
        },
        {
          keyDisplayName: "Maximum Date",
          key: "max",
          value: "",
          type: "select",
          dropdownType: "date",
          validationRules: {
            required: true,
          },
          validationFuncName: "maxDateValidation",
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "first name":
    case "last name":
    case "full name":
      return [
        {
          keyDisplayName: "Democratic Region",
          key: "democraticRegion",
          value: "Indian",
          type: "select",
          dropdownType: "simple",
          options: [
            { label: "American", value: "American" },
            { label: "Indian", value: "Indian" },
          ],
        },
        {
          keyDisplayName: "Unique",
          key: "unique",
          value: false,
          type: "toggle",
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "country":
      return [
        {
          keyDisplayName: "Continent",
          key: "continent",
          type: "select",
          value: "",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
          options: continentOptions,
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "state":
      return [
        {
          key: "continent",
          value: "",
          type: "select",
          keyDisplayName: "Continent",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
          options: continentOptions,
        },
        {
          key: "country",
          value: "",
          type: "select",
          keyDisplayName: "Country",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "city":
      return [
        {
          key: "continent",
          value: "",
          type: "select",
          keyDisplayName: "Continent",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
          options: continentOptions,
        },
        {
          key: "country",
          value: "",
          type: "select",
          keyDisplayName: "Country",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "state",
          value: "",
          type: "select",
          keyDisplayName: "State",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "zip code":
      return [
        {
          keyDisplayName: "Continent",
          key: "continent",
          type: "select",
          value: "",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "country",
          value: "",
          type: "select",
          keyDisplayName: "Country",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "state",
          value: "",
          type: "select",
          keyDisplayName: "State",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          key: "city",
          value: "",
          type: "select",
          keyDisplayName: "City",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
      ];
    case "email":
      return [
        {
          keyDisplayName: "Domain",
          key: "domain",
          value: "",
          type: "text",
          validationRules: {
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
              message: "Invalid domain format",
            },
          },
          note: "Note: Example format 'xyz.com', 'xyz.co.in'",
        },
        // {
        // 	keyDisplayName: "Minimum Length",
        // 	key: "min",
        // 	value: "4",
        // 	type: "number",
        // },
        // {
        // 	keyDisplayName: "Maximum Length",
        // 	key: "max",
        // 	value: "10",
        // 	type: "number",
        // },
        {
          keyDisplayName: "Unique",
          key: "unique",
          value: false,
          type: "toggle",
        },
        {
          key: "hasEmpty",
          value: isNullable,
          type: "toggle",
          keyDisplayName: "Has Empty",
        },
      ];
    case "phone":
      return [
        {
          keyDisplayName: "Continent",
          key: "continent",
          type: "select",
          value: "Asia",
          dropdownType: "simple",
          options: [
            { label: "Asia", value: "Asia" },
            { label: "Europe", value: "Europe" },
            { label: "Africa", value: "Africa" },
            { label: "Oceania", value: "Oceania" },
            { label: "North America", value: "North America" },
            { label: "South America", value: "South America" },
          ],
        },
        {
          keyDisplayName: "Country",
          key: "country",
          type: "select",
          value: "",
          dropdownType: "simple",
          validationRules: {
            required: true,
          },
        },
        {
          keyDisplayName: "Phone Number",
          key: "numberFormat",
          value: "nnn-nnn-nnnn",
          type: "text",
          note: "Note: Allowed format 'nnn-nnn-nnnn'",
        },
        {
          keyDisplayName: "Append Country Code",
          key: "appendCountryCode",
          value: false,
          type: "toggle",
        },
        {
          keyDisplayName: "Unique",
          key: "unique",
          value: false,
          type: "toggle",
        },
      ];
    case "choice list":
      return [
        {
          keyDisplayName: "Custom option",
          key: "custom",
          value: [],
          type: "select",
          dropdownType: "autocomplete",
          note: "Note: Add custom options",
          validationRules: {
            required: true,
          },
        },
      ];
    default:
      return [];
  }
};

export const hasDependentTables = (attributes) => {
  return attributes.some((attr) => attr.DependentTableName !== "");
};

export const getDependentTableNames = (attributes) => {
  return attributes
    .filter((attr) => attr.DependentTableName !== "")
    .map((attr) => attr.DependentTableName)
    .reduce((uniqueNames, name) => {
      if (!uniqueNames.includes(name)) uniqueNames.push(name);
      return uniqueNames;
    }, []);
};

export const transformAttributes = (selctedTable) => {
  return {
    attributes: selctedTable?.ColumnDetails.map((attributeValues) => {
      const newProperties = definePropertiesBasedOnAttributes(
        attributeValues?.AttributeType,
        attributeValues.IsNullable,
      );
      const UpdatedProperties = appendPropertyValues(newProperties, attributeValues);

      return {
        attributeCustomName: attributeValues?.AttributeName,
        type: attributeValues?.AttributeType.toLowerCase(),
        isPII: attributeValues?.IsPII || false,
        isPrimaryKey: attributeValues?.IsPrimaryKey || attributeValues?.isPrimaryKey || false,
        IsForeignKey: attributeValues?.IsForeignKey || false,
        isForeignKey: attributeValues?.IsForeignKey || attributeValues?.isForeignKey || false,

        attributeName: attributeValues?.AttributeType || "string",
        properties: attributeValues?.IsForeignKey ? [] : UpdatedProperties,
        entityId: "",
        DependentTable: attributeValues?.DependentTable,
        DependentColumn: attributeValues?.DependentColumn,
      };
    }),
  };
};
export const appendPropertyValues = (newProperties, attributeValues) => {
  const UpdatedProperties = [];
  for (const property of newProperties) {
    if (property.key === "startsWith") {
      property.value = attributeValues?.StartsWith || property.value;
    } else if (property.key === "endsWith") {
      property.value = attributeValues?.EndsWith || property.value;
    } else if (property.key === "isFixedLength") {
      property.value = attributeValues?.IsFixedLength || property.value;
    } else if (property.key === "useCustomRules") {
      property.value = attributeValues?.UseCustomRules || property.value;
    } else if (property.key === "customFormattingRules") {
      property.value = (attributeValues?.CustomRules || []).map((e) => {
        return {
          segmentType: e.SegmentType,
          length: e?.Length || 0,
          content: e?.Value || "",
        };
      });
    } else if (property.key === "hasEmpty") {
      property.value = attributeValues?.HasEmpty || property.value;
    } else if (property.key === "min" && property.keyDisplayName === "Minimum Length") {
      property.value = attributeValues?.MinLength || property.value;
    } else if (property.key === "max" && property.keyDisplayName === "Maximum Length") {
      property.value = attributeValues?.MaxLength || property.value;
    } else if (property.key === "min" && property.keyDisplayName === "Minimum Value") {
      property.value = attributeValues?.MinLength || property.value;
    } else if (property.key === "max" && property.keyDisplayName === "Maximum Value") {
      property.value = attributeValues?.MaxLength || property.value;
    } else if (property.key === "alphaNumeric") {
      property.value = attributeValues?.IsAlphaNumeric || property.value;
    } else if (property.key === "domain") {
      property.value = attributeValues?.Domain || property.value;
    } else if (property.key === "unique") {
      property.value = attributeValues?.IsUnique || property.value;
    } else if (property.key === "isDecimal") {
      property.value = attributeValues?.IsDecimal || property.value;
    } else if (property.key === "continent") {
      property.value = attributeValues?.Continent || property.value;
    } else if (property.key === "country") {
      property.value = attributeValues?.Country || property.value;
    } else if (property.key === "state") {
      property.value = attributeValues?.State || property.value;
    } else if (property.key === "city") {
      property.value = attributeValues?.City || property.value;
    } else if (property.key === "noOfDecimals") {
      property.value = attributeValues?.NoOfDecimals || property.value;
    } else if (property.key === "appendCountryCode") {
      property.value = attributeValues?.AppendCountryCode || property.value;
    } else if (property.key === "numberFormat") {
      property.value = attributeValues?.numberFormat || property.value;
    } else if (property.key === "democraticRegion") {
      property.value = attributeValues?.Region || property.value;
    } else if (property.key === "fixed") {
      property.value = attributeValues?.FixedLength || property.value;
    } else if (property.key === "dateFormat") {
      property.selectedFormat = attributeValues.DateFormat || null;
      property.value = dateFormatOptionsValue(attributeValues.DateFormat) || property.value;
    } else if (property.key === "min" && property.keyDisplayName === "Minimum Date") {
      property.value = attributeValues?.MinDate || property.value;
    } else if (property.key === "max" && property.keyDisplayName === "Maximum Date") {
      property.value = attributeValues?.MaxDate || property.value;
    } else if (property.key === "caps") {
      property.value = attributeValues?.IsUpperCase ? "upperCase" : "initCaps" || property.value;
    } else if (property.key === "custom") {
      property.value =
        attributeValues?.ChoiceList.map((e) => {
          return { value: e, label: e };
        }) || property.value;
    } else {
      return property;
    }
    UpdatedProperties.push(property);
  }
  return UpdatedProperties;
};

export const removeNullAndUndefinedKeysFromObject = (obj) => {
  const cleanedObj = {};
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      cleanedObj[key] = removeNullAndUndefinedKeysFromObject(obj[key]);
    } else if (obj[key] !== null && obj[key] !== undefined) {
      cleanedObj[key] = obj[key];
    }
  }
  return cleanedObj;
};
export const formatPayloadClonedEntity = (data) => {
  const Tables = [];
  for (const entity of data?.selectedTables || data || []) {
    const val = {
      IsChecked: true,
      IsDisabled: entity.isDisabled === true,
      RowCount: 1,
      TableName: entity?.entityName || entity?.TableName,
      DataSetName: "",
      EntityRelations: entity?.entityRelations,
      ColumnDetails: entity?.attributes?.map((e) => {
        const IsUnique = (e?.properties || []).find((el) => el.key === "unique");
        const domain = (e?.properties || []).find((el) => el.key === "domain");

        const Max = (e?.properties || []).find((el) => el.key === "max");
        const DateFormat = (e?.properties || []).find((el) => el.key === "dateFormat");
        const Min = (e?.properties || []).find((el) => el.key === "min");
        const Country = (e?.properties || []).find((el) => el.key === "country");
        const State = (e?.properties || []).find((el) => el.key === "state");
        const City = (e?.properties || []).find((el) => el.key === "city");
        const ChoiceList = (e?.properties || []).find((el) => el.key === "custom");
        const HasEmpty = (e?.properties || []).find((el) => el.key === "hasEmpty");
        const IsFixedLength = (e?.properties || []).find((el) => el.key === "isFixedLength");
        const useCustomRules = (e?.properties || []).find((el) => el.key === "useCustomRules");
        const fixed = (e?.properties || []).find((el) => el.key === "fixed");
        let CustomFormattingRules = e?.properties?.find((el) => el.key === "customFormattingRules");
        CustomFormattingRules = (CustomFormattingRules?.value || [])?.map((ele) => {
          const obj = {};
          obj.SegmentType = ele?.segmentType;
          obj.Length = ele?.length;
          obj.Value = ele?.content;
          return obj;
        });
        const startsWith = (e?.properties || []).find((el) => el.key === "startsWith");
        const endsWith = (e?.properties || []).find((el) => el.key === "endsWith");
        const alphaNumeric = (e?.properties || []).find((el) => el.key === "alphaNumeric");
        const IsUpperCase = (e?.properties || []).find((el) => el.key === "caps");
        const isDecimal = (e?.properties || []).find((el) => el.key === "isDecimal");
        const noOfDecimals = (e?.properties || []).find((el) => el.key === "noOfDecimals");
        const appendCountryCode = (e?.properties || []).find((el) => el.key === "appendCountryCode");
        const democraticRegion = (e?.properties || []).find((el) => el.key === "democraticRegion");
        const continent = (e?.properties || []).find((el) => el.key === "continent");
        const fk = entity?.entityRelations.find(
          (el) =>
            (el?.TableName === e.TableName || el?.fk_table_name === e.TableName) &&
            (e.Name === el?.fk_column_name || e.Name === el?.ForeignColumnName),
        );
        const DependentTable = fk?.ForeignTableName || fk?.pk_table_name || e.DependentTable;
        const DependentColumn = fk?.ForeignColumnName || fk?.fk_column_name || e.DependentColumn;
        const isDepen = DependentColumn !== undefined || e?.isForeignKey;
        const attributeProperties = {
          IsForeignKey: e?.IsForeignKey || e?.isForeignKey,
          IsPII: e?.isPII,
          Continent: continent?.value,
          Region: democraticRegion?.value,
          IsPrimaryKey: e?.IsPrimaryKey || e?.isPrimaryKey || false,
          DependentTable: e?.relationTable || DependentTable || undefined,
          DependentColumn: e?.referredName || DependentColumn || undefined,
          IsDependent: isDepen,
          AttributeName: e?.attributeCustomName || e?.Name,
          OriginalAttributeName: e?.Name,
          AttributeType: e?.attributeName || e.type,
          IsUnique: IsUnique?.value,
          AppendCountryCode: appendCountryCode?.value,
          IsUpperCase: IsUpperCase?.value === "upperCase",
          IsAlphaNumeric: alphaNumeric?.value,
          EndsWith: endsWith?.value,
          StartsWith: startsWith?.value,
          MaxLength: !DateFormat ? Max?.value : undefined,
          MinLength: !DateFormat ? Min?.value : undefined,
          MaxDate: DateFormat && Max?.value,
          MinDate: DateFormat && Min?.value,
          DateFormat: DateFormat?.selectedFormat,
          HasEmpty: HasEmpty?.value,
          IsDecimal: isDecimal?.value,
          NoOfDecimals: noOfDecimals?.value,
          IsFixedLength: IsFixedLength?.value || null,
          FixedLength: fixed?.value === "" ? null : fixed?.value ? Number(fixed?.value) : null,
          CustomRules: CustomFormattingRules,
          UseCustomRules: useCustomRules?.value,
          Country: Country?.value,
          State: State?.value,
          City: City?.value,
          Domain: domain?.value,
          ChoiceList: ChoiceList && (ChoiceList?.value || []).map((e) => e.value),
        };
        const cleanedObj = removeNullAndUndefinedKeysFromObject(attributeProperties);
        return cleanedObj;
      }),
    };
    Tables.push(val);
  }
  return Tables;
};
