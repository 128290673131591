import dayjs from "dayjs";

export const minValidation = (minValue) => {
  const min = parseInt(minValue, 10);

  // Check for negative numbers
  if (min < 0) {
    return "Max cannot be negative";
  }

  if (min && isNaN(min)) {
    return "Min must be a number";
  }
  return true;
};

export const maxValidation = (maxValue, allValues) => {
  // Check if the max value is not a number
  if (maxValue && isNaN(maxValue)) {
    return "Max must be a number";
  }

  const max = parseInt(maxValue, 10);

  // Check for negative numbers
  if (max < 0) {
    return "Max cannot be negative";
  }

  if (max && isNaN(max)) {
    return "Max must be a number";
  }

  if (max > 2147483647) {
    return "Max cannot be greater than 2147483647";
  }

  const min = parseInt(allValues.find((prop) => prop.key === "min")?.value, 10);

  // Check if min value is greater than max value
  if (min) {
    if (!isNaN(min) && (max < min || max === min)) {
      return "Max must be greater than Min";
    }
  }

  return true; // Validation passes
};

export const customNameValidation = (customName) => {
  if (customName.trim() === "") {
    return "Custom Name cannot be empty";
  }
  if (customName.length < 3) {
    return "Custom Name cannot be less than 3 characters";
  }
  return null;
};

export const domainValidation = (domain) => {
  if (domain.trim() === "") {
    return "Domain cannot be empty";
  }
  return null;
};

// export const minDateValidation = (minDate, allValues) => {
//   const maxDate = allValues.find((prop) => prop.key === "max")?.value;
//   // if(maxDate) {
//   //   const max = dayjs(maxDate);
//   //   const min = dayjs(minDate);
//   //   // if(dayjs(min).isAfter(max)) {
//   //   //   return "Min date should be less than max date";
//   //   // }
//   // }
//   return true;
// };

export const maxDateValidation = (maxDate, prevDateFormat, allValues) => {
  const minDate = allValues.find((prop) => prop.key === "min")?.value;
  const format = allValues.find((prop) => prop.key === "dateFormat");
  const dateFormat = format.options.find((option) => option.value === format.value)?.label;
  if (minDate) {
    const max = dayjs(maxDate).format(dateFormat);
    const min = dayjs(minDate).format(dateFormat);
    if (dayjs(min).isAfter(max) || dayjs(min).isSame(max)) {
      return "Max date should be greater than Min date";
    }
  }
  return true;
};
