import { ChevronLeft, Error } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, IconButton, Paper, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SnackbarContext } from "../App";
import { getDatasetCreationProgress } from "../app/features/datasetsSlice";
import AgGridSSRM from "./AgGrid/AgGridSSRM";
import InnerHeader from "./Layout/InnerHeader";
import TabPanel from "./TabPanel";

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tab-panel-${index}`,
  };
};

const ViewDataset = () => {
  const gridRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setSnack } = useContext(SnackbarContext);

  const [tabIndex, setTabIndex] = useState(0);
  const [datasetData, setDatasetData] = useState(null);
  const [currentActiveTab, setCurrentActiveTab] = useState(null);

  const fetchDatasetProgress = () => {
    try {
      dispatch(getDatasetCreationProgress({ ids: [id] }))
        .then((res) => {
          if (res.meta.requestStatus === "rejected") {
            throw new Error(res.payload);
          } else if (res.meta.requestStatus === "fulfilled") {
            const datasetProgressInfo = res.payload[0];
            setDatasetData(res.payload[0]);
            setCurrentActiveTab(datasetProgressInfo?.result?.[tabIndex]);
          }
        })
        .catch((err) => {
          setSnack({
            message: err.message,
            open: true,
            colour: "error",
          });
        });
    } catch (e) {
      setSnack({
        message: e.message,
        open: true,
        colour: "error",
      });
    }
  };

  useEffect(() => {
    fetchDatasetProgress();
  }, []);
  const downlaodDataset = (url) => {
    window.open(`${process.env.REACT_APP_SERVER_BASE_URL}datasets/downloadDataset/${url}`, "_blank");
  };
  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
    setCurrentActiveTab(datasetData?.result?.[newValue]);
  };

  const getStatusIndicator = (entityId) => {
    const status = datasetData.result?.find((dataset) => dataset.referenceName === entityId)?.status;

    const backgroundColor = status === 3 ? "success.main" : status === 4 ? "error.main" : "warning.main";

    return (
      <Box
        sx={{
          ml: 1,
          width: 8,
          height: 8,
          borderRadius: "50%",
          backgroundColor: backgroundColor,
        }}
      />
    );
  };

  const overallStatus = (status, url) => {
    const statusInfo = {
      3: { text: "Success", color: "#2e7d32", showDownload: true },
      4: { text: "Failed", color: "#d32f2f", showDownload: false },
      default: { text: "Pending", color: "#f57c00", showDownload: false },
    };

    const { text, color, showDownload } = statusInfo[status] || statusInfo.default;

    return (
      <Box display="flex" alignItems="center" mb="16px">
        <Typography variant="body2">
          Status: <span style={{ fontWeight: "bold", color }}>{text}</span>
        </Typography>
        {showDownload && (
          <Box display="flex" ml="auto">
            <IconButton size="small" color="primary" onClick={() => downlaodDataset(url)}>
              <DownloadIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <InnerHeader>
        <Typography variant="h6">Cloned Datasets View Data</Typography>
      </InnerHeader>
      <Box sx={{ flex: 1 }} className="pt74">
        <Paper
          sx={{
            flex: 1,
            height: "calc(100vh - 132px)",
            overflow: "auto",
            p: 2,
          }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Tooltip title={"Back to datasets"}>
              <IconButton onClick={() => navigate(-1)} size="small" sx={{ p: 0, mr: 1 }}>
                <ChevronLeft style={{ cursor: "pointer", fontSize: "24px" }} />
              </IconButton>
            </Tooltip>
            <Typography variant="h3" sx={{ fontSize: "20px" }}>
              {datasetData?.datasetName}
            </Typography>
          </Box>

          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            sx={{ width: "100%" }}
            variant="scrollable"
            scrollButtons="auto">
            {datasetData?.result?.map((table, index) => (
              <Tab
                key={`tab-${index}`}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}>
                    {table.referenceName}
                    {getStatusIndicator(table.referenceName)}
                  </Box>
                }
                sx={{ px: 1 }}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {datasetData?.result?.map((table, index) => {
            return (
              <TabPanel key={table?.entities} value={tabIndex} index={index}>
                {currentActiveTab && (
                  <>
                    {overallStatus(table?.status, `${datasetData?._id}_${table?.referenceName}.csv`)}
                    <AgGridSSRM
                      gridRef={gridRef}
                      apiMethod="SyntheticDataset"
                      payload={{
                        requestId: datasetData?._id,
                        datasetName: table?.referenceName,
                        totalRows: table?.count,
                      }}
                    />
                  </>
                )}
              </TabPanel>
            );
          })}

          {/* <Box sx={{ my: 2 }}>
            <SkeletonLoader />
          </Box> */}
        </Paper>
      </Box>
    </>
  );
};

export default ViewDataset;
