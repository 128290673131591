import { Box } from "@mui/material";
import { PropTypes } from "prop-types";

const TabPanel = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}>
      {value === index && <Box sx={{ py: 0 }}>{children}</Box>}
    </Box>
  );
};

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  entityStatus: PropTypes.string,
  totalBatchCount: PropTypes.number,
  batchesCompleted: PropTypes.number,
};
