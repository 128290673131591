import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InnerHeader from "../Layout/InnerHeader";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../App";
import { autoScroll } from "../../_helpers/utils";
import { setSelectedId } from "../../app/features/defaultActionsSlice";
import { deleteEntity, fetchAllEntities, setEntity } from "../../app/features/entitySlice";
import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";
import CustomDrawer from "../CustomDrawer";
import SkeletonLoader from "../SkeletonLoader";
import ClonedEntityCard from "./ClonedEntityCard";
import PreviewEntity from "./PreviewEntity";

const ClonedEntitySets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setSnack } = useContext(SnackbarContext);
  const ScrollRef = useRef();

  // States
  const [openDialog, setOpenDialog] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [activeEntityId, setActiveEntityId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  // Redux
  const { entities } = useSelector((state) => state.entity);
  const loading = useSelector((state) => state.entity.loading);
  const deleteLoading = useSelector((state) => state.entity.deleteLoading);

  // Component Mount Cycles
  useEffect(() => {
    dispatch(fetchAllEntities({ type: "cloned" }))
      .then((response) => {
        if (response.meta.responseStatus === "rejected") {
          setSnack({
            message: response.payload,
            open: true,
            colour: "error",
          });
        }
      })
      .catch((err) => {
        setSnack({
          message: err.message,
          open: true,
          colour: "error",
        });
      });
  }, []);

  // Functions

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedEntity({});
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (id) => {
    setOpenDialog(true);
    setSelectedRow(id);
  };

  const handleDeleteEntity = () => {
    try {
      dispatch(deleteEntity({ type: "cloned", id: selectedRow })).then((response) => {
        if (response.payload.status.toLowerCase() === "success") {
          dispatch(setEntity(response.payload.data.entities));
          setSnack({
            message: "Entity deleted successfully",
            open: true,
            colour: "success",
          });
          setOpenDialog(false);
        }
      });
      dispatch(setSelectedId(null));
    } catch (err) {
      setSnack({
        message: "Something went wrong",
        open: true,
        colour: "error",
      });
      console.log(err);
    }
  };
  const handleEditEntity = (entity) => {
    console.log(entity, 113);
    navigate(
      `/cloned/create?entityName=${entity.entityName}&connectionId=${entity.connectionId}&showSaveForm=flase&entityId=${entity?._id}`,
      { state: { entityId: entity?._id } },
    );
  };

  const handleViewEntity = (entity) => {
    setSelectedEntity(entity);
    autoScroll(ScrollRef);
  };

  const handleGenerateDataset = (entity) => {
    setSelectedEntity(entity);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <InnerHeader>
        <Typography variant="h6">Cloned Entity Sets</Typography>
        <Box sx={{ display: "flex", ml: "auto", gap: 1 }}>
          <Button
            size="small"
            variant="contained"
            disableElevation
            sx={{ textTransform: "capitalize" }}
            onClick={() => navigate("/cloned/create")}>
            Create New Entity
          </Button>
          <Button
            variant="contained"
            disableElevation
            size="small"
            color="warning"
            onClick={() => handleGenerateDataset({})}>
            {"Generate Synthetic Data "}
          </Button>
        </Box>
      </InnerHeader>

      <Box mt={2} className="pt74">
        {loading ? (
          <SkeletonLoader />
        ) : (
          <Box>
            {entities && entities?.length > 0 ? (
              <Grid container spacing={1}>
                {(entities || [])?.map((entity, index) => {
                  return (
                    <Grid md={3} item key={index}>
                      <ClonedEntityCard
                        key={entity?._id}
                        entitySet={entity}
                        handleViewEntity={handleViewEntity}
                        handleEditEntity={handleEditEntity}
                        handleGenerateDataset={handleGenerateDataset}
                        isActive={entity?._id === activeEntityId}
                        showDialog={handleOpenDialog}
                        onActiveChange={() => setActiveEntityId(entity?._id)}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box className="noDataFound">
                <Typography>No entities found. </Typography>
                <Button variant="contained" size="small" onClick={() => navigate("/cloned/create")}>
                  Create New Entity
                </Button>
              </Box>
            )}
          </Box>
        )}
        {selectedEntity && Object.keys(selectedEntity).length !== 0 && !isDrawerOpen && (
          <PreviewEntity
            ScrollRef={ScrollRef}
            selectedEntity={selectedEntity}
            setSelectedEntity={setSelectedEntity}
            setActiveEntityId={setActiveEntityId}
          />
        )}
      </Box>

      {openDialog && (
        <CustomDeleteModal
          loading={deleteLoading}
          disabled={deleteLoading}
          onClickDelete={handleDeleteEntity}
          handleClose={handleCloseDialog}
        />
      )}
      <CustomDrawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        entity={selectedEntity}
        setSelectedEntity={setSelectedEntity}
      />
    </>
  );
};

export default ClonedEntitySets;
