import React, { createContext, useLayoutEffect, useState } from "react";
import { CssBaseline, Snackbar, ThemeProvider } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import "./App.css";

import { interceptor } from "./services/axios-interceptor";
import SessionTimeout from "./services/SessionTimeout";
import { toasterMessages, logOut } from "./_helpers/utils";
import { userLocalSession } from "./_helpers/Constant";
import { useTranslation } from "react-i18next";
import { UserDetailsContext } from "./services/UserDetailsContext";
import { ColorModeContext, useMode } from "./theme/theme";
import RouteManager from "./RouteManager";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarContext = createContext({});
export const SideBarContext = createContext({});

const initialState = {
  message: "",
  colour: "",
  open: false,
};

const App = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [theme, colorMode] = useMode();

  const userData = JSON.parse(localStorage.getItem(userLocalSession));
  const token = userData?.Token;
  const [snack, setSnack] = useState(initialState);
  const [userDetails, setUserDetails] = useState(userData || {});
  const [drawerWidth, setDrawerWidth] = useState(200);

  interceptor(userData, setUserDetails, navigate, setSnack);

  let snackMessage = "Oops!! There was a glitch. Please try in a while.";
  if (typeof snack.message === "string") {
    if (snack.message.includes(" 500") || snack.message.includes(" 400")) {
      snackMessage = "Oops!! There was a glitch. Please try in a while.";
    } else {
      snackMessage = toasterMessages[snack.message] || snack.message;
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack({ ...snack, open: false });
  };

  const handleSessionTimeout = () => {
    if (userData?.SessionId) {
      setSnack({
        message: "Your session has expired. Please log in again.",
        open: true,
        colour: "error",
      });
      logOut(userData, setUserDetails, navigate);
    }
  };

  const checkAccess = (eName, action) => {
    const user = userData?.RolePermissions?.find((e) => e?.EntityName === eName);
    return user?.[action] ?? false;
  };

  useLayoutEffect(() => {
    setUserDetails(userData);
  }, [token]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {token && <SessionTimeout timeout={1000 * 60 * 15} onTimeout={handleSessionTimeout} />}
        <UserDetailsContext.Provider value={{ userDetails, setUserDetails, checkAccess }}>
          <SideBarContext.Provider value={{ setDrawerWidth, drawerWidth }}>
            <SnackbarContext.Provider value={{ snack, setSnack }}>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snack.open}
                autoHideDuration={3000}
                onClose={handleClose}>
                <Alert onClose={handleClose} severity={snack.colour || "success"}>
                  {t(snackMessage)}
                </Alert>
              </Snackbar>
              <RouteManager />
            </SnackbarContext.Provider>
          </SideBarContext.Provider>
        </UserDetailsContext.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
