import { Delete, Edit, PostAdd, Visibility } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedId } from "../../app/features/defaultActionsSlice";

const EntityCard = ({
  entity,
  showDialog,
  handleGenerateDataset,
  handleViewEntity,
  handleEditEntity,
  isActive,
  onActiveChange,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <Box
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
      sx={{
        padding: 2,
        background: theme?.palette?.mode === "dark" ? "transparent" : "#FFF",
        position: "relative",
        boxShadow: isActive ? "0 0.6em 1em -0.3em rgba(9, 110, 182, 0.8)" : "0 0px 12px rgb(0 0 0 / 8%)",
        cursor: "pointer",
        borderRadius: "15px",
        // border: isActive ? "1px solid #096eb6" : "1px solid #eee",
      }}>
      <Box>
        <Tooltip title={entity.entityDisplayName}>
          <Typography variant="h6">{entity.entityDisplayName}</Typography>
        </Tooltip>
        <Typography variant="body1" component="p">
          {entity?.entityDescription?.trim()?.length > 0 ? entity.entityDescription : "No description provided"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mt: 1,
            justifyContent: "end",
          }}>
          <Tooltip title="Create Dataset">
            <IconButton
              sx={{
                background: "#6800B7",
                width: 24,
                height: 24,
                "&:hover": {
                  background: "#6800B7",
                },
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleGenerateDataset(entity);
              }}>
              <PostAdd style={{ fontSize: 16 }} sx={{ color: "#FFF" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Entity">
            <IconButton
              sx={{
                background: "#2196F3",
                width: 24,
                height: 24,
                "&:hover": {
                  background: "#2196F3",
                },
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onActiveChange();
                handleViewEntity(entity);
              }}>
              <Visibility style={{ fontSize: 16 }} sx={{ color: "#FFF" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Entity">
            <IconButton
              sx={{
                background: "#E68200",
                width: 24,
                height: 24,
                "&:hover": {
                  background: "#E68200",
                },
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleEditEntity(entity?._id);
              }}>
              <Edit style={{ fontSize: 16 }} sx={{ color: "#FFF" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete Entity">
            <IconButton
              sx={{
                background: "#F9594B",
                width: 24,
                height: 24,
                "&:hover": {
                  background: "#F9594B",
                },
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(setSelectedId(entity._id));
                showDialog(entity._id);
              }}>
              <Delete style={{ fontSize: 16 }} sx={{ color: "#FFF" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default EntityCard;
