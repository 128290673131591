import { Box, Dialog } from "@mui/material";
import { useContext, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { SnackbarContext } from "../../../App";
import APIServices from "../../../services/app.service";
import ConnectionsListGrid from "./ConnectionsGridView";
import ConnectionsListView from "./ConnectionsListView";
import PreviewDBConnection from "./PreviewDBConnection";

const DataSourceDetails = ({ gridRef, refreshData }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const DSType = searchParams.get("type");
  const viewType = searchParams.get("view");
  const previewConId = searchParams.get("previewConId");
  const [tablesList, setTablesList] = useState([]);
  const [tablesLoading, setTablesLoading] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState({});
  // const [columns, setColumns] = useState([]);
  const { setSnack } = useContext(SnackbarContext);

  const closePreview = () => {
    const param = searchParams.get("previewConId");
    if (param) {
      searchParams.delete("previewConId");
      setSearchParams(searchParams);
    }
  };

  const getTablesData = async (row) => {
    setSelectedConnection(row);
    const connectionId = row?.id;

    setTablesLoading(true);
    try {
      if (DSType !== "Files") {
        const results = await APIServices.ConnectionDetails(connectionId);
        setTablesList(results?.data?.tables);
      } else {
        // const response = await APIServices.GetFilesData({ id: connectionId });
        // setColumns(response?.data?.result?.rows[0]);
      }
    } catch (e) {
      setSnack({
        message: e?.response?.data?.message ?? e.message,
        open: true,
        colour: "error",
      });
    }
    setTablesLoading(false);
  };

  return (
    <Box>
      {viewType === "grid" ? (
        <ConnectionsListGrid />
      ) : (
        <ConnectionsListView gridRef={gridRef} refreshData={refreshData} getTablesData={getTablesData} />
      )}
      {previewConId && (
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={true}
          onClose={closePreview}
          sx={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}>
          <PreviewDBConnection
            connectionDetails={selectedConnection}
            tablesList={tablesList}
            tablesLoading={tablesLoading}
            closePreview={closePreview}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default DataSourceDetails;
