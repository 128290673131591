import { useState, useEffect, useContext } from "react";
import { Box, Grid, Typography, Switch, TextField, FormControlLabel, Button, Autocomplete, Alert } from "@mui/material";
import DroppableArea from "../DroppableArea";
import AttributesToolbar from "../AttributesToolbar";
import PropertiesContainer from "../PropertiesContainer";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider, MultiBackend } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { useForm, FormProvider, Controller, useFieldArray } from "react-hook-form";
import { fetchAllEntities, fetchEntityById, createNewEntity, editEntity } from "../../app/features/entitySlice";
import { Save, Clear } from "@mui/icons-material";
import InnerHeader from "../Layout/InnerHeader";
import { unwrapResult } from "@reduxjs/toolkit";
import { useLocation, useNavigate } from "react-router-dom";
import { SnackbarContext } from "../../App";
import { formatPayloadClonedEntity, transformAttributes } from "../../utils/formInfo";
import { LoadingButton } from "@mui/lab";
import { updateGlobalDropdown } from "../../app/features/dropdownSlice";

const CreateNewEntity = () => {
  const defaultValues = {
    entityName: "",
    entityDisplayName: "",
    entityDescription: "",
    entityRelations: [],
    attributes: [],
  };

  const methods = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const { setValue } = methods;

  const location = useLocation();
  const entityId = location.state?.entityId;
  const dispatch = useDispatch();
  const { entities } = useSelector((state) => state.entity);
  const loading = useSelector((state) => state.entity.loading);
  const [foreignRelations, setForeignRelations] = useState(false);
  const [disableForeignRelations, setDisableForeignRelations] = useState(false);
  const { selectedAttributeIndex } = useSelector((state) => state.attributes);
  const [entityInfo, setEntityInfo] = useState([]);
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [finalErrors, setFinalErrors] = useState([]);

  const {
    fields,
    append: appendAttribute,
    remove,
    replace,
  } = useFieldArray({
    control: methods.control,
    name: "attributes",
  });

  const handleAddForeignAttributes = (newRelations) => {
    const existingAttributeIds = fields.map((attr) => attr?.entityId);
    const newAttributes = [];
    newRelations.forEach((relation) => {
      if (relation?.selectedTables.length !== 0) {
        const newAttribute = relation?.selectedTables[0]?.ColumnDetails.find((attr) => attr.IsPrimaryKey === true);
        if (newAttribute) {
          const updatedAttribute = {
            ...newAttribute,
            entityId: relation?.selectedTables[0]?._id,
          };
          if (!existingAttributeIds.includes(updatedAttribute?.entityId)) {
            const modifiedAttribute = {
              ...updatedAttribute,
              IsPrimaryKey: false,
              type: newAttribute?.AttributeType,
              referredName: newAttribute?.AttributeName,
              referenceName: relation?.entityDisplayName,
              isForeignKey: true,
              relationTable: relation?.entityName,
            };
            newAttributes.push(modifiedAttribute);
          }
        }
      }
    });
    const updatedAttributes = [...fields, ...newAttributes];
    replace(updatedAttributes);
  };

  const handleRemoveForeignAttributes = (remainingRelations) => {
    const remainingRelationIds = remainingRelations
      .flatMap((relation) =>
        relation?.selectedTables[0]?.ColumnDetails?.flatMap((attr) =>
          attr?.IsPrimaryKey ? relation?.selectedTables[0]?._id : [],
        ),
      )
      .filter((id) => id !== undefined);
    const filteredFields = fields.filter((field) => remainingRelationIds.includes(field?.entityId) || field?._id);
    replace(filteredFields);
  };

  const attributes = methods.watch("attributes");

  useEffect(() => {
    methods.trigger();
  }, [methods]);

  useEffect(() => {
    resetGlobaldropdown(["continent", "country", "state"]);
    dispatch(fetchAllEntities({ type: "custom" })).then((res) => {
      if (res.meta.responseStatus === "rejected") {
        setSnack({
          message: "Failed to fetch entities",
          open: true,
          colour: "error",
        });
      } else {
        if (!entityId) {
          setValue("entityName", "");
          setValue("entityDisplayName", "");
          setValue("entityDescription", "");
          setValue("entityRelations", []);
          setValue("attributes", []);
          const filteredEntities = res?.payload?.filter((entity) => {
            const attributes = entity?.selectedTables[0];
            return attributes?.ColumnDetails?.some((attribute) => attribute.IsPrimaryKey);
          });
          setEntityInfo([...filteredEntities]);
          if (filteredEntities === 0) {
            setDisableForeignRelations(true);
          } else {
            setDisableForeignRelations(false);
          }
        } else {
          const entityOptions = res?.payload?.filter((entity) => {
            if (entity._id !== entityId) {
              const attributes = entity?.selectedTables[0];
              return attributes?.ColumnDetails?.some((attribute) => attribute.IsPrimaryKey);
            }
            return null;
          });
          if (entityOptions.length === 0) {
            setDisableForeignRelations(true);
          } else {
            setDisableForeignRelations(false);
          }
          setEntityInfo(entityOptions);
          dispatch(fetchEntityById({ type: "custom", id: entityId }))
            .then((res) => {
              if (res.meta.responseStatus === "rejected") {
                setSnack({
                  message: "Failed to fetch entity",
                  open: true,
                  colour: "error",
                });
              } else {
                const entity = unwrapResult(res);
                const editAttributes = entity?.selectedTables[0];
                setForeignRelations(entity?.entityRelations?.length > 0);
                setValue("entityName", entity.entityName);
                setValue("entityDisplayName", entity.entityDisplayName);
                setValue("entityDescription", entity.entityDescription);
                if (entity?.entityRelations?.length > 0) {
                  const relatedEntities = entities.filter((e) =>
                    entity?.entityRelations?.includes(e.entityDisplayName),
                  );
                  setValue("entityRelations", relatedEntities);
                  defaultValues.entityRelations = relatedEntities;
                }
                const ColumnDetails = transformAttributes(editAttributes);
                const cityDetails = ColumnDetails?.attributes.find(
                  (e) => e?.attributeName === "City" || e?.attributeName === "State" || e?.attributeName === "Country",
                );
                if (cityDetails) {
                  for (const row of cityDetails.properties || []) {
                    dispatch(updateGlobalDropdown({ key: row.key, value: row?.value || "" }));
                  }
                }
                setValue("attributes", ColumnDetails?.attributes);
              }
            })
            .catch((err) => {
              console.log("Inside this err ", err.message);
              setSnack({
                message: err.message,
                open: true,
                colour: "error",
              });
            });
        }
      }
    });
  }, []);

  const handleReset = () => {
    if (!entityId) {
      setValue("entityName", "");
      setValue("entityDisplayName", "");
      setValue("entityDescription", "");
      setValue("entityRelations", []);
      setValue("attributes", []);
      methods.trigger();
    } else {
      dispatch(fetchEntityById({ id: entityId, type: "custom" }))
        .then((res) => {
          if (res.meta.responseStatus === "rejected") {
            setSnack({
              message: "Failed to fetch entity",
              open: true,
              colour: "error",
            });
          } else {
            const entity = unwrapResult(res);
            const editAttributes = entity?.selectedTables?.[0]?.attributes || {};
            setForeignRelations(entity?.entityRelations?.length > 0);
            setValue("entityName", entity.entityName);
            setValue("entityDisplayName", entity.entityDisplayName);
            setValue("entityDescription", entity.entityDescription);
            if (entity?.entityRelations?.length > 0) {
              const relatedEntities = entities.filter((e) => entity.entityRelations.includes(e._id));
              setValue("entityRelations", relatedEntities);
              defaultValues.entityRelations = relatedEntities;
            } else {
              setValue("entityRelations", []);
              defaultValues.entityRelations = [];
            }
            setValue("attributes", editAttributes);
            methods.trigger();
          }
        })
        .catch((err) => {
          console.log("Line 175 catch ", err.message);
          setSnack({
            message: err.message,
            open: true,
            colour: "error",
          });
        });
    }
  };

  const resetGlobaldropdown = (keys) => {
    for (const key of keys) {
      dispatch(updateGlobalDropdown({ key, value: "" }));
    }
  };

  const handleFormSubmit = async (data) => {
    const isErrors = data?.attributes
      ?.map((attribute) => {
        if (!attribute?.attributeCustomName) {
          return attribute._id;
        }
        return null;
      })
      ?.filter((each) => Boolean(each));

    if (isErrors?.length > 0) {
      setFinalErrors(isErrors);
      setSnack({
        message: "Fill All Data",
        open: true,
        colour: "error",
        severity: "error",
      });
      return;
    }
    setFinalErrors([]);

    try {
      if (data.entityRelations.length > 0) {
        data.entityRelations = data.entityRelations.map((relation) => {
          return relation.entityDisplayName;
        });
      }
      data.entityType = "custom";
      const updatedPayload = formatPayloadClonedEntity([data]);
      data.selectedTables = updatedPayload;

      if (entityId) {
        dispatch(editEntity({ ...data, _id: entityId }))
          .then((res) => {
            if (res.payload.message) {
              setSnack({
                message: res.payload.message,
                open: true,
                colour: "error",
              });
            } else {
              setSnack({
                message: "Entity updated successfully",
                open: true,
                colour: "success",
              });
              navigate("/");
            }
          })
          .catch((err) => {
            setSnack({
              message: err.message,
              open: true,
              colour: "error",
            });
          });
      } else {
        dispatch(createNewEntity(data))
          .then((res) => {
            if (res.meta.requestStatus === "rejected") {
              setSnack({
                message: res.payload,
                open: true,
                colour: "error",
              });
            } else {
              if (res.payload.message) {
                setSnack({
                  message: res.payload.message,
                  open: true,
                  colour: "error",
                });
              } else {
                setSnack({
                  message: "Entity created successfully",
                  open: true,
                  colour: "success",
                });
                navigate("/");
              }
            }
          })
          .catch((err) => {
            setSnack({
              message: err.message,
              open: true,
              colour: "error",
            });
          });
      }
    } catch (err) {
      setSnack({
        message: err.message,
        open: true,
        colour: "error",
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <InnerHeader>
        <Typography variant="h6">{entityId ? "Edit Entity" : "Create Entity"}</Typography>
      </InnerHeader>
      <Box className="pt74">
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item lg={2.5}>
                <Box className="attributesSec" sx={{ height: "100%" }}>
                  <Typography variant="h6">Attributes</Typography>
                  <AttributesToolbar />
                </Box>
              </Grid>
              <Grid item xs={6.5}>
                <Box className="entityInfo" sx={{ mt: 2 }}>
                  <Controller
                    name="entityName"
                    id="entityName"
                    control={methods.control}
                    defaultValue={defaultValues.entityName}
                    rules={{
                      required: "Entity name is required",
                      validate: {
                        uniqueEntityName: (value) => {
                          if (!entityId) {
                            return (
                              !entities.some((entity) => entity.entityName === value) || "Entity name already exists"
                            );
                          }

                          const entityExists = entities.some(
                            (entity) => entity._id !== entityId && entity.entityName === value,
                          );
                          if (entityExists) {
                            return "Entity name already exists";
                          }

                          return true;
                        },
                        noLeadingSpaces: (value) => !/^\s/.test(value) || "Cannot start with a space",
                        noOnlySpaces: (value) => !/^\s+$/.test(value) || "Cannot be only spaces",
                        noTrailingSpaces: (value) => !/\s$/.test(value) || "Cannot end with a space",
                        noSpecialChars: (value) =>
                          /^[A-Za-z][A-Za-z0-9_ \s]*[A-Za-z0-9]$/.test(value) ||
                          "Special characters not allowed except _ and spaces",
                        noLeadingDigits: (value) => !/^[0-9]/.test(value) || "Cannot start with a digit",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Entity Name"
                        size="small"
                        sx={{ mb: 1 }}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setValue("entityDisplayName", e.target.value);
                        }}
                        fullWidth
                        variant="outlined"
                        error={Boolean(methods?.formState?.errors?.entityName)}
                        helperText={methods?.formState?.errors?.entityName?.message}
                      />
                    )}
                  />
                  <Controller
                    name="entityDescription"
                    id="entityDescription"
                    control={methods.control}
                    defaultValue={defaultValues.entityDescription}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Entity Description"
                        variant="outlined"
                        multiline
                        rows={4}
                        error={Boolean(methods.formState.errors?.entityDescription)}
                        helperText={methods.formState.errors?.entityDescription?.message}
                        sx={{ mb: 1 }}
                        fullWidth
                      />
                    )}
                  />
                  <Grid container spacing={1}>
                    <Grid item lg={4}>
                      <FormControlLabel
                        sx={{ mb: 1, mx: 0 }}
                        name="hasForeignKey"
                        control={
                          <Switch
                            id="hasForeignKey"
                            name="hasForeignKey"
                            disabled={disableForeignRelations}
                            checked={foreignRelations}
                            onChange={() => {
                              setForeignRelations((prev) => !prev);
                              methods.trigger("entityRelations");
                            }}
                            size="small"
                          />
                        }
                        label={"Has Foreign Key"}
                      />
                    </Grid>
                    <Grid item lg={8}>
                      {foreignRelations && (
                        <Controller
                          name="entityRelations"
                          control={methods.control}
                          rules={{
                            required: "At least one foreign relation must be selected",
                          }}
                          render={({ field, fieldState }) => (
                            <Autocomplete
                              {...field}
                              options={entityInfo}
                              getOptionLabel={(option) => option.entityDisplayName}
                              isOptionEqualToValue={(option, value) => option?._id === value?._id}
                              size="small"
                              sx={{ mb: 1 }}
                              fullWidth
                              multiple
                              defaultValue={defaultValues.entityRelations}
                              disableCloseOnSelect
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  variant="outlined"
                                  label="Foreign Relations"
                                  error={fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                              onChange={(_, value, reason) => {
                                field.onChange(value);
                                if (reason === "selectOption") {
                                  handleAddForeignAttributes(value);
                                } else if (reason === "removeOption") {
                                  handleRemoveForeignAttributes(value);
                                } else if (reason === "clear") {
                                  const filteredAttributes = fields.filter((attr) => !attr.isForeignKey);
                                  replace(filteredAttributes);
                                }
                                methods.trigger();
                              }}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <DroppableArea
                  finalErrors={finalErrors}
                  control={methods.control}
                  watch={methods.watch}
                  appendAttribute={appendAttribute}
                  remove={remove}
                  fields={fields}
                />
                {!(attributes && attributes.length > 0) && (
                  <Alert sx={{ mt: 2 }} severity="warning">
                    Add at least one attribute
                  </Alert>
                )}
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  {!entityId && (
                    <Button
                      type="button"
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        handleReset();
                      }}
                      startIcon={<Clear sx={{ fontSize: "8px" }} />}>
                      Reset
                    </Button>
                  )}
                  <LoadingButton
                    type="submit"
                    size="small"
                    variant="contained"
                    className="loader-button"
                    startIcon={<Save sx={{ fontSize: "8px" }} />}
                    autoFocus
                    disabled={!(attributes && attributes?.length > 0) || loading}
                    loading={loading}
                    loadingPosition="start">
                    Save
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="attributesSec propertiesSec" sx={{ height: "100%", width: "100%" }}>
                  <Typography variant="h6">Properties</Typography>
                  {selectedAttributeIndex !== null ? (
                    <PropertiesContainer control={methods.control} watch={methods.watch} />
                  ) : (
                    <Alert sx={{ mb: 2 }} severity="info">
                      Select an attribute to view its properties
                    </Alert>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DndProvider>
      </Box>
    </FormProvider>
  );
};

export default CreateNewEntity;
