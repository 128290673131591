import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import DataSources from "../components/DataSources";
import License from "../components/License";
import DQGUsers from "../components/DQGUsers";
import UserLogsList from "../components/DQGUsers/UserLogsList";
import SignIn from "../components/Login";
import { Box } from "@mui/material";
import Layout from "../components/Layout";
import { useContext } from "react";
import { UserDetailsContext } from "../services/UserDetailsContext";
import SyntheticEntitySets from "../components/Synthetic/SyntheticEntitySets";
import SyntheticDatasets from "../components/Synthetic/SyntheticDatasets";
import CreateSyntheticEntity from "../components/Synthetic/CreateSyntheticEntity";
import CreateClonedEntity from "../components/Cloned/CreateClonedEntity";
import ClonedEntitySets from "../components/Cloned/ClonedEntitySets";
import ClonedDatasets from "../components/Cloned/ClonedDatasets";
import ViewDataset from "../components/ViewDataset";
import { userLocalSession } from "../_helpers/Constant";
import AllNotifications from "../components/Notifications";
import QueryEntityCreate from "../Pages/QueryEntity";
import Subscribe from "../components/Notifications/Subscribe";


const ROUTES = [
  { path: "/", element: <SyntheticEntitySets /> },
  { path: "license/:info", element: <License /> },
  { path: "users", element: <DQGUsers /> },
  { path: "users/userlogs/:id", element: <UserLogsList /> },
  { path: "synthetic/create", element: <CreateSyntheticEntity /> },
  { path: "synthetic/datasets", element: <SyntheticDatasets /> },
  { path: "cloned/entity-sets", element: <ClonedEntitySets /> },
  { path: "cloned/create", element: <CreateClonedEntity /> },
  { path: "cloned/datasets", element: <ClonedDatasets /> },
  { path: "cloned/datasources", element: <DataSources /> },
  { path: "/dataset/:id", element: <ViewDataset /> },
  { path: "/all-notifications", element: <AllNotifications /> },
  { path: "/query-entity-create", element: <QueryEntityCreate /> },
  { path: "subscribe", element: <Subscribe /> },

  // Add other routes here as needed
];
const SidebarLayout = () => {
  const { userDetails } = useContext(UserDetailsContext);
  const token = userDetails?.Token;
  if (!token) {
    return <Navigate to="/login" />;
  }
  return (
    <Box sx={{ display: "flex", marginTop: "52px" }}>
      <Layout />
      <Box className="outlet">
        <Outlet />
      </Box>
    </Box>
  );
};
const PrivateRoute = ({ children }) => {
  const userDetails = JSON.parse(localStorage.getItem(userLocalSession));
  return userDetails ? children : <Navigate to="/login" />;
};
const RouteManager = () => {
  return (
    <Routes>
      <Route path="/login" element={<SignIn />} />
      <Route element={<SidebarLayout />}>
        {ROUTES?.map((route) => (
          <Route key={route?.path} path={route?.path} element={<PrivateRoute>{route?.element}</PrivateRoute>} />
        ))}
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default RouteManager;
