import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import themeSettings from "./themeSettings";

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {
    console.log("Toggled color mode");
  },
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    [],
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
