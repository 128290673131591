import { Autocomplete, Box, Button, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SnackbarContext } from "../App";
import { generateDataset } from "../app/features/datasetsSlice";
import { fetchAllEntitiesByIds } from "../app/features/entitySlice";

const GenerateDrawer = ({ entity = {}, allEntities = [], onClose, setSelectedEntity }) => {
  const dispatch = useDispatch();
  const { setSnack } = useContext(SnackbarContext);
  const { handleSubmit, trigger, control, watch, getValues } = useForm({
    defaultValues: {
      selectedEntity: entity && Object.keys(entity).length > 0 ? entity : null,
    },
  });

  const navigate = useNavigate();

  // Variable Initializations
  const selectedEntity = watch("selectedEntity");
  const [relatedEntityInfo, setRelatedEntityInfo] = useState([]);

  useEffect(() => {
    trigger();
  }, [trigger, getValues]);

  useEffect(() => {
    if (selectedEntity?.entityType === "custom") {
      if (selectedEntity.entityRelations?.length > 0) {
        // const initialSelectionStatus = selectedEntity.entityRelations.reduce((acc, entityId) => {
        //   acc[entityId] = false; // Initially, no datasets are selected for any related entities
        //   return acc;
        // }, {});
        // setEntityDatasetStatus(initialSelectionStatus);
        dispatch(
          fetchAllEntitiesByIds({
            ids: [...selectedEntity.entityRelations],
            type: selectedEntity.entityType,
          }),
        )
          .then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
              console.log("Datasets ", res.payload);
              setRelatedEntityInfo(res.payload);
            } else {
              if (res.meta.requestStatus === "rejected") {
                setSnack({
                  message: res.payload,
                  open: true,
                  color: "error",
                  severity: "error",
                });
              }
            }
          })
          .catch((err) => {
            setSnack({
              message: err.message,
              open: true,
              color: "error",
              severity: "error",
            });
          });
      } else {
        setRelatedEntityInfo([]);
      }
    }
  }, [selectedEntity, dispatch, setSnack]);

  useEffect(() => {
    if (selectedEntity?.entityRelations?.length > 0) {
      dispatch(
        fetchAllEntitiesByIds({
          entityNames: [...selectedEntity.entityRelations],
          type: selectedEntity.entityType,
        }),
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          setRelatedEntityInfo(res.payload);
        }
      });
    }
  }, []);

  const handleGenerateDataset = (data) => {
    try {
      let entities;
      let finalPayload;

      if (entity?.entityType === "cloned") {
        entities = data?.selectedEntity?.selectedTables?.map((table) => {
          const countKey = `Count-${table._id}`;
          const count = data[countKey];
          const dataSetName = `${data?.datasetName} ${table?._id}`;
          return {
            ...table,
            count: parseInt(count, 10),
            datasetName: dataSetName.replace(/\s+/g, ""),
            referenceName: dataSetName.replace(/\s+/g, ""),
            entitySetId: data?.selectedEntity?._id,
            entityRelations: table?.entityRelations,
          };
        });

        finalPayload = {
          entitySetId: data?.selectedEntity?._id,
          datasetName: data?.datasetName,
          entityType: entity?.entityType,
          entityDisplayName: entity?.entityDisplayName,
          entities: [...entities],
          isCopied: data?.isCopied ? data.isCopied : false,
          connectionId: selectedEntity?.connectionId,
        };
      } else {
        const selectedTables = [data?.selectedEntity, ...relatedEntityInfo] || [data?.selectedEntity];
        entities = selectedTables.map((table) => {
          const countKey = `Count-${table._id}`;
          const count = data[countKey];
          const dataSetName = `${data?.datasetName} ${table?._id}`;
          return {
            ...table,
            count: parseInt(count, 10),
            datasetName: dataSetName.replace(/\s+/g, ""),
            referenceName: dataSetName.replace(/\s+/g, ""),
            entitySetId: table?._id,
            entityRelations: table?.entityRelations,
          };
        });
        finalPayload = {
          entitySetId: data?.selectedEntity?._id,
          datasetName: data?.datasetName,
          entityType: data?.selectedEntity?.entityType,
          entityDisplayName: data?.selectedEntity?.entityDisplayName,
          entities: [...entities],
          isCopied: data?.isCopied ? data.isCopied : false,
        };
      }

      dispatch(generateDataset(finalPayload))
        .then((result) => {
          console.log(result, 230);
          if (result?.meta?.requestStatus === "rejected") {
            setSnack({
              message: result.payload.response.data.message || "Oops!! There was a glitch. Please try in a while.",
              open: true,
              colour: "error",
            });
          } else {
            setSnack({
              message: "Dataset initiated successfully",
              open: true,
              colour: "success",
            });
            navigate(
              `/${entity?.entityType === "cloned" ? "cloned" : "synthetic"}/datasets?dataSetType=${
                entity?.entityType || result?.payload?.type
              }`,
            );
          }
        })
        .catch((error) => {
          setSnack({
            message: `Could not create dataset - ${error.message}`,
            open: true,
            colour: "error",
          });
        });
    } catch (error) {
      setSnack({
        message: error.message,
        open: true,
        colour: "error",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "relative",
      }}>
      <Box className="drawerHead">
        <Typography variant="h6">Generate Synthetic Data:</Typography>
      </Box>
      <Box sx={{ "& .MuiFormControl-root": { "& p": { m: 0 } } }} component={"form"} noValidate>
        <Controller
          name="selectedEntity"
          control={control}
          rules={{ required: true }}
          defaultValue={entity && Object.keys(entity)?.length > 0}
          render={({ field, fieldState }) => (
            <Autocomplete
              sx={{ mb: 1 }}
              fullWidth
              {...field}
              getOptionLabel={(option) => option?.entityDisplayName}
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              getOptionDisabled={() => allEntities?.length === 0}
              options={allEntities}
              onChange={(_, value) => {
                setSelectedEntity(value);
                field.onChange(value);
                trigger();
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Select Entity"
                  error={Boolean(fieldState?.error)}
                  // helperText={fieldState.error?.message}
                  sx={{ mb: 1 }}
                  fullWidth
                  size="small"
                />
              )}
            />
          )}
        />
        {selectedEntity?.entityType === "cloned" ? (
          <>
            <Controller
              name={"datasetName"}
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 4,
                  message: "Minimum length should be 4",
                },
                validate: {
                  noLeadingSpaces: (value) => !/^\s/.test(value) || "Cannot start with a space",
                  noOnlySpaces: (value) => !/^\s+$/.test(value) || "Cannot be only spaces",
                  noTrailingSpaces: (value) => !/\s$/.test(value) || "Cannot end with a space",
                  noSpecialChars: (value) => /^[a-zA-Z0-9\s]+$/.test(value) || "No special characters allowed",
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Dataset Name"
                  error={Boolean(fieldState?.error)}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger();
                  }}
                  helperText={fieldState.error?.message}
                  required
                  sx={{ mb: 2 }}
                  fullWidth
                  size="small"
                />
              )}
            />
            {selectedEntity?.selectedTables?.map((entityInfo, entityIndex) => (
              <Controller
                key={entityIndex}
                name={`Count-${entityInfo?._id}`}
                control={control}
                rules={{
                  required: "Count is required",
                  min: {
                    value: 1,
                    message: "Count should be greater than 0",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    {...field}
                    label={`No of records for ${entityInfo?.TableName || entityInfo?.entityName}`}
                    type="number"
                    error={Boolean(fieldState?.error)}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger();
                    }}
                    // helperText={fieldState.error?.message}
                    sx={{ mb: 2 }}
                    fullWidth
                    size="small"
                  />
                )}
              />
            ))}
            <Controller
              name="isCopied"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ mb: 1, mx: 0 }}
                  control={
                    <Switch
                      id="copiedData"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        trigger().then();
                      }}
                      size="small"
                    />
                  }
                  label={"Copied Data"}
                />
              )}
            />
          </>
        ) : (
          <>
            <Controller
              name="datasetName"
              control={control}
              rules={{
                required: true,
                minLength: {
                  value: 4,
                  message: "Minimum length should be 4",
                },
                validate: {
                  noLeadingSpaces: (value) => !/^\s/.test(value) || "Cannot start with a space",
                  noOnlySpaces: (value) => !/^\s+$/.test(value) || "Cannot be only spaces",
                  noTrailingSpaces: (value) => !/\s$/.test(value) || "Cannot end with a space",
                  noSpecialChars: (value) => /^[a-zA-Z0-9\s]+$/.test(value) || "No special characters allowed",
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  {...field}
                  label="Dataset Name"
                  error={Boolean(fieldState?.error)}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger();
                  }}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 1 }}
                  fullWidth
                  size="small"
                />
              )}
            />
            <Controller
              name={`Count-${selectedEntity?._id}`}
              control={control}
              defaultValue={1}
              rules={{
                required: "Count is required",
                min: { value: 1, message: "Count should be greater than 0" },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  {...field}
                  label={`No of records for ${selectedEntity?.entityName}`}
                  type="number"
                  error={Boolean(fieldState?.error)}
                  onChange={(e) => {
                    const value = e.target.value.trim().replace(/^0+/, "");
                    field.onChange(parseInt(value, 10));
                    trigger();
                  }}
                  InputProps={{ inputProps: { min: "1", step: "1" } }}
                  helperText={fieldState.error?.message}
                  sx={{ mb: 1 }}
                  fullWidth
                  size="small"
                />
              )}
            />
            {relatedEntityInfo?.map((entityInfo) => (
              <Controller
                key={`Count-${entityInfo?._id}`}
                name={`Count-${entityInfo?._id}`}
                control={control}
                defaultValue={1}
                rules={{
                  required: "Count is required",
                  min: {
                    value: 1,
                    message: "Count should be greater than 0",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={`No of records for ${entityInfo?.TableName || entityInfo?.entityName}`}
                    type="number"
                    error={Boolean(fieldState?.error)}
                    onChange={(e) => {
                      const value = e.target.value.trim().replace(/^0+/, "");
                      field.onChange(parseInt(value, 10));
                      trigger();
                    }}
                    InputProps={{ inputProps: { min: "1", step: "1" } }}
                    helperText={fieldState.error?.message}
                    size="small"
                    sx={{ mb: 1 }}
                    fullWidth
                  />
                )}
              />
            ))}
          </>
        )}
      </Box>
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          justifyContent: "space-between", // Match the drawer's background color
        }}>
        {/* Add your buttons here */}
        <Button variant="outlined" color="error" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          className="customBtn"
          // disabled={!canSubmit}
          onClick={handleSubmit(handleGenerateDataset)}>
          Generate
        </Button>
        {/* More buttons if needed */}
      </Box>
    </Box>
  );
};

export default GenerateDrawer;
