import { useForm } from "react-hook-form";
import FormTextField from './FormTextField';
import { Box, Button } from "@mui/material";
import { useEffect } from "react";

export default function QueryEntityForm({ fields, defaultValues, columns, onSubmit, onChageFormValues, handleFieldChange }) {
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues
    });
    const formValues = watch();

    useEffect(() => {
        onChageFormValues(formValues);
    }, [formValues]);



    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            {(fields || []).map((field) => (
                <Box key={field.name} mb={2}>
                    <FormTextField
                        name={field.name}
                        control={control}
                        label={field.label}
                        required
                        errors={errors}
                        rules={field.rules}
                        options={field.options}
                        type={field.type}
                        onChange={(event) => { handleFieldChange(event.target.name, event.target.value, formValues); }}
                    />
                </Box>
            ))}
            {console.log(columns, 40)}

            <Button type="submit" variant="outlined" align="right" color="error" size="small">Submit</Button>
        </Box>
    );
}
