import { AnalyticsOutlined as clonedIcon, BackupTableOutlined as syntheticIcon } from "@mui/icons-material";
import { Box, Divider, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import whiteLogo from "../../images/dq-gateway-logo.png";
// import darkLogo from "../../images/dq-gateway-logo-dark.png";
import Logo2 from "../../images/DQG - Logo-small.svg";
import AppMenuItem from "../AppMenuItem";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";

export default function SideMenu({ openSideMenu }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const routes = [
    {
      text: t("synthetic"),
      icon: syntheticIcon,
      items: [
        {
          text: t("entity_sets"),
          path: "/",
          icon: RadioButtonCheckedIcon,
        },
        {
          text: t("datasets"),
          path: "/synthetic/datasets?dataSetType=custom",
          icon: RadioButtonCheckedIcon,
        },
      ],
    },
    {
      text: t("cloned"),
      icon: clonedIcon,
      items: [
        {
          text: t("data_sources"),
          path: "/cloned/datasources",
          icon: RadioButtonCheckedIcon,
        },
        {
          text: t("entity_sets"),
          path: "/cloned/entity-sets",
          icon: RadioButtonCheckedIcon,
        },
        {
          text: t("datasets"),
          path: "/cloned/datasets?dataSetType=cloned",
          icon: RadioButtonCheckedIcon,
        },
        // {
        //   text: t("Query Entity"),
        //   path: "/query-entity-create",
        //   icon: RadioButtonCheckedIcon,
        // },


      ],
    },
    {
      text: t("Users"),
      icon: PeopleAltOutlinedIcon,
      path: "/users",
    },
    {
      text: t("Settings"),
      icon: clonedIcon,
      items: [
        {
          text: t("Notifications"),
          path: "/all-notifications",
          icon: RadioButtonCheckedIcon,
        }]
    }

  ];

  const Logo1 = theme?.palette?.mode === "dark" ? whiteLogo : whiteLogo;

  return (
    <Box>
      <Box className="center">
        {openSideMenu ? (
          <img src={Logo1} height="50px" alt="logo-notavailable" style={{ padding: "5px" }} />
        ) : (
          <img src={Logo2} height="50px" alt="logo-notavailable" />
        )}
      </Box>
      <Divider sx={{ my: 1 }} />
      {routes.map((route, index) => (
        <AppMenuItem {...route} key={index} />
      ))}
    </Box>
  );
}
