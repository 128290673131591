// color design tokens export
export const tokens = (mode) => {
  const isDarkMode = mode === "dark";
  return {
    white: isDarkMode ? "#292f41" : "#F9F9FF",
    done: isDarkMode ? "#4840C5" : "#4840C5",
    success: isDarkMode ? "#00C163" : "#00C163",
    orange: isDarkMode ? "#E68200" : "#E68200",
    error: isDarkMode ? "#F9594B" : "#F9594B",
    heading: isDarkMode ? "#fff" : "#2C2C57",
    gridheader: isDarkMode ? "#1d243c" : "#f5f7f7",
    strip: isDarkMode ? "#292f41" : "#DCD4FB",
    bodystrip: isDarkMode ? "#292f41" : "#F2F1F1",
    border: isDarkMode ? "#545967" : "#D2CDCD",
    darktext: isDarkMode ? "#F9F9FF" : "#333333",
    cardbg: isDarkMode ? "#1d243c" : "#F4F1FF",

    gray: {
      100: isDarkMode ? "#eef6ff" : "#F9FAFB",
      200: isDarkMode ? "#FFFFF" : "#E9ECEF",
      300: isDarkMode ? "#7f828d" : "#DDE0E5",
      400: isDarkMode ? "#545967" : "#CED4DA",
      500: isDarkMode ? "#292f41" : "#ADB5BD",
      600: isDarkMode ? "#212634" : "#6C757D",
      700: isDarkMode ? "#64748B" : "#495057",
      800: isDarkMode ? "#343A40" : "#343A40",
      900: isDarkMode ? "#08090d" : "#212529",
    },

    primary: {
      100: isDarkMode ? "#d2d3d8" : "#06070c",
      200: isDarkMode ? "#a5a7b1" : "#0c0e18",
      300: isDarkMode ? "#777c8a" : "#111624",
      400: isDarkMode ? "#4a5063" : "#171d30",
      500: isDarkMode ? "#1d243c" : "#F4F1FF",
      600: isDarkMode ? "#171d30" : "#4a5063",
      700: isDarkMode ? "#111624" : "#777c8a",
      800: isDarkMode ? "#0c0e18" : "#a5a7b1",
      900: isDarkMode ? "#06070c" : "#5F64E526",
    },
  };
};
