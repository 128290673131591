import axios from "axios";
import authService from "./auth.service";
import { Decryption } from "../_helpers/Encryption";
import appService from "./app.service";
import { userLocalSession, ProductCode } from "../_helpers/Constant";
export const interceptor = async (userData, setUserDetails, navigate, setSnack) => {
  if (!userData) {
    return;
  }
  const { Token, UserId, SessionId } = userData;
  let isLogoutInProgress = false;
  axios.create();
  axios.defaults.headers.common.Consumer = "DQG-Web";
  if (Token && axios.defaults.headers.common) {
    axios.defaults.headers.common.Authorization = `Bearer ${Token}`;
  }
  const handleRequestSuccess = (config) => {
    return config;
  };

  const handleRequestError = (error) => {
    return Promise.reject(error);
  };

  const handleResponseSuccess = (response) => {
    return response;
  };

  const handleResponseError = async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && error?.response?.data?.error === "jwtExpired" && !isLogoutInProgress) {
      isLogoutInProgress = true;
      originalRequest._retry = true;

      const tokenData = { token: Token, ProductCode };
      const refreshTokenRes = await appService.RefreshToken(tokenData);
      const newToken = refreshTokenRes?.data?.ResponseObject?.Token;
      originalRequest.headers.Authorization = `Bearer ${newToken}`;

      const newData = {
        ...userData,
        Token: newToken,
      };

      localStorage.setItem(userLocalSession, JSON.stringify(newData));
      // 2) Change Authorization header
      axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;
      // 3) return originalRequest object with Axios.
      return axios(originalRequest);
    } else if (error?.response?.status === 401 && !isLogoutInProgress) {
      isLogoutInProgress = true;
      const data = {
        UserId: UserId,
        SessionId: SessionId,
      };

      const logoutPromise = authService.logout(data);
      localStorage.removeItem(userLocalSession);
      setUserDetails({});
      navigate("/login");
      logoutPromise.catch((error) => {
        console.log("Error during logout:", error);
      });
    } else if (error?.response?.status === 402 && !isLogoutInProgress) {
      isLogoutInProgress = true;
      try {
        const dc = await Decryption(error.response.data);
        navigate(`/license/${dc?.ExpiryType}`);
      } catch (e) {
        setSnack({
          message: e?.response?.data?.message ?? e.message,
          colour: "error",
          open: true,
        });
      }
    }
    // else {
    //   setSnack({
    //     message: error?.response?.data?.message ?? error.message,
    //     colour: "error",
    //     open: true,
    //   });
    // }

    return Promise.reject(error);
  };

  // Register the interceptors before any API calls are made
  axios.interceptors.request.use(handleRequestSuccess, handleRequestError);
  axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
};
