import authHeader from "./auth-header";
import { AuthBaseURLENV, KeyManagerURLENV, EngineURLENV, NotificationBaseURL } from "../_helpers/Constant";
import { Decryption, Encryption } from "../_helpers/Encryption";

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_BASE_URL;
axios.defaults.headers.common.Authorization = authHeader().Authorization;

export class ApiService {
  async fetchDQGUsers(searchString) {
    const enData = await Encryption(JSON.stringify(searchString));
    const usersRes = await axios.post(`${AuthBaseURLENV}/api/ADFS/SearchADUsers`, enData);
    const deData = await Decryption(usersRes?.data);
    return deData?.users;
  }
  async getUserRoles() {
    const rolesRes = await axios.get(`${AuthBaseURLENV}/api/Role`);
    return await Decryption(rolesRes?.data);
  }
  async fetchExistingDQGUsers(userDetails) {
    const enData = await Encryption(JSON.stringify(userDetails));
    const usersRes = await axios.post(`${AuthBaseURLENV}/api/User/v1/AllUsers`, enData);
    const deData = await Decryption(usersRes?.data);
    return deData?.ResponseObject;
  }
  async CreateDQGUser(data) {
    const enData = await Encryption(JSON.stringify(data));
    const res = await axios.post(`${AuthBaseURLENV}/api/User/Create`, enData);
    return await Decryption(res?.data);
  }
  getDQGUserDetails(id) {
    return axios.get(`${AuthBaseURLENV}/api/User/v1/${id}`);
  }
  async deleteDQGUser(data) {
    const res = await axios.post(`${AuthBaseURLENV}/api/User/v1/DeleteUser`, data);
    return await Decryption(res?.data);
  }
  async UpdateDQGUser(data) {
    const enData = await Encryption(JSON.stringify(data));
    const res = await axios.put(`${AuthBaseURLENV}/api/User/Update`, enData);
    return await Decryption(res?.data);
  }
  async getDQGUserLogs(data) {
    const enData = await Encryption(JSON.stringify(data));
    const userLogsRes = await axios.post(`${AuthBaseURLENV}/api/UserActivity`, enData);
    return Decryption(userLogsRes?.data);
  }

  RefreshToken(data) {
    return axios.post(`${AuthBaseURLENV}/api/Login/V1/RefreshToken`, data);
  }
  getDecryptionkey(data) {
    return axios.post(KeyManagerURLENV, data);
  }

  // Entity
  createEntity(data) {
    return axios.post("/entities/create", data);
  }

  updateEntity(data) {
    return axios.post("/entities/update", data);
  }
  getEntity() {
    return axios.get("/entities/get");
  }

  getEntityById(entityId) {
    return axios.get(`/entities/get/${entityId}`);
  }
  getEntitiesData() {
    return axios.get("/entities/get/list");
  }
  deleteEntity(data) {
    return axios.post("/entities/delete", data);
  }
  getEntityResult(data) {
    return axios.post("/entities/get/results", data);
  }
  checkEntity(data) {
    return axios.post("/entities/check/entity", data);
  }
  generateSyntheticData(data) {
    return axios.post("/entities/create/syntheticdata", data);
  }
  getDataSets(data) {
    return axios.post("/entities/get/datasets", data);
  }
  getDatasetData(data) {
    return axios.post("/entities/get/getDatasetData", data);
  }

  getAllEntitiesById(data) {
    return axios.post("/entities/get-entities-by-id", data);
  }

  getAllDatasetsByIds(data) {
    return axios.post("/entities/get-datasets-by-id", data);
  }
  getDropDownData(data) {
    return axios.post("/entities/get/propertygenerator/", data);
  }

  // Environments
  getEnivronments() {
    return axios.get("environments/types");
  }
  createEnvironment(data) {
    return axios.post("environments/create", data);
  }
  getEnivronmentsList(data) {
    return axios.post("environments/all", data);
  }
  checkEnvironmentName(data) {
    return axios.post("environments/check-unique-name", data);
  }
  updateEnvironment(id, data) {
    return axios.put(`environments/update/${id}`, data);
  }
  deleteEnvironment(data) {
    return axios.post("/environments/delete", { ids: data });
  }

  // Data Sources
  createDataSource(data) {
    return axios.post("data-source/create/", data);
  }

  editDataSource(data, id) {
    return axios.put(`data-source/update/${id}`, data);
  }

  getDataSources(data) {
    return axios.post("data-source/all", data);
  }
  dataSources(data) {
    return axios.post("data-source/list", data);
  }
  GetSchemaList(data) {
    return axios.post("/data-source/schema-list/", data);
  }
  getDataSourceByType(data) {
    return axios.post("/data-source/connections", data);
  }

  testDataSource(data) {
    return axios.post("data-source/test", data);
  }

  deleteDataSource(data) {
    return axios.post("data-source/delete", { ids: data });
  }

  getConnections() {
    return axios.post("data-source/connections");
  }

  getConnectionsById(data) {
    return axios.post("data-source/connection-data", { id: data });
  }

  getConnectionData(data) {
    return axios.post("data-source/connection-tables-data", data);
  }

  ConnectionsList(data) {
    return axios.post("/connections/", data);
  }
  CheckConnectionUnique(data) {
    return axios.post("/connections/check-by-cname", data);
  }
  ConnectionCreate(data) {
    return axios.post("/connections/create", data);
  }
  ConnectionUpdate(data, id) {
    return axios.put(`/connections/${id}`, data);
  }
  ConnectionDelete(data) {
    return axios.post("/connections/delete", data);
  }
  ConnectionDetails(id, data = "") {
    return axios.get(`/connections/tables-by-connection/${id}?data=${data}`, {});
  }
  ConnectionDetailsDataValidation(data) {
    return axios.post("/connections/get-connection-details-datavalidation", data);
  }
  addMapping(data) {
    return axios.post("etl/execute", data);
  }

  EtlPipeline() {
    return axios.post("etl/executions");
  }
  SyntheticDataset(data) {
    return axios.post("entities/synthetic-data/view-dataset", data);
  }
  DatasetList(data) {
    return axios.post("datasets/all", data);
  }
  DatasetDelete(data) {
    return axios.post("datasets/delete", data);
  }
  TableAttributes(data) {
    return axios.post("data-source/connection/table-attributes", data);
  }
  TableListByConnection(id) {
    return axios.get(`data-source/connection/tables-list/${id}`);
  }
  NotificationMarkAsRead(data) {
    return axios.put(`${NotificationBaseURL}/Notification`, data);
  }
  NotificationSubscriptionJson() {
    return axios.get(`${NotificationBaseURL}/Notification/SubscriptionStatus`);
  }
  NotificationSubscriptionUpdate(data) {
    return axios.post(`${NotificationBaseURL}/Notification/Toggle`, data);
  }


  async getNotifications(data) {
    const resust = await axios.post(`${NotificationBaseURL}/Notification/fetch`, {
      pageNumber: data?.pageNumber || 1,
      pageSize: data?.pageSize || 10,
    });
    return resust.data;
  }
  DQGServerHealthcheck() {
    return axios.get(`/healthcheck`);
  }
  DQGEngineHealthcheck() {
    return axios.get(`${EngineURLENV}/api/HealthCheck`);
  }
}

export default new ApiService();
