import { tokens } from "./tokens";
import InnerHeader from "../images/InnerHeader_Bg.png";

const themeSettings = (mode) => {
  const colors = tokens(mode);
  const { gray, primary, white, success, heading, strip, border, darktext, gridheader, cardbg } = colors;
  // Table UI
  const getMuiTableStyles = () => ({
    "& table.MuiTable-root": {
      border: "1px solid rgb(189 195 199 / 50%)",
      "& tr": {
        "& th": {
          padding: "4px 10px",
          borderLeft: "1px solid rgb(189 195 199 / 50%)",
          backgroundColor: "#7A86A1",
          color: white,
        },
        "& td": {
          padding: "4px 10px",
          borderLeft: "1px solid rgb(189 195 199 / 50%)",
          "& .MuiTablePaginationUnstyled-root": {
            border: "0px",
          },
          "& .MuiIconButton-root": {
            padding: "3px",
            fontSize: "1rem",
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "20px",
        },
      },
      "& tr.active": {
        backgroundColor: "#e5f6fd",
      },
    },
  });

  const getHeaderStyles = () => ({
    "& .headerCus": {
      width: "100%",
      borderRadius: "0 !important", // Set border-radius to 0 with !important
      boxShadow: "none !important", // Remove box-shadow with !important
      "& .MuiToolbar-root": {
        minHeight: "52px",
        color: "#096eb6",
      },
      "& .MuiButtonBase-root": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      "& .MuiTypography-h5": {
        background: "linear-gradient(to right, #2c65f4, #e73323)",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
      "& .headerIcons": {
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "1rem",
      },
    },
    "& .notItems": {
      "& .MuiMenu-paper": {
        // overflow: "auto",
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        marginTop: "12px",
        width: "460px",
        maxWidth: "460px",
        padding: "8px 16px",
        "& .MuiAvatar-root": {
          width: "32px",
          height: "32px",
          marginRight: "8px",
          fontSize: "16px",
        },
        "&:before": {
          // content: '" "',
          display: "block",
          position: "absolute",
          top: "0",
          right: "14px",
          width: "10px",
          height: "10px",
          backgroundColor: white,
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
        "& ul": {
          "& li": {
            padding: "8px 0px",
            whiteSpace: "normal",
            "& p": {
              paddingRight: "35px",
              "& span": {
                color: "#ccc",
                fontWeight: "200",
                fontSize: "10px",
              },
            },
          },
        },
        "& .MuiTabs-root": {
          minHeight: "30px",
        },
        "& .MuiTabs-flexContainer": {
          borderBottom: "2px solid #ddd",
          "& .MuiTab-root": {
            padding: "0px 16px 0px 0px",
            minWidth: "auto",
            minHeight: "30px",
          },
        },
        "& .listBox": {
          paddingTop: "16px",
          "& .timeNot": {
            color: "#ccc",
            textTransform: "uppercase",
          },
        },
      },
      "& .notOnline": {
        position: "absolute",
        right: "8px",
        "& .MuiCheckbox-root": {
          padding: "5px",
          "& .MuiSvgIcon-root": {
            fontSize: "10px",
          },
          "& .MuiSvgIcon-root[data-testid='RadioButtonUncheckedIcon']": {
            color: white,
          },
        },
      },
      "&.statusItems": {
        "& .MuiPaper-root": {
          width: "auto",
          "& ul": {
            "& li": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              padding: "8px",
              marginBottom: "2px",
            },
          },
        },
        "& .webSerStatus": {
          paddingTop: "8px",
          "& .MuiAvatar-root": {
            width: "15px",
            height: "15px",
          },
          "& svg": {
            fontSize: "6px",
          },

          "& .statusOnline": {
            backgroundColor: "rgb(0 128 0 / 19%)",
            "& .MuiAvatar-root": {
              backgroundColor: "green",
            },
            "& span": {
              color: "green",
            },
          },
        },
      },
    },
  });

  const getSidemenuStyles = () => ({
    "& .sidemenuCus": {
      borderRight: 0,
      zIndex: 2,
      "& .MuiDrawer-paper": {
        zIndex: 2,
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
      },

      "& a": {
        color: darktext,
        "& span.MuiTypography-root": {
          fontWeight: "300",
        },
      },
      "& .MuiListItem-button": {
        "& .MuiListItemIcon-root": {
          minWidth: "46px",
        },
      },

      "& a.active": {
        color: "#F9F9FF",
        "& .css-1gjdz3h-MuiTypography-root": {
          fontSize: "14px",
        },
        "& .MuiListItem-button": {
          background: "transparent linear-gradient(91deg,  #611EB6 25%, #66A2EE 100%)",
        },
        "& svg": {
          color: "#F9F9FF",
        },
      },
      // "& hr": {
      //   borderColor: "rgb(255 255 255 / 12%)",
      // },
    },
  });
  const executionsStatus = () => ({
    "& .bg-color": {
      backgroundColor: white,
      boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
      // padding: "0px 16px 16px",
      borderRadius: "8px",
    },
    "& .exePop": {
      // padding: "0px 16px 16px",
      cursor: "move",
      position: "fixed",
      right: "4px",
      top: "150px",
      zIndex: 3,
    },
    "& .exepopTabs": {
      boxSizing: "border-box",
      minHeight: "26px",
      padding: "0px",
      width: "400px",

      "& .MuiTabs-flexContainer": {
        display: "block",
      },
      "& button": {
        minHeight: "24px",
        padding: "0px",
        marginRight: "1.5rem",
        alignItems: "start",
        minWidth: "auto",
      },
      "& span": {
        bottom: "auto",
      },
    },
    "& .exeListPop": {
      minHeight: "50px",
      maxHeight: "calc(100vh - 280px)",
      overflowY: "auto",
      // width: "400px",
      paddingBottom: "0.5rem",
    },
    "& .exList": {
      overflow: "visible",
      marginTop: "8px",
      padding: "8px",
      "& .MuiAvatar-root": {
        width: "32px",
        height: "32px",
        marginLeft: "-4px",
        marginRight: "8px",
      },

      "& .MuiLinearProgress-root": {
        height: "14px",
        borderRadius: "8px",
        "& .MuiLinearProgress-bar": {
          // background: "rgb(25,118,210)",
          background: "linear-gradient(0deg, rgba(25,118,210,1) 0%, rgba(97,173,250,1) 100%)",
        },
      },
      "& .percentage": {
        position: "absolute",
        top: "-1px",
        width: "100%",
        textAlign: "center",
        color: "#fff",
      },
      "&.completed": {
        "& .MuiLinearProgress-root": {
          "& .MuiLinearProgress-bar": {
            background: "linear-gradient(0deg, #128300 0%, #20c905 100%)",
          },
        },
      },
      "&.aborted": {
        "& .MuiLinearProgress-root": {
          background: "rgb(255 215 178)",
          "& .MuiLinearProgress-bar": {
            background: "linear-gradient(0deg, #ee720d 0%, rgb(255 178 87) 100%)",
          },
        },
      },
    },
    "& .exeHeaderIcon": {
      "& .MuiIconButton-root": {
        backgroundColor: "#fff",
        boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
        "& span.MuiBox-root": {
          position: "absolute",
          top: 0,
          backgroundColor: "#e89d45",
          color: "#fff",
          height: "20px",
          width: "20px",
          fontSize: "14px",
          lineHeight: "20px",
          borderRadius: "50%",
          right: 0,
        },
      },
    },
    "& .exeHeaderIconRotate": {
      "& .MuiIconButton-root": {
        svg: {
          animation: "cog 3s infinite",
          animationTimingFunction: "linear",
        },
        "@keyframes cog": {
          "100%": {
            transform: "rotate(360deg)",
          },
        },
      },
    },
  });
  const getdataSourcesStyles = () => ({
    "& .dataSourceInnerHead": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& .searchInput": {
        marginLeft: 1,
        flex: 1,
        fontSize: "0.75rem",
        padding: "0px",
        "& input": {
          padding: "4.5px 8px",
        },
      },
      "& .dataprofileprogress": {
        cursor: "pointer",
        background: "white",
        borderRadius: "24px",
        width: "140px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "34px",
        padding: "0 12px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
    "& .DisabledDSGridItem": {
      cursor: "not-allowed",
      opacity: 0.6,
      backdropFilter: "none",
      "&:hover": {
        border: "1px solid #e0e0e0",
        boxShadow: "none",
        transform: "none",
        cursor: "not-allowed",
      },
    },
    "& .DSGridItem": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      gap: 2,
      flexShrink: 0,
      height: "140px",
      padding: "16px",
      borderRadius: "6px",
      fontSize: "0.875rem",
      border: "1px solid #FFF",
      transition: "transform 0.5s ease , box-shadow 0.5s ease",
      cursor: "pointer",
      background: "rgba(255, 255, 255, 0.75)",
      boxShadow: " 0px 12px 16px -4px rgba(12, 26, 36, 0.04)",
      backdropFilter: "blur(13.600000381469727px)",
      "&:hover": {
        boxShadow: "0 0.5em 0.5em -0.4em #096eb6",
        transform: "translateY(-0.25em)",
      },
      "& .DBAdd": {
        padding: "8px",
        borderRadius: "2px",
        background: "linear-gradient(90deg, #4840C5 0%, #6334C6 100%)",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        height: "20px",
        width: "20px",
        color: white,
        "&:hover": {
          backgroundColor: "#1565c0",
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
      },
      "& img": {
        display: "inline-flex",
        padding: "4px",
        width: "55px",
      },

      "& .center": {
        "& p:first-of-type": {
          color: gray[700],
        },
        "& p:nth-of-type(2)": {
          color: gray[700],
        },
      },
    },
    "& .DSListItem": {
      "& thead": {
        height: "48px",
        flexShrink: 0,
        borderRadius: "12px 12px 0px 0px",
        "& tr": {
          height: "48px",
          flexShrink: 0,
          borderRadius: "12px 12px 0px 0px",
          "& th": {
            padding: "4px 16px",
            height: "48px",
            background: strip,
            color: gray[700],
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0.28px",
            textTransform: "uppercase",
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            padding: "4px 16px",
            color: heading,
            fontWeight: 700,
            height: "45px",
          },
        },
      },
      "& .DBAdd": {
        padding: "0.5rem",
        borderRadius: "2px",
        background: "linear-gradient(90deg, #4840C5 0%, #6334C6 100%)",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
        height: "20px",
        width: "20px",
        color: white,
        "&:hover": {
          backgroundColor: "#1565c0",
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
      },
    },

    "& .gridViewConList": {
      borderRadius: "6px",
      border: "1px solid #FFF",
      background: "rgba(255, 255, 255, 0.75)",
      boxShadow: "0px 12px 16px -4px rgba(12, 26, 36, 0.04)",
      backdropFilter: "blur(13.600000381469727px)",
    },

    "& .previewConnection": {
      "& .MuiPaper-rounded": {
        borderRadius: "10px 10px 0px 0px",
        background: white,
      },
      "& .previewConTitleContainer": {
        borderRadius: "10px 10px 0px 0px",
        background: strip,
        padding: "10px 12px",

        "& .prevConCloseIcon": {
          position: "absolute",
          top: 0,
          right: 0,
        },
        "& .titleItem": {
          "& p:first-of-type": {
            color: gray[700],
            fontWeight: 600,
          },
          "& p:nth-of-type(2)": {
            color: gray[700],
            fontWeight: 400,
          },
        },
      },

      "& .previewConContent": {
        minHeight: "250px",
        paddingLeft: "12px",
        paddingRight: "12px",
        "& .prevConTablesContainer": {
          borderRadius: "4px",
          border: `1px solid ${border}`,
          background: white,
          boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.04)",
          "& .prevConTablesTitle": {
            borderradius: "4px 4px 0px 0px",
            background: strip,
            boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.25)",
            height: "46px",
            flexShrink: 0,
            padding: "0.5rem",
          },

          "& .prevConSearchInput": {
            borderRadius: "14px",
            border: "0.5px solid #6C6C6C",
            background: primary[500],
            width: "100%",
            paddingLeft: "8px",
            paddingRight: "8px",
            color: " #4F4F4F",
            fontWeight: 500,
            "& input": {
              padding: "3px 0px",
            },
          },

          "& .prevConTableList": {
            height: "460px",
            overflow: "auto",
            paddingLeft: "8px",
            paddingRight: "8px",

            "& .prevConTableItem": {
              "& span": {
                padding: "4px 4px",
              },
              "& .MuiTypography-root": {
                color: gray[700],
                fontWeight: 500,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                display: "block",
                padding: "3px",
                paddingLeft: "9px",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                  cursor: "pointer",
                },
              },
            },
          },
        },

        "& .prevConColumnsContainer": {
          marginLeft: "0.5rem",
          maxHeight: 550,
          overflowY: "auto",
          "& .prevConTableExpandIcon": {
            color: gray[700],
            background: white,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "26px",
            height: "26px",
            "& svg": {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          },

          "& .prevConTableInfo": {
            background: strip,
            height: "auto",
            minHeight: "auto",
            padding: " 0px 8px",
            boxShadow: "2px 0px 32px 0px rgba(104, 107, 228, 0.00)",

            "& .MuiAccordionSummary-content": {
              margin: "4px 0px",
            },
          },

          "& .prevConValidateButton": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            "& button": {
              float: "right",
              pointerEvents: "auto",
              marginRight: "1rem",
            },
          },
        },
      },
    },
  });

  const getAgGridStyles = () => ({
    "& .ag-theme-balham": {
      background: white,
      boxShadow: "2px 0px 16px rgba(104, 107, 228, 0.16)",
      border: `1px solid ${border}`,
      borderRadius: "12px",
      height: "100%",
      width: "100%",
      "& .ag-root-wrapper-body": {
        height: "auto !important",
      },
      "& .ag-header-cell::after": {
        display: "none",
      },

      "& .ag-ltr": {
        borderColor: white,
        borderRadius: "12px 12px 0 0",
      },

      "& .ag-header .ag-header-row": {
        background: gridheader,
        color: gray[700],
      },
      "& .ag-header-icon": {
        opacity: 1,
        color: gray[700],
      },
      "& .highlight-header": {
        color: "green",
      },
      "& .error-header": {
        color: "#ef6c00",
      },
      "& .ag-center-cols-viewport": {
        background: white,
        minHeight: "40px",
      },
      "& .ag-row": {
        background: white,
        color: "rgba(100, 116, 139, 1)",
        height: "40px",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .ag-cell": {
        background: white,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: "rgba(100, 116, 139, 1)",
      },
      "& .rowsDisplay": {
        position: "absolute",
        background: "transparent",
        bottom: "14px",
        width: "80px",
        left: "14px",
        "& .MuiSelect-select": {
          padding: "3.5px 14px",
        },
      },
      "& .ag-checkbox-input-wrapper": {
        background: "transparent",
      },
      "& .paging-panel": {
        height: "50px",
        marginRight: "6px",
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        "& .Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26) !important",
        },
        "& .MuiButton-outlined": {
          color: darktext,
          transition: "0.5s ease-in-out",
        },
        "& .MuiButton-contained": {
          background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
          color: "#F9F9FF",
          transition: "05.s ease-in-out",
        },
        "& .MuiButtonBase-root": {
          minHeight: "34px",
          height: "34px",
          width: "34px",
          minWidth: "34px",
          boxShadow: "none",
          textTransform: "none",
          fontSize: "12px",
          border: `1px solid ${border}`,
          borderRadius: "19.36px",
        },
      },
    },

    "& .noBorderRadiusAggrid": {
      borderRadius: "0px 0px 12px 12px",
      "& .ag-ltr": {
        borderRadius: "0px",
      },
    },
  });

  const createFile = () => ({
    "& .fileTextbox": {
      border: "1px solid #ccc",
      borderRadius: "4px 0 0 4px",
      position: "relative",
      padding: "5.4px",
      cursor: "pointer",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "block",
      textOverflow: "ellipsis",
      paddingRight: "25px",
      "& p": {
        position: "absolute",
        right: "5px",
        top: "5px",
        alignItems: "center",
        display: "flex",
      },
      "& svg.sucIcon": {
        position: "absolute",
        right: "5px",
        top: "4px",
        color: "green",
        fontSize: "20px",
      },
      "& svg.errIcon": {
        fontSize: "13px",
        marginLeft: 0.4,
      },
    },
    "& .drawerFile": {
      "& .fileUpGrid1": {
        flexBasis: "100%",
        maxWidth: "100%",
      },
      "& .gridCusmd": {
        flexBasis: "50%",
        maxWidth: "50%",
      },
      "& .MuiGrid-container": {
        "& .MuiGrid-grid-xs-8": {
          flexBasis: "50%",
          maxWidth: "50%",
        },
        "& .MuiGrid-grid-xs-2": {
          flexBasis: "25%",
          maxWidth: "25%",
        },
      },
    },
  });

  const palette = {
    mode,
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: gray[500],
    },
    neutral: {
      dark: gray[700],
      main: gray[500],
      light: gray[100],
    },
    background: {
      default: primary[500],
      paper: mode === "dark" ? primary[500] : "white",
    },
  };

  const typography = {
    allVariants: {
      fontSize: 12,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    bold: {
      fontWeight: 600,
    },
    sectionHeading: {
      color: heading,
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "uppercase",
    },
    detailTitle: {
      color: gray[700],
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "capitalize",
    },
    detailValue: {
      color: gray[700],
      fontSize: "12px",
      fontWeight: 500,
    },
  };

  const components = {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#71787e",
          fontFamily: "Poppins, Helvetica, 'sans-serif' ",
          "& .flex": {
            display: "flex",
          },
          "& .column": {
            flexDirection: "column",
          },
          "& .row": {
            flexDirection: "row",
          },
          // flex-end
          "& .flex-end": {
            display: "flex",
            alignItems: "flex-end",
          },

          // inline display
          "& .display-inline": {
            display: "inline-flex",
          },

          /* Vertical and Horizontal Center */
          "& .center": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },

          /* Vertical Center */
          "& .v-center": {
            display: "flex",
            alignItems: "center",
          },

          /* Horizontal Center */
          "& .h-center ": {
            display: "flex",
            justifyContent: "center",
          },
          /* Space Between */
          "& .space-between": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          },

          /* Space Around */
          "& .space-around": {
            display: "flex",
            justifyContent: "space-around",
          },

          // allignment style
          "& .float-right": {
            float: "right",
          },

          // Text styles
          "& .upper-case": {
            textTransform: "uppercase",
          },
          "& .bold-text": {
            fontWeight: 600,
          },

          // Paper UI
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
          "& .MuiPaper-root.MuiPaper-elevation0": {
            boxShadow: "none",
          },
          // Paper UI End

          // Common
          "& .innerHeader": {
            height: "58px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            zIndex: 2,
            boxShadow: "0px 4px 8px 0px rgba(46, 45, 125, 0.16)",
            background: `url(${InnerHeader})`,
            backgroundSize: "cover",
            color: "#F9F9FF",
            padding: "12px 16px 12px 16px",
            // borderRadius: "16px 16px 0 0",
            boxSizing: "border-box",
          },
          "& .innerHeaderContainer": {
            position: "fixed",
            zIndex: 2,
            marginLeft: "-16px",
            marginRight: "-16px",
            borderRadius: 0,
            transition: "width 0.3s ease-in-out, max-width 0.3s ease-in-out",
          },
          "& .pt74": {
            paddingTop: "74px",
            width: "100%",
          },
          "& .accordionArrow": {
            height: "20px",
            width: "20px",
            background: white,
            color: "#4C73FA",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            float: "right",
            borderRadius: "3px",
            cursor: "pointer",
            "&:hover": {
              cursor: "pointer",
              background: "#4C73FA",
              color: white,
            },
          },

          "& .ellipsis": {
            display: "inline-block",
            maxWidth: 260, // percentage also works
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& .ellipsisValidation": {
            display: "inline-block",
            maxWidth: 460, // percentage also works
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
          "& .ellipsisSql": {
            display: "-webkit-box",
            maxWidth: 260,
            webkitLineClamp: 4,
            webkitBoxOrient: "vertical",
            overflow: "hidden",
          },

          "& .disabled-component": {
            background: "lightgray",
            opacity: 0.5,
            pointerEvents: "none",
          },

          "& .disabled-icon": {
            opacity: 0.5,
            pointerEvents: "none",
          },
          "& .backButton": {
            display: "inline-flex",
            padding: "0.25rem 0.5rem",
            alignItems: "center",
            borderRadius: "0.25rem",
            border: "1px solid #F9594B",
            background: "#F9594B",
            fontSize: "0.75rem",
            fontWeight: 600,
            transition: "background-color 0.3s ease", // Transition for smooth hover effect
            "&:hover": {
              background: "#ff7d6f", // Change background color on hover
              cursor: "pointer", // Change cursor to pointer on hover
            },
          },
          "& .DSHeaderContainer": {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          },

          "& .container": {
            borderRadius: "12px",
            border: `1px solid ${border}`,
            background: white,
            boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
            width: "100%",
            "& .subcontainer": {
              background: cardbg,
              textAlign: "center",
              color: darktext,
            },
          },

          "& .noborder-container": {
            width: "100%",
            background: white,
            boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
          },

          "& .left-border": {
            borderRadius: "12px 0 0 12px",
            border: `1px solid ${border}`,
          },
          "& .right-border": {
            borderRadius: "0 12px 12px 0",
            border: `1px solid ${border}`,
          },

          // validation page accordian border
          "& .css-bzyao8-MuiPaper-root-MuiAccordion-root:first-of-type": {
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            color: "#64748B",
          },

          // outlet
          "& .outlet": {
            padding: "0px 16px 16px",
            borderRadius: "16px 16px 0 0",
            width: "100%",
          },

          // online chip
          ".OnlineChip": {
            background: "#E8F5EF",
            border: "0.5px solid #00C163",
            borderRadius: "6px",
            color: success,
          },

          // offline chip
          ".OfflineChip": {
            background: "rgba(100, 116, 139, 0.1)",
            border: "0.5px solid rgba(100, 116, 139, 0.45)",
            borderRadius: "6px",
            color: gray[700],
          },

          // Submit and InnerHeader Create Button
          "& .addButton": {
            backgroundColor: success,
            boxShadow: "none",
            textTransform: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#00a854",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            },
            "&.active": {
              backgroundColor: " #008f4e",
              boxShadow: "inset 0 2px 4px rgba(0, 0, 0, 0.1)",
            },
          },
          "& .PreviewButton": {
            backgroundColor: "#E69434",
            boxShadow: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            padding: "5px 8px",
            "&:hover": {
              backgroundColor: "#E69434",
            },
            "&:disabled": {
              background: "rgba(0, 0, 0, 0.12)",
            },
            "& .MuiLoadingButton-startIconLoadingStart": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            "& .MuiLoadingButton-loadingIndicator": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          },
          "& .UploadButton": {
            backgroundColor: strip,
            borderRadius: "0px 4px 4px 0px",
            color: heading,
            boxShadow: "none",
            textTransform: "none",
            transition: "backgroundColor 0.3s, box-shadow 0.3s",
            "&:hover": {
              backgroundColor: "#c8c1e3",
              boxShadow: "none",
            },
          },
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
          },

          // AgGrid Popover Buttons
          "& .createBtn": {
            float: "right",
            padding: "4px 0",
            marginRight: "10px",

            "& .MuiToolbar-root": {
              backgroundColor: "transparent",
              padding: 0,
            },
            "& .MuiIconButton-root": {
              borderRadius: "4px",
              background: "linear-gradient(90deg, #4840C5 0%, #6334C6 100%)",
              color: white,
              marginLeft: "4px",
              "&:hover": {
                backgroundColor: "#1565c0",
                boxShadow:
                  "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
              },
            },
          },

          // AgGrid Filter button
          "& .refreshBtn": {
            float: "left",
            padding: "10px 0",
            "& .MuiButtonBase-root": {
              border: "1px solid #bdbdbd",
              borderRadius: "4px",
            },
          },

          // loader buton
          "& .loader-button": {
            position: "relative",
            display: "inline-flex",
            // padding: "6px 8px",
            color: white,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            background: "linear-gradient(90deg, #611EB6 0%, #66A2EE 100%)",
            "&:disabled": {
              background: "rgba(0, 0, 0, 0.12)",
            },
            "& .MuiLoadingButton-startIconLoadingStart": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            "& .MuiLoadingButton-loadingIndicator": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          },

          "& .customRadioGroup": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            "& span": {
              color: "#6363D3",
              "&.Mui-checked": {
                color: "#6363D3",
              },
            },
          },

          // custom select
          "& .customSelect": {
            display: "flex",
            width: "250px",
            padding: "0px 16px",
            alignItems: "center",
            gap: "7px",
            flexShrink: 0,
            boxShadow: "none",
            borderRadius: "6px",
            border: 0,
            background: "rgba(245, 245, 245, 0.70)",
            backdropFilter: " blur(1.3600000143051147px)",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { border: 0 },

            "& .MuiOutlinedInput-input": {
              display: "flex",
              alignItems: "center",
              padding: "0px",
              gap: "0.5rem",
              height: "34px",
              borderRadius: 0,
            },
            "& .MuiListItemIcon-root": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "40px !important",

              "& img": {
                width: "25px",
                height: "auto",
                margin: "auto",
              },
            },
          },

          // Error Message
          "& .errorText": {
            color: "#d32f2f",
            display: "flex",
            alignItems: "center",
            "& svg": {
              marginRight: "4px",
            },
          },
          "& .columnName.MuiTypography-root": {
            backgroundColor: "#e0f3ff",
            padding: "3px 8px",
            marginBottom: "4px",
            marginTop: "4px",
            borderRadius: "4px",
          },
          // Error Message End
          "& .containerHead": {
            padding: "0.8rem",
            borderBottom: `1px solid ${border}`,
            borderRadius: "12px 12px 0px 0px",
            background: primary[500],
            // boxShadow: "2px 0px 16px 0px rgba(104, 107, 228, 0.16)",
          },
          // No Data Found
          "& .noDataFound": {
            textAlign: "center",
            marginTop: "25px",
            "& p": {
              fontSize: "18px",
              opacity: "0.6",
            },
            "& button": {
              marginTop: "5px",
            },
          },

          ...getMuiTableStyles(),
          ...getSidemenuStyles(),
          ...getHeaderStyles(),
          ...getAgGridStyles(),
          ...getdataSourcesStyles(),
          ...createFile(),
          ...executionsStatus(),
        },
      },
    },
  };

  return {
    palette,
    typography,
    components,
  };
};

export default themeSettings;
