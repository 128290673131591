import { configureStore } from "@reduxjs/toolkit";

import entityReducer from "./features/entitySlice";
import attributesReducer from "./features/attributesSlice";
import datasetsReducer from "./features/datasetsSlice";
import dataSourcesReducer from "./features/datasourcesSlice";
import dropdownReducer from "./features/dropdownSlice";
import defaultActionsReducer from "./features/defaultActionsSlice";
import connectionReducer from "./features/connectionSlice";
import formReducer from "./features/formSlice";

export const store = configureStore({
  reducer: {
    entity: entityReducer,
    attributes: attributesReducer,
    datasets: datasetsReducer,
    dataSources: dataSourcesReducer,
    connections: connectionReducer,
    dropdown: dropdownReducer,
    formState: formReducer,
    defaultActions: defaultActionsReducer,
  },
});
