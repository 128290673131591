import {
  Alert,
  Autocomplete,
  Box,
  FormControlLabel,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSelectedAttributeIndex, setCurrentSelectedTableIndex } from "../../app/features/attributesSlice";
import { attributes as allAttributes } from "../AttributesToolbar";
import { definePropertiesBasedOnAttributes } from "../../utils/formInfo";
import SkeletonLoader from "../SkeletonLoader";
import PropertiesContainer from "../PropertiesContainer";

export const StyledTableBox = styled(Box)(({ active }) => ({
  marginTop: "5px",
  padding: "5px",
  borderRadius: "5px",
  backgroundColor: active ? "#F4F1FF" : "#fff",
  fontWeight: active ? "bold" : "normal",
  boxShadow: active ? "1px 2px 3px rgb(0 0 0 / 34%)" : "none",
  position: active ? "relative" : "static",
  "&:hover": {
    backgroundColor: "#f4f4f4",
    cursor: "pointer",
  },
}));

const EntitySaveForm = ({ methods, attributeLoader }) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const dispatch = useDispatch();
  const selectedTables = watch("selectedTables");
  const [activeTableIndex, setActiveTableIndex] = useState(0);
  const { selectedAttributeIndex, selectedTableIndex } = useSelector((state) => state.attributes);
  const { selectedConnection } = useSelector((state) => state.dataSources);
  // const [ hasError, setHasError ] = useState(false)

  const activeTableAttributes = useMemo(() => {
    const table = selectedTables[activeTableIndex];
    if (table === undefined) {
      return [];
    }
    return table?.attributes || [];
  }, [activeTableIndex, selectedTables]);

  function handleClickAttribute(attrProps, index) {
    methods.setValue(`selectedTables[${activeTableIndex}.attributes[${index}].isPII`, attrProps.isPII || false);
    methods.setValue("attributeType", attrProps.attributeName);
    methods.setValue(`selectedTables[${activeTableIndex}.attributes[${index}].attributeName`, attrProps.attributeName);
    methods.setValue(`selectedTables[${activeTableIndex}.attributes[${index}].type`, attrProps.type);
    dispatch(setCurrentSelectedAttributeIndex(index));
  }

  useEffect(() => {
    methods.trigger();
  }, []);

  return (
    <Box sx={{ flex: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          py: 1,
        }}>
        <Typography sx={{ fontSize: 14 }}>
          Datasource:{" "}
          <Typography variant={"subtitle1"} component={"span"} sx={{ color: "#0062BA", fontSize: 14, fontWeight: 700 }}>
            {selectedConnection?.connectionType}
          </Typography>
        </Typography>
        <Typography>
          Connection Name:{" "}
          <Typography variant={"subtitle1"} component={"span"} sx={{ color: "#0062BA", fontSize: 14, fontWeight: 700 }}>
            {selectedConnection?.connectionName}
          </Typography>
        </Typography>
        <Typography>
          Number of Selected Tables:
          <Typography variant={"subtitle1"} component={"span"} sx={{ color: "#0062BA", fontSize: 14, fontWeight: 700 }}>
            {selectedTables?.length}
          </Typography>
        </Typography>
      </Box>
      {attributeLoader ? (
        <SkeletonLoader />
      ) : (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ flex: "20%", borderRight: "1px solid #CCC" }}>
            <Typography
              variant="body1"
              sx={{
                py: 1,
                px: 2,
                fontWeight: 700,
                background: "#00498b",
                color: "#FFF",
              }}>
              Table List
            </Typography>
            {selectedTables.map((table, index) => (
              <StyledTableBox
                key={index}
                active={index === activeTableIndex}
                onClick={() => {
                  setActiveTableIndex(index);
                  dispatch(setCurrentSelectedTableIndex(index));
                  dispatch(setCurrentSelectedAttributeIndex(null));
                }}>
                <Typography
                  variant="body1"
                  sx={{
                    maxWidth: 200,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}>
                  {table?.TableName}
                </Typography>
              </StyledTableBox>
            ))}
          </Box>
          <Box sx={{ flex: "60%", borderRight: "1px solid #ccc" }}>
            <Typography
              variant="body1"
              sx={{
                py: 1,
                px: 2,
                fontWeight: 700,
                background: "#0062BA",
                color: "#FFF",
              }}>
              Columns
            </Typography>
            <Box sx={{ p: 2 }}>
              {activeTableAttributes?.length > 0 ? (
                <Box
                  sx={{
                    pb: 8,
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 3,
                    maxHeight: 300,
                    overflowY: "auto",
                  }}>
                  {activeTableAttributes?.map((attr, index) => {
                    const getBorderStyle = () => {
                      if (
                        errors &&
                        Object.keys(errors).length > 0 &&
                        errors.selectedTables &&
                        errors.selectedTables[activeTableIndex] &&
                        errors.selectedTables[activeTableIndex].attributes &&
                        errors.selectedTables[activeTableIndex].attributes[index] &&
                        Object.keys(errors.selectedTables[activeTableIndex].attributes[index]).length > 0
                      ) {
                        return "1px solid #F9594B";
                      }
                      return "1px solid #09A057";
                    };

                    return (
                      <Box key={index} sx={{ cursor: "pointer" }}>
                        <Box
                          sx={{
                            background: "#FFF",
                            borderRadius: "4px",
                            boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
                            p: 1,
                            cursor: "pointer",
                            overflowWrap: "break-word",
                            border: getBorderStyle(),
                          }}
                          onClick={() => handleClickAttribute(attr, index)}
                          className="droppedItemMain">
                          <Tooltip title={attr.attributeCustomName}>
                            <Typography
                              variant={"body1"}
                              sx={{
                                fontSize: 12,
                                maxWidth: 200,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}>
                              {attr.attributeCustomName}
                            </Typography>
                          </Tooltip>
                          <Typography variant="subtitle1" sx={{ color: "#ccc" }}>
                            {attr.attributeName}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Alert variant={"info"} severity={"info"}>
                  No attributes for table.
                </Alert>
              )}
            </Box>
          </Box>
          <Box sx={{ flex: "20%", background: "#f6f6f6" }}>
            <Typography
              variant="body1"
              sx={{
                p: 0.5,
                fontWeight: 700,
                background: "#0062BA",
                color: "#FFF",
              }}>
              Properties
            </Typography>
            {selectedAttributeIndex !== null && selectedTableIndex !== null && (
              <Box sx={{ maxHeight: 300, overflowY: "auto", px: 1 }}>
                <Controller
                  // key={}
                  name={`attributeType`}
                  defaultValue={activeTableAttributes[selectedAttributeIndex]?.attributeName}
                  rules={{
                    required: "Attribute type is required",
                  }}
                  control={methods.control}
                  render={({ field, fieldState }) => {
                    return (
                      <Autocomplete
                        {...field}
                        options={allAttributes.map((attr) => attr.attributeName)}
                        size={"small"}
                        sx={{ my: 2 }}
                        getOptionLabel={(option) => option || ""}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionDisabled={() => !(allAttributes?.length > 0)}
                        disableClearable
                        onChange={(_, value) => {
                          field.onChange(value);
                          const currentState = activeTableAttributes;
                          const selectedAttribute = allAttributes.find((attr) => attr.attributeName === value);
                          const newProperties = definePropertiesBasedOnAttributes(
                            selectedAttribute?.attributeName,
                            currentState[selectedAttributeIndex].IsNullable,
                          );
                          const updatedTables = [...selectedTables];
                          updatedTables[activeTableIndex].attributes[selectedAttributeIndex].properties = newProperties;
                          updatedTables[activeTableIndex].attributes[selectedAttributeIndex].attributeName = value;
                          updatedTables[activeTableIndex].attributes[selectedAttributeIndex].type =
                            selectedAttribute?.type;
                          methods.reset({
                            ...methods.getValues(),
                            selectedTables: updatedTables,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Attrbute Type"
                            error={Boolean(fieldState?.error)}
                            helperText={fieldState?.error?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
                <PropertiesContainer control={methods.control} watch={watch} />
                <Controller
                  key={`selectedTables[${activeTableIndex}.attributes[${selectedAttributeIndex}].isPII`}
                  name={`selectedTables[${activeTableIndex}.attributes[${selectedAttributeIndex}].isPII`}
                  control={methods.control}
                  defaultValue={activeTableAttributes[selectedAttributeIndex]?.isPII}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ mb: 1, mx: 0 }}
                      control={
                        <Switch
                          id="isPii"
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            methods.trigger().then();
                          }}
                          size="small"
                        />
                      }
                      label={"Is PII?"}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EntitySaveForm;
