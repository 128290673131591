import { Autocomplete, Box, Button, Paper, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Save } from "@mui/icons-material";
import { getConnectionByDataSourceType } from "../../services/dataSources.services";
import { SnackbarContext } from "../../App";
import { setSelectedConnection } from "../../app/features/datasourcesSlice";
import SkeletonLoader from "../SkeletonLoader";
import EntitySaveForm from "./EntitySaveForm";
import InnerHeader from "../Layout/InnerHeader";
import { setCurrentSelectedAttributeIndex, setCurrentSelectedTableIndex } from "../../app/features/attributesSlice";
import { attributes } from "../AttributesToolbar";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { createNewEntity, editEntity, fetchEntityById } from "../../app/features/entitySlice";
import TableCheckbox from "../TableCheckbox";
import { ApiService } from "../../services/app.service";
import {
  definePropertiesBasedOnAttributes,
  formatPayloadClonedEntity,
  appendPropertyValues,
} from "../../utils/formInfo";
import { LoadingButton } from "@mui/lab";

const apiService = new ApiService();

export const transformTableObject = (table, selctedTable, isCreate) => {
  function getAttributeType(dataType) {
    const numberTypes = new Set(["NUMBER", "DEC", "INT", "FLOAT", "bigint", "integer", "smallint"].map(type => type.toLowerCase()));
    const baseDataType = dataType.split("(")[0].trim().toLowerCase();
    return numberTypes.has(baseDataType) ? "number" : "string";
  }

  return {
    ...table,
    entityName: table.Name,
    entityDisplayName: table.Name,
    entityRelations: table?.DependentTables || [],
    IsChecked: true,
    IsDisabled: table?.IsDisabled ? table?.IsDisabled : false,
    attributes: table?.Attributes.map((attr) => {
      const attributeType = getAttributeType(attr.DataType);
      const attribute = attributes.find((config) => config.type === attributeType);
      const attributeValues = (selctedTable?.ColumnDetails || []).find((e) => e.OriginalAttributeName === attr.Name);
      const newProperties = definePropertiesBasedOnAttributes(
        attributeValues?.AttributeType || attribute?.type,
        attr.IsNullable,
      );
      const UpdatedProperties = appendPropertyValues(newProperties, attributeValues);

      return {
        ...attr,
        attributeCustomName: isCreate ? attr?.Name : attributeValues?.AttributeName,
        type: attributeType,
        isPII: attributeValues?.IsPII || false,
        isPrimaryKey: (isCreate ? attr.isPrimaryKey : attributeValues?.IsPrimaryKey) || false,
        attributeName: (isCreate ? attribute?.attributeName : attributeValues?.AttributeType) || "String",
        properties: UpdatedProperties,
        entityId: "",
      };
    }),
  };
};

const CreateClonedEntity = () => {
  const dispatch = useDispatch();
  const { setSnack } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { entities } = useSelector((state) => state.entity);
  const loading = useSelector((state) => state.entity.loading);
  const [showSaveForm, setShowSaveForm] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [attributeLoader, setAttributeLoader] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    entityName: "",
    connectionId: "",
    showSaveForm: showSaveForm,
  });

  const entityName = searchParams.get("entityName");
  const connectionId = searchParams.get("connectionId");
  const entitySetId = location.state?.entityId || searchParams.get("entityId");

  const defaultValues = {
    entityName: entityName || "",
    entityDisplayName: "",
    datasource: null,
    connection: connectionId || null,
    selectedTables: [],
    loader: false,
  };

  const methods = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    name: "selectedTables",
    control: methods.control,
  });

  const {
    formState: { errors },
  } = methods;
  const [connections, setConnections] = useState([]);
  const [tables, setTables] = useState([]);

  const selectedConnection = methods.watch("connection");
  const selectedTables = methods.watch("selectedTables");

  // TODO: Needs to be refactored to be generic

  useEffect(() => {
    if (entitySetId) {
      dispatch(fetchEntityById({ type: "cloned", id: entitySetId })).then((entityResponse) => {
        if (entityResponse.meta.requestStatus === "rejected") {
          setSnack({
            message: entityResponse.payload,
            open: true,
            colour: "error",
          });
        } else {
          methods.setValue("datasource", entityResponse?.payload?.dataSource);
          methods.setValue("connection", entityResponse?.payload?.connection);
          methods.setValue("entityName", entityResponse?.payload?.entity?.entityName);
          methods.setValue("entityDisplayName", entityResponse?.payload?.entity?.entityDisplayName);
          methods.setValue("selectedTables", entityResponse?.payload?.entity?.selectedTables);
          dispatch(setSelectedConnection(entityResponse?.payload?.connection));
          methods.trigger();
        }
      });
    } else {
      if (location?.state) {
        methods.setValue("datasource", location?.state?.dataSource);
        methods.setValue("connection", location?.state?.connection);
        methods.setValue("selectedTables", location?.state?.selectedTables);
        dispatch(setSelectedConnection(location?.state?.connection));
        methods.trigger();
      }
    }
  }, []);

  useEffect(() => {
    dispatch(getConnectionByDataSourceType())
      .then((response) => {
        if (response.payload?.error) {
          setSnack({
            message: response.payload.error,
            open: true,
            colour: "error",
          });
        } else {
          setConnections(response.payload);
        }
      })
      .catch((err) => {
        setSnack({
          message: err.message,
          open: true,
          colour: "error",
        });
      });
  }, []);

  const selectedTableAttributes = async () => {
    setAttributeLoader(true);
    methods.setValue("selectedTables", []);
    methods.setValue("loader", true);
    try {
      const tablesAttributeData = await apiService.TableAttributes({
        connectionId: selectedConnection?._id || selectedConnection?.id || connectionId,
        tablesNames: selectedTables.map((e) => e.TableName),
      });
      const tablesWithAttributes = (tablesAttributeData?.data || []).map((t) => {
        const selctedTable = selectedTables.find((el) => el.TableName === t?.TableName);
        const transformedTable = transformTableObject(t, selctedTable, !entitySetId);
        const dep = selectedTables.find((el) => el.TableName === transformedTable.TableName);
        transformedTable.entityRelations = dep?.DependentTables || [];
        transformedTable.IsDisabled = dep?.IsDisabled;

        delete transformedTable.Attributes;
        return transformedTable;
      });
      methods.setValue("loader", false);
      methods.setValue("selectedTables", tablesWithAttributes);
      setAttributeLoader(false);
    } catch (e) {
      setAttributeLoader(false);
      setSnack({
        message: e.message,
        open: true,
        colour: "error",
      });
    }
  };
  useEffect(() => {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams.toString());
      newSearchParams.set("showSaveForm", showSaveForm);
      return newSearchParams;
    });
    if (showSaveForm) {
      selectedTableAttributes();
    }
  }, [showSaveForm]);

  const getConnectionData = async (connectionId) => {
    try {
      setTableLoader(true);
      const tablesResponse = await apiService.TableListByConnection(connectionId);
      setTables(tablesResponse?.data?.Tables || []);
      setTableLoader(false);
    } catch (e) {
      setTableLoader(false);
    }
  };
  useEffect(() => {
    if (connectionId) {
      setTables([]);
      getConnectionData(connectionId);
      methods.setValue("selectedTables", []);
    }
  }, [connectionId, methods]);

  useEffect(() => {
    methods.trigger().then();
  }, [methods]);
  const handleSubmit = (data) => {
    const selectedTables = formatPayloadClonedEntity(data);
    const finalPayload = {
      connectionId: data.connection?._id || data.connection?.id || connectionId,
      entityName: data?.entityName,
      entityDisplayName: data?.entityName,
      entityDescription: data?.entityDescription,
      entityType: "cloned",
      selectedTables: selectedTables,
    };

    if (entitySetId) {
      finalPayload._id = entitySetId;
      dispatch(editEntity(finalPayload)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          setSnack({
            message: res.payload,
            open: true,
            colour: "error",
          });
        } else {
          if (res.payload.error) {
            setSnack({
              message: res.payload.error,
              open: true,
              colour: "error",
            });
          } else {
            setSnack({
              message: "Entity updated successfully",
              open: true,
              colour: "success",
            });
            navigate("/cloned/entity-sets");
          }
        }
      });
    } else {
      dispatch(createNewEntity(finalPayload))
        .then((res) => {
          if (res.payload.error) {
            setSnack({
              message: res.payload.error,
              open: true,
              colour: "error",
            });
          } else {
            if (typeof res.payload === "string" && res.payload) {
              setSnack({
                message: res.payload,
                open: true,
                colour: "error",
              });
            } else {
              setSnack({
                message: "Entity created successfully",
                open: true,
                colour: "success",
              });
              navigate("/cloned/entity-sets");
            }
          }
        })
        .catch((err) => {
          setSnack({
            message: err.message,
            open: true,
            colour: "error",
          });
        });
    }
  };

  const handleSelectConnection = (field, value = null) => {
    field.onChange(value);
    dispatch(setSelectedConnection(value));
    if (value === null) {
      methods.setValue("selectConnection", null);
      methods.setValue("selectedTables", []);
      methods.trigger();
    } else {
      setSearchParams((prevSearchParams) => {
        const newSearchParams = new URLSearchParams(prevSearchParams.toString());
        newSearchParams.set("connectionId", value?.id || value?._id);
        return newSearchParams;
      });
      methods.trigger();
    }
  };

  return (
    <>
      <InnerHeader>
        <Typography variant="h6">{!entitySetId ? "Create Cloned Entity" : "Edit Cloned Entity"}</Typography>
      </InnerHeader>
      <Box sx={{ py: 2, flex: 1, display: "flex", flexDirection: "column" }} className="pt74">
        <FormProvider {...methods}>
          <Box component={"form"} onSubmit={methods.handleSubmit(handleSubmit)}>
            <Paper elevation={3} sx={{ height: "calc(100vh - 200px)" }}>
              {!showSaveForm ? (
                <Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      flex: 1,
                    }}>
                    <Box borderRight={"1px solid #ccc"}>
                      <Box sx={{ padding: "4px 16px", backgroundColor: "#eee" }}>
                        <Typography variant="h6">Entity Details</Typography>
                      </Box>
                      <Box
                        p={2}
                        sx={{
                          "& .MuiFormControl-root": {
                            marginBottom: "16px",
                          },
                        }}>
                        <Controller
                          name="entityName"
                          id="entityName"
                          defaultValue={defaultValues.entityName}
                          control={methods.control}
                          rules={{
                            required: "Entity name is required",
                            validate: {
                              uniqueEntityName: (value) => {
                                if (!entitySetId) {
                                  return (
                                    !entities.some((entity) => entity.entityName === value) ||
                                    "Entity name already exists"
                                  );
                                }

                                const entityExists = entities.some(
                                  (entity) => entity._id !== entitySetId && entity.entityName === value,
                                );
                                if (entityExists) {
                                  return "Entity name already exists";
                                }

                                return true;
                              },
                              noLeadingSpaces: (value) => !/^\s/.test(value) || "Cannot start with a space",
                              noOnlySpaces: (value) => !/^\s+$/.test(value) || "Cannot be only spaces",
                              noTrailingSpaces: (value) => !/\s$/.test(value) || "Cannot end with a space",
                              noSpecialChars: (value) =>
                                /^[A-Za-z][A-Za-z0-9_ \s]*[A-Za-z0-9]$/.test(value) ||
                                "Special characters not allowed except _ and spaces",
                              noLeadingDigits: (value) => !/^[0-9]/.test(value) || "Cannot start with a digit",
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Entity Name"
                              size="small"
                              sx={{ mb: 1 }}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                methods.setValue("entityDisplayName", e.target.value);
                              }}
                              fullWidth
                              variant="outlined"
                              error={Boolean(methods?.formState?.errors?.entityName)}
                              helperText={methods.formState.errors.entityName?.message}
                            />
                          )}
                        />
                        <Controller
                          name="entityDescription"
                          id="entityDescription"
                          control={methods.control}
                          rules={{
                            maxLength: {
                              value: 150,
                              message: "Description should be less than 150 characters",
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              label="Description"
                              variant="outlined"
                              multiline
                              rows={4}
                              size="small"
                              onChange={(event) => {
                                field.onChange(event.target.value);
                                methods.trigger().then();
                              }}
                              error={Boolean(error)}
                              helperText={error?.message}
                              sx={{ mb: 1 }}
                              fullWidth
                            />
                          )}
                        />

                        <Controller
                          name="connection"
                          control={methods.control}
                          rules={{ required: true }}
                          render={({ field, fieldState }) => (
                            <Autocomplete
                              {...field}
                              options={connections}
                              size={"small"}
                              sx={{
                                cursor: "auto",
                              }}
                              value={connections.find((each) => each?.id === connectionId) || field.value}
                              defaultValue={connections.find((each) => each?.id === connectionId) || field.value}
                              disabled={entitySetId}
                              getOptionLabel={(option) => option?.connectionName || ""}
                              isOptionEqualToValue={(option, value) => option?._id === value?._id}
                              getOptionDisabled={() => !connections?.length > 0}
                              disableClearable
                              onChange={(_, value) => {
                                handleSelectConnection(field, value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{
                                    cursor: "auto",
                                  }}
                                  label="Select Connection"
                                  error={Boolean(fieldState?.error)}
                                  helperText={!connectionId && " select connection"}
                                />
                              )}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    {!selectedConnection ? (
                      <Box
                        sx={{
                          // backgroundColor: "#f6f6f6",
                          p: 1,
                          display: "grid",
                          placeContent: "center",
                        }}>
                        <Typography variant="h6">Select Data Source and Connection</Typography>
                      </Box>
                    ) : (
                      <Box>
                        <Box sx={{
                          padding: "4px 16px", display: "flex", alignItems: "center", backgroundColor: "#eee",
                          "& .MuiFormControlLabel-root": {
                            marginLeft: "auto",
                          }
                        }}>
                          <Typography variant="h6">List of Tables</Typography>

                        </Box>
                        <Box
                          p={2}
                          sx={{
                            height: "calc(100vh - 248px)",
                            overflow: "auto",
                            "& .MuiAccordionSummary-root": {
                              minHeight: "35px",
                              py: 0,
                              backgroundColor: "#FAFAF9",
                            },
                            "& .Mui-expanded": {
                              py: 0,
                            },
                          }}>

                          {tableLoader ? (
                            <SkeletonLoader />
                          ) : (
                            <>
                              {tables?.map((table, index) => (
                                <TableCheckbox
                                  key={`table-${index}`}
                                  table={table}
                                  tableData={tables}
                                  connectionId={connectionId}
                                  fields={fields}
                                  append={append}
                                  remove={remove}
                                />
                              ))}
                            </>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <EntitySaveForm methods={methods} attributeLoader={attributeLoader} />
              )}
            </Paper>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                gap: 1,
              }}>
              <Button
                disabled={!methods.getValues()}
                variant="contained"
                color="error"
                type={"button"}
                disableElevation
                onClick={(e) => {
                  e.preventDefault();
                  methods.reset({
                    datasource: null,
                    connection: null,
                    selectedTables: [],
                    entityName: "",
                  });
                  navigate("/cloned/entity-sets");
                }}>
                Cancel
              </Button>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  disabled={!showSaveForm}
                  variant="outlined"
                  color="error"
                  type={"button"}
                  disableElevation
                  onClick={(e) => {
                    e.preventDefault();
                    setShowSaveForm(false);
                  }}>
                  Back
                </Button>
                {showSaveForm ? (
                  <LoadingButton
                    type="submit"
                    size="small"
                    variant="contained"
                    className="loader-button"
                    disableElevation
                    startIcon={<Save sx={{ fontSize: "8px" }} />}
                    autoFocus
                    disabled={Object.keys(errors).length > 0 || loading || tableLoader || attributeLoader}
                    loading={loading}
                    loadingPosition="start">
                    Save
                  </LoadingButton>
                ) : (
                  <Button
                    disabled={
                      Object.keys(errors).length > 0 || selectedTables?.length === 0 || attributeLoader || tableLoader
                    }
                    variant="contained"
                    type={"button"}
                    disableElevation
                    disableFocusRipple
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch(setCurrentSelectedTableIndex(0));
                      dispatch(setCurrentSelectedAttributeIndex(null));
                      setShowSaveForm(true);
                    }}>
                    Next
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};

export default CreateClonedEntity;
