import { Grid, MenuItem, TextField } from "@mui/material";
import { FormTabs } from "./FormTabs";

export default function SalesforceForm(props) {
  const { register, errors, control, formData } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          name="Description"
          label="Description"
          minRows={2}
          maxRows={4}
          multiline
          {...register("Description", { required: true })}
          error={Boolean(errors?.Description)}
          defaultValue={formData?.Description}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          size="small"
          fullWidth
          select
          label="Connection via integration runtime"
          {...register("AutoResolveIntegrationRuntime", { required: true })}
          error={errors.AutoResolveIntegrationRuntime}
          defaultValue={formData?.AutoResolveIntegrationRuntime}>
          <MenuItem value="AutoResolveIntegrationRuntime">Auto Resolve Integration Runtime</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          size="small"
          fullWidth
          label="Environment url"
          {...register("EnvironmentUrl", { required: true })}
          error={errors.EnvironmentUrl}
          defaultValue={formData?.EnvironmentUrl}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          size="small"
          fullWidth
          label="Domain"
          {...register("Domain", { required: true })}
          error={errors.Domain}
          defaultValue={formData?.Domain}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          label="User name"
          {...register("Username", { required: true })}
          error={errors.Username}
          defaultValue={formData?.Username}
        />
      </Grid>

      <Grid item xs={12}>
        <FormTabs
          label={{
            tab1: "Password",
            tab2: "Azure Key vault",
            labelName: "Password",
            type: "password",
          }}
          register={register}
          errors={errors}
          control={control}
          formData={formData}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTabs
          label={{
            tab1: "Security token",
            tab2: "Azure Key vault",
            type: "text",
          }}
          register={register}
          errors={errors}
          control={control}
          formData={formData}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          label="Client ID"
          {...register("ClientId")}
          error={Boolean(errors?.ClientId)}
          defaultValue={formData?.ClientId}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTabs
          label={{
            tab1: "Client Secret",
            tab2: "Azure Key vault",
            type: "text",
          }}
          register={register}
          errors={errors}
          control={control}
          formData={formData}
        />
      </Grid>
    </Grid>
  );
}
