import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import authHeader from "./auth-header";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_BASE_URL;
axios.defaults.headers.common.Authorization = authHeader().Authorization;

export const getDataSourcesList = createAsyncThunk("dataSource/list", async () => {
  try {
    const { data } = await axios.get(`datasource/list`);
    return data;
  } catch (error) {
    throw error.response.data;
  }
});

export const getAllConnections = createAsyncThunk("connection/list", async (_, thunkAPI) => {
  try {
    const res = await axios.get(`datasource/connection/get-all-connections`);
    return res?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const createConnection = createAsyncThunk("connection/create", async (data, thunkAPI) => {
  try {
    const res = await axios.post(`datasource/connection/create`, data);
    return res?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getDataSourceByType = createAsyncThunk("datasource/connections", async (data) => {
  try {
    const res = await axios.post(`datasource/connections`, data);
    return res?.data;
  } catch (error) {
    throw error.response.data;
  }
});
export const getConnectionPreviewData = createAsyncThunk("dataset/tables", async (args, thunkAPI) => {
  try {
    const { id } = args;
    const res = await axios.get(`data-source/connection/tables-list/${id}`);
    return res?.data?.Tables;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getConnectionTableData = async (id) => {
  const res = await axios.get(`data-source/connection/tables/${id}`);
  return res?.data;
};

export const getExistingDataSources = createAsyncThunk("dataSource/getByType", async (_, thunkAPI) => {
  try {
    const res = await axios.get(`datasource/get-all-existing-datasources`);
    return res?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const getConnectionByDataSourceType = createAsyncThunk("connection/getByType", async (arg, thunkAPI) => {
  try {
    const res = await axios.post("/data-source/connections/get-all-by-datasource-type", {});
    return res?.data;
  } catch (e) {
    return thunkAPI.rejectWithValue({ error: e.message });
  }
});

export const dataSourceServices = {
  getDataSourcesList,
  getDataSourceByType,
  createConnection,
  getConnectionPreviewData,
  getConnectionTableData,
};
