import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";

const CustomAddButton = ({
  onClick,
  label,
  size = "small",
  variant = "contained",
  startIcon = <AddIcon />,
  ...rest
}) => {
  return (
    <LoadingButton
      className="addButton"
      size={size}
      variant={variant}
      startIcon={startIcon}
      onClick={onClick}
      {...rest}
    >
      {label}
    </LoadingButton>
  );
};

CustomAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  startIcon: PropTypes.node,
};

export default CustomAddButton;
