import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useContext, useMemo, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SnackbarContext } from "../../App";
import InnerHeader from "../../components/Layout/InnerHeader";
import { ApiService } from "../../services/app.service";
import AgGridSSRM from "../AgGrid/AgGridSSRM";
import CustomDeleteModal from "../CustomComponents/CustomDeleteModal";
import { CustomDeleteIcon, CustomPreviewIcon } from "../CustomComponents/IconButtons";
import { datasetHeaderCells } from "./DatasetHeadcells";

const apiService = new ApiService();

const DatasetsToolbar = ({ numSelected, selectedRows, onDeleteSuccess, type }) => {
  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const openDeleteDialog = () => setIsDeleteDialogOpen(true);
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);

  const handleDeleteConnections = async () => {
    setIsLoadingDelete(true);
    try {
      const response = await apiService.DatasetDelete({
        id: selectedRows,
        type: type,
      });
      onDeleteSuccess(response?.data?.result?.deletedIds);
      setSnack({
        message: "Dataset deleted successfully",
        open: true,
        colour: "success",
      });
      closeDeleteDialog();
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <Toolbar
      sx={{
        minHeight: { xs: 35 },
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        py: { xs: 0 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}>
      {numSelected > 0 && (
        <>
          <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
            {numSelected} {t("Selected")}
          </Typography>
          <Tooltip title={t("Delete")}>
            <IconButton size="small" onClick={openDeleteDialog}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {isDeleteDialogOpen && (
        <CustomDeleteModal
          loading={isLoadingDelete}
          disabled={isLoadingDelete}
          onClickDelete={handleDeleteConnections}
          handleClose={closeDeleteDialog}
        />
      )}
    </Toolbar>
  );
};

DatasetsToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const ActionsCell = ({ data, context }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSnack } = useContext(SnackbarContext);
  const [openModal, setOpenModal] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handlePreviewClick = () => navigate(`/dataset/${data?._id}`);

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    try {
      await apiService.DatasetDelete({
        id: data?._id,
        type: data?.type,
      });
      context?.refreshData();
      setOpenModal(false);
      setSnack({
        message: "Dataset deleted successfully",
        open: true,
        colour: "success",
      });
    } catch (e) {
      setSnack({
        message: e.message,
        open: true,
        colour: "error",
      });
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <>
      <Box className="v-center" sx={{ gap: 0.4 }}>
        <CustomPreviewIcon disabled={data.status !== 3} title={t("Preview")} onClick={handlePreviewClick} />
        <CustomDeleteIcon title={t("delete")} onClick={handleOpen} />
      </Box>
      {openModal && (
        <CustomDeleteModal
          loading={isLoadingDelete}
          disabled={isLoadingDelete}
          onClickDelete={handleDelete}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

const Datasets = () => {
  const gridRef = useRef();
  const [searchParams] = useSearchParams();
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);

  const type = searchParams.get("dataSetType");

  const onDeleteSuccess = () => {
    refreshData();
    setSelected([]);
  };

  const memoizedRows = useMemo(() => rows, [rows]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (memoizedRows.some((e) => e.status === 1 || e.status === 2)) {
        refreshData();
      }
    }, 10000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [memoizedRows]);

  const refreshData = () => {
    gridRef?.current?.api?.refreshServerSide({ purge: true });
  };

  const action = useMemo(
    () => ({
      headerName: "Actions",
      sortable: false,
      cellRenderer: ActionsCell,
      lockPosition: "right",
      cellClass: "locked-col",
      suppressColumnsToolPanel: true,
      suppressHeaderMenuButton: true,
      filter: false,
    }),
    [],
  );
  datasetHeaderCells[datasetHeaderCells.length - 1] = action;

  return (
    <>
      <InnerHeader>
        <Typography variant="h6">{type === "cloned" ? "Cloned" : "Synthetic"} Datasets</Typography>
      </InnerHeader>
      <Box sx={{ flex: 1 }} className="pt74">
        <Box sx={{ width: "100%", mb: 2 }}>
          <Box className="createBtn">
            {selected?.length > 0 && (
              <DatasetsToolbar
                selectedRows={selected}
                numSelected={selected.length}
                onDeleteSuccess={onDeleteSuccess}
                type
              />
            )}
          </Box>
          <AgGridSSRM
            gridRef={gridRef}
            setRows={setRows}
            setSelected={setSelected}
            apiMethod={"DatasetList"}
            headCells={datasetHeaderCells}
            payload={{ type }}
            context={{
              refreshData: refreshData,
              type,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Datasets;
