import { Box, Chip, Divider, IconButton, Paper, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useMemo, useState } from "react";
import { StyledTableBox } from "../Cloned/EntitySaveForm";

const renderChips = (attributes) => {
  const excluded = [
    "",
    null,
    "AttributeName",
    "AttributeType",
    "UseCustomRules",
    ...(attributes.AttributeType !== "Number" && attributes.UseCustomRules ? ["StartsWith", "EndsWith"] : []),
    ...(attributes.AttributeType === "Number" ? ["IsUpperCase"] : []),
    ...(attributes.OriginalAttributeName ? ["IsForeignKey"] : []),
  ];

  return Object.entries(attributes)
    .filter(
      ([key, value]) =>
        !excluded.includes(key) && value !== "" && value !== null && !(Array.isArray(value) && value.length === 0),
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return (
          <Box key={key} sx={{ mb: 1 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", fontSize: 12, m: 0.5 }}>
              {key}
            </Typography>
            {value.map((subValue, subIndex) => {
              if (typeof subValue === "object" && subValue !== null) {
                return renderChips(subValue);
              }
              return (
                <Chip
                  key={`${key}-${subIndex}`}
                  label={`${key}: ${subValue}`}
                  style={{
                    margin: "3px",
                    fontSize: 10,
                    padding: 0,
                    height: 24,
                  }}
                />
              );
            })}
          </Box>
        );
      }
      const label = key === "IsUpperCase" && !value ? "InitialCaps : True" : `${key} : ${value}`;
      return (
        <Chip
          key={key}
          label={label}
          style={{
            margin: "3px",
            fontSize: 10,
            padding: 0,
            height: 24,
          }}
        />
      );
    });
};

const AttributeChips = ({ attributes }) => {
  return <Box sx={{ p: 1, height: 170, overflow: "auto" }}>{renderChips(attributes)}</Box>;
};

const PreviewEntity = ({ ScrollRef, selectedEntity, setSelectedEntity, setActiveEntityId }) => {
  const [activeTableIndex, setActiveTableIndex] = useState(0);

  const activeTableAttributes = useMemo(() => {
    if (!selectedEntity || !Array.isArray(selectedEntity.selectedTables)) {
      return [];
    }
    const table = selectedEntity.selectedTables[activeTableIndex];
    if (table === undefined) {
      return [];
    }
    return table.ColumnDetails || [];
  }, [selectedEntity, activeTableIndex]);
  let columnWidth;

  if (activeTableAttributes?.length === 1) {
    columnWidth = "600px";
  } else if (activeTableAttributes?.length <= 4) {
    columnWidth = "300px";
  } else {
    columnWidth = "250px";
  }
  const gridTemplateColumns = `repeat(auto-fill, minmax(${columnWidth}, 1fr))`;

  return (
    <Paper
      ref={ScrollRef}
      sx={{
        mt: 2,
        flex: 1,
        minHeight: 400,
        borderRadius: "12px",
      }}>
      <Box
        sx={{
          py: 1,
          px: 2,
          display: "flex",
          alignItems: "center",
          gap: 2,
          position: "relative",
        }}>
        <Typography variant={"h6"}>
          <Box component={"span"} fontWeight="normal">
            Entity Set Name:
          </Box>{" "}
          {selectedEntity?.entityName}
        </Typography>
        <Divider orientation="vertical" style={{ height: 18 }} />
        <Typography variant={"h6"}>
          <Box component={"span"} fontWeight="normal">
            Number of Entities:
          </Box>{" "}
          {selectedEntity?.selectedTables?.length}
        </Typography>
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelectedEntity({});
            setActiveEntityId(null);
          }}
          size="small"
          color="error"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}>
          <CancelOutlinedIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: 400,
        }}>
        <Box sx={{ flex: "20%" }}>
          <Typography
            variant="body1"
            sx={{
              marginLeft: "8px",
              marginBottom: "14px",
              px: "5px",
              py: 1,
              fontWeight: 700,
              background: "#DCD4FB",
            }}>
            Table List
          </Typography>
          {selectedEntity?.selectedTables?.map((table, index) => (
            <StyledTableBox
              sx={{ marginLeft: "8px", marginRight: "2px" }}
              key={index}
              active={index === activeTableIndex}
              onClick={() => {
                setActiveTableIndex(index);
              }}>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: 200,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  fontWeight: 700,
                }}>
                {table?.TableName}
              </Typography>
              <Typography variant={"body1"} sx={{ color: "#888" }}>
                No of columns: {table?.ColumnDetails?.length}
              </Typography>
            </StyledTableBox>
          ))}
        </Box>
        <Box sx={{ flex: "80%" }}>
          <Typography
            variant="body1"
            sx={{
              marginRight: "8px",
              py: 1,
              px: 2,
              fontWeight: 700,
              background: "#DCD4FB",
            }}>
            Columns
          </Typography>
          <Box
            sx={{
              p: 2,
              display: "grid",
              gridTemplateColumns,
              gap: 1,
              overflowY: "auto",
            }}>
            {activeTableAttributes?.map((attr, index) => (
              <Box key={index} sx={{ cursor: "pointer" }}>
                <Box
                  sx={{
                    boxShadow: "0 0px 12px rgb(0 0 0 / 8%)",
                    borderRadius: 2,
                    cursor: "pointer",
                    overflowWrap: "break-word",
                  }}>
                  {/* Box heading */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "4px 8px",
                      borderBottom: "1px solid #ccc",
                    }}>
                    <Typography
                      variant={"body1"}
                      sx={{
                        fontSize: 12,
                        // color: "#333",
                        maxWidth: 200,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontWeight: 600,
                      }}>
                      {attr?.AttributeName} {attr?.IsPrimaryKey && "(PK)"}
                      {attr?.IsForeignKey && "(FK)"}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      // sx={{ color: "#3c3c3c" }}
                    >
                      {attr?.AttributeType}
                    </Typography>
                  </Box>
                  <AttributeChips attributes={attr} />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default PreviewEntity;
