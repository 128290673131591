import CryptoJS from "crypto-js";
import shajs from "sha.js";
import { setSecretKey } from "./utils";

// let Encryptionkey = "AecECroRUgnGTa";

const iv = CryptoJS.enc.Utf8.parse("globalaesvectors");
const getSecretKey = async () => {
  const data = await setSecretKey(["DQG-AES-EncyptKey"]);
  const enKey = data?.find((item) => item.SecretKey === "DQG-AES-EncyptKey")?.SecretValue;
  return enKey;
};
export const Decryption = async (InputString, multiDeKey = "") => {
  try {
    const Decryptionkey = multiDeKey || (await getSecretKey());
    const hashstring = shajs("sha256").update(Decryptionkey).digest("hex");
    const hashsubstring = hashstring?.substring(0, 32);
    const Enckey = CryptoJS.enc.Utf8.parse(hashsubstring);
    let DecryptedInput = InputString?.split("-").join("+");
    DecryptedInput = DecryptedInput?.split("_").join("/");
    const Decrypted = CryptoJS.AES.decrypt(DecryptedInput, Enckey, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const response = Decrypted.toString(CryptoJS.enc.Utf8);
    try {
      const parsedResponse = JSON.parse(response);
      return parsedResponse;
    } catch (jsonError) {
      console.error("Error parsing JSON:", jsonError);
      return response;
    }
  } catch (e) {
    return {
      Message: "AuthNZ Server is currently down! Please contact IT support.",
    };
  }
};
export const Encryption = async (InputString) => {
  try {
    const Decryptionkey = await getSecretKey();
    const hashstring = shajs("sha256").update(Decryptionkey).digest("hex");
    const hashsubstring = hashstring.substring(0, 32);
    const Enckey = CryptoJS.enc.Utf8.parse(hashsubstring);
    let Encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(InputString), Enckey, {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    Encrypted = Encrypted?.ciphertext?.toString(CryptoJS.enc.Base64);
    Encrypted = Encrypted?.split("+").join("-");
    Encrypted = Encrypted?.split("/").join("_");
    return { EncryptedData: Encrypted };
  } catch (e) {
    return null;
  }
};
export const DecryptionMultple = async (enArray) => {
  const multiDeKey = await getSecretKey();
  try {
    const muitiResponse = await Promise.all(await enArray.map(async (each) => await Decryption(each, multiDeKey)));
    return muitiResponse;
  } catch (e) {
    return [];
  }
};
