// AttributesToolbar.js

import React from "react";
import { Typography, Card, CardContent, ListItemIcon, Box, List, TextField } from "@mui/material";
import { useDrag } from "react-dnd";
import {
  Abc,
  AccountCircle,
  CalendarToday,
  Call,
  Email,
  Flag,
  LocationCity,
  MyLocation,
  Numbers,
  Person,
  Person2,
  PlaylistAdd,
  Public,
} from "@mui/icons-material";

export const attributes = [
  {
    entityId: "",
    attributeName: "String",
    type: "string",
    attributeCustomName: "",
    IsPrimaryKey: false,
    isValid: false,
    icon: <Abc />,
  },
  {
    entityId: "",
    attributeName: "Number",
    type: "number",
    attributeCustomName: "",
    isPrimaryKey: false,
    isValid: false,
    icon: <Numbers />,
  },
  {
    entityId: "",
    attributeName: "Date",
    type: "date",
    attributeCustomName: "",
    isValid: false,
    icon: <CalendarToday />,
  },
  {
    entityId: "",
    attributeName: "First Name",
    attributeCustomName: "",
    type: "firstName",
    isValid: false,
    icon: <Person />,
  },
  {
    entityId: "",
    attributeName: "Last Name",
    attributeCustomName: "",
    type: "lastName",
    isValid: false,
    icon: <Person2 />,
  },
  {
    entityId: "",
    attributeName: "Full Name",
    attributeCustomName: "",
    type: "fullName",
    isValid: false,
    icon: <AccountCircle />,
  },
  {
    entityId: "",
    attributeName: "Country",
    attributeCustomName: "",
    type: "countries",
    isValid: false,
    icon: <Public />,
  },
  {
    entityId: "",
    attributeName: "State",
    type: "states",
    attributeCustomName: "",
    isValid: false,
    icon: <Flag />,
  },
  {
    entityId: "",
    attributeName: "City",
    type: "cities",
    isValid: false,
    icon: <LocationCity />,
    attributeCustomName: "",
  },
  {
    entityId: "",
    type: "zipCodes",
    attributeName: "Zip Code",
    attributeCustomName: "",
    isValid: false,
    icon: <MyLocation />,
  },
  {
    entityId: "",
    attributeName: "Email",
    type: "email",
    attributeCustomName: "",
    isValid: false,
    icon: <Email />,
  },
  {
    entityId: "",
    type: "phone",
    attributeName: "Phone",
    attributeCustomName: "",
    isValid: false,
    icon: <Call />,
  },
  {
    entityId: "",
    type: "choiceList",
    attributeName: "Choice List",
    attributeCustomName: "",
    isValid: false,
    icon: <PlaylistAdd />,
  },
];

const AttributesToolbar = () => {
  const [searchText, setSearchText] = React.useState("");

  const DraggableAttribute = ({ attribute }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: "attribute",
      item: { ...attribute, isNew: true },
      collect: (monitor) => ({
        isDragging: Boolean(monitor.isDragging()),
      }),
    }));

    return (
      <Card
        ref={drag}
        sx={{
          boxShadow: "none",
          border: "1px solid #eee",
          opacity: isDragging ? 0.8 : 1,
          cursor: "grab",
        }}>
        <CardContent
          style={{
            padding: 8,
            display: "grid",
            gridTemplateColumns: "30px 1fr",
          }}>
          <ListItemIcon>{attribute?.icon}</ListItemIcon>
          <Typography variant="body1">{attribute.attributeName}</Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box>
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        fullWidth
        sx={{ my: 2 }}
        onChange={(event) => {
          setSearchText(event.target.value);
        }}
      />
      <List>
        {attributes
          .filter((attribute) => attribute.attributeName.toLowerCase().includes(searchText?.toLowerCase()))
          .map((attribute, index) => (
            <DraggableAttribute attribute={attribute} key={`attribute-${index}`} />
          ))}
      </List>
    </Box>
  );
};

export default AttributesToolbar;
