import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Divider, Popover, Typography, Badge } from "@mui/material";
import { NotificationsActiveOutlined as NotificationsActiveOutlinedIcon } from "@mui/icons-material";
import { timeAgo } from "../../_helpers/Constant";
const RenderNotificationItem = ({ label, description, createdAt }) => (
  <Box p={1} sx={{ display: "flex", alignItem: "flex-start", borderBottom: "1px solid #ccc" }}>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItem: "center" }}>
        <Typography variant={"h6"}>{label}</Typography>
        <Typography sx={{ opacity: 0.6 }}>{timeAgo(createdAt)}</Typography>
      </Box>

      <Typography sx={{ opacity: 0.6 }}>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </Typography>
    </Box>
  </Box>
);
const Notifications = memo(({ notifications, notificationList, notificationMarkAsRead }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    const messageIds = notifications.allNotifications
      .filter((e) => e.notificationCountReadStatus === false)
      .map((el) => el?.id);
    messageIds.length > 0 && notificationMarkAsRead({ messageId: messageIds, notificationCountReadStatus: true });
    notificationList();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleViewAll = () => {
    navigate("/all-notifications");
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge badgeContent={notifications?.unReadCount} color="success">
          <NotificationsActiveOutlinedIcon />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        PaperProps={{
          sx: {
            ml: "-14%",
            width: "350px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              // eslint-disable-next-line quotes
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}>
        <Box sx={{ position: "relative" }}>
          <Box p={1.5}>
            <Typography variant="h6">Notifications</Typography>
          </Box>
          <Divider />
          <Box sx={{ px: 1.5, maxHeight: 350, overflowY: "auto" }}>
            {(notifications?.allNotifications || []).map((each) => (
              <RenderNotificationItem
                key={each?.id}
                label={each?.messageTitle}
                description={each?.rawMessage}
                createdAt={each?.createdAt}
              />
            ))}
          </Box>
          <Box
            sx={{
              background: "#fff",
              // position: "absolute",
              bottom: 0,
              display: "flex",

              p: 1,
              borderTop: "1px solid #ccc",
              width: "100%",
            }}>
            <Button onClick={handleViewAll} sx={{ mx: "auto" }}>
              View All
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
});
Notifications.displayName = "Notifications";

export default Notifications;
