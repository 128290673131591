import { Tooltip, IconButton } from "@mui/material";
export const CustomEditIcon = ({
  title,
  onClick,
  disabled = false,
  ...props
}) => {
  return (
    <IconButton
      className="v-center"
      onClick={onClick}
      size="small"
      aria-label={title}
      disabled={disabled}
      {...props}
    >
      <Tooltip title={title} placement="top" arrow>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className={disabled ? "disabled-icon" : ""}
        >
          <g clipPath="url(#clip0_1327_18940)">
            <path
              d="M15.8333 0H4.16667C1.86917 0 0 1.86917 0 4.16667V15.8333C0 18.1308 1.86917 20 4.16667 20H15.8333C18.1308 20 20 18.1308 20 15.8333V4.16667C20 1.86917 18.1308 0 15.8333 0ZM18.3333 15.8333C18.3333 17.2117 17.2117 18.3333 15.8333 18.3333H4.16667C2.78833 18.3333 1.66667 17.2117 1.66667 15.8333V4.16667C1.66667 2.78833 2.78833 1.66667 4.16667 1.66667H15.8333C17.2117 1.66667 18.3333 2.78833 18.3333 4.16667V15.8333ZM11.5658 5.31583L5.97667 10.905C5.3475 11.5342 5 12.3717 5 13.2617V14.5833C5 15.0442 5.37333 15.4167 5.83333 15.4167H7.155C8.045 15.4167 8.8825 15.07 9.51167 14.44L15.1008 8.85083C16.0758 7.87583 16.0758 6.29083 15.1008 5.31583C14.1558 4.37167 12.51 4.37167 11.5658 5.31583ZM8.33333 13.2617C8.0225 13.5717 7.59333 13.75 7.155 13.75H6.66667V13.2617C6.66667 12.8167 6.84 12.3983 7.155 12.0833L10.6967 8.54167L11.875 9.72L8.33333 13.2617ZM13.9225 7.6725L13.0533 8.54167L11.875 7.36333L12.7442 6.49417C13.0583 6.17833 13.6075 6.17833 13.9225 6.49417C14.2475 6.81917 14.2475 7.3475 13.9225 7.6725Z"
              fill="#00C163"
            />
          </g>
          <defs>
            <clipPath id="clip0_1327_18940">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Tooltip>
    </IconButton>
  );
};

export const CustomPreviewIcon = ({
  title,
  onClick,
  disabled = false,
  ...props
}) => {
  return (
    <IconButton
      className="v-center"
      onClick={onClick}
      size="small"
      aria-label={title}
      disabled={disabled}
      {...props}
    >
      <Tooltip className="v-center" title={title} placement="top" arrow>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className={disabled ? "disabled-icon" : ""}
        >
          <g clipPath="url(#clip0_1327_18942)">
            <path
              d="M19.3923 7.84956C18.0998 5.74456 15.1598 2.21289 9.9998 2.21289C4.8398 2.21289 1.8998 5.74456 0.607299 7.84956C0.207739 8.49581 -0.00390625 9.24059 -0.00390625 10.0004C-0.00390625 10.7602 0.207739 11.505 0.607299 12.1512C1.8998 14.2562 4.8398 17.7879 9.9998 17.7879C15.1598 17.7879 18.0998 14.2562 19.3923 12.1512C19.7919 11.505 20.0035 10.7602 20.0035 10.0004C20.0035 9.24059 19.7919 8.49581 19.3923 7.84956ZM17.9715 11.2787C16.8615 13.0837 14.349 16.1212 9.9998 16.1212C5.65063 16.1212 3.13813 13.0837 2.02813 11.2787C1.79074 10.8946 1.66501 10.452 1.66501 10.0004C1.66501 9.54883 1.79074 9.10619 2.02813 8.72206C3.13813 6.91706 5.65063 3.87956 9.9998 3.87956C14.349 3.87956 16.8615 6.91372 17.9715 8.72206C18.2089 9.10619 18.3346 9.54883 18.3346 10.0004C18.3346 10.452 18.2089 10.8946 17.9715 11.2787Z"
              fill="#E68200"
            />
            <path
              d="M10.0002 5.83301C9.17608 5.83301 8.37049 6.07738 7.68529 6.53522C7.00008 6.99306 6.46603 7.6438 6.15067 8.40516C5.8353 9.16652 5.75279 10.0043 5.91356 10.8126C6.07433 11.6208 6.47117 12.3632 7.05389 12.946C7.63661 13.5287 8.37903 13.9255 9.18729 14.0863C9.99554 14.2471 10.8333 14.1645 11.5947 13.8492C12.356 13.5338 13.0068 12.9998 13.4646 12.3146C13.9225 11.6293 14.1668 10.8238 14.1668 9.99967C14.1655 8.89501 13.7261 7.83597 12.945 7.05486C12.1639 6.27374 11.1048 5.83433 10.0002 5.83301ZM10.0002 12.4997C9.50571 12.4997 9.02236 12.3531 8.61124 12.0783C8.20012 11.8036 7.87968 11.4132 7.69047 10.9564C7.50125 10.4996 7.45174 9.9969 7.5482 9.51195C7.64466 9.027 7.88277 8.58154 8.2324 8.23191C8.58203 7.88228 9.02749 7.64417 9.51244 7.54771C9.99739 7.45125 10.5001 7.50076 10.9569 7.68998C11.4137 7.87919 11.8041 8.19963 12.0788 8.61075C12.3535 9.02187 12.5002 9.50522 12.5002 9.99967C12.5002 10.6627 12.2368 11.2986 11.7679 11.7674C11.2991 12.2363 10.6632 12.4997 10.0002 12.4997Z"
              fill="#E68200"
            />
          </g>
          <defs>
            <clipPath id="clip0_1327_18942">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Tooltip>
    </IconButton>
  );
};
export const CustomDeleteIcon = ({
  title,
  onClick,
  disabled = false,
  ...props
}) => {
  return (
    <IconButton
      onClick={onClick}
      size="small"
      aria-label={title}
      disabled={disabled}
      {...props}
    >
      <Tooltip title={title} placement="top" arrow>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={disabled ? "disabled-icon" : ""}
        >
          <g clipPath="url(#clip0_1667_3361)">
            <path
              d="M14 2.66667H11.9334C11.7786 1.91428 11.3693 1.23823 10.7742 0.752479C10.1792 0.266727 9.43484 0.000969683 8.66671 0L7.33337 0C6.56524 0.000969683 5.82092 0.266727 5.22587 0.752479C4.63083 1.23823 4.22144 1.91428 4.06671 2.66667H2.00004C1.82323 2.66667 1.65366 2.7369 1.52864 2.86193C1.40361 2.98695 1.33337 3.15652 1.33337 3.33333C1.33337 3.51014 1.40361 3.67971 1.52864 3.80474C1.65366 3.92976 1.82323 4 2.00004 4H2.66671V12.6667C2.66777 13.5504 3.0193 14.3976 3.64419 15.0225C4.26908 15.6474 5.11631 15.9989 6.00004 16H10C10.8838 15.9989 11.731 15.6474 12.3559 15.0225C12.9808 14.3976 13.3323 13.5504 13.3334 12.6667V4H14C14.1769 4 14.3464 3.92976 14.4714 3.80474C14.5965 3.67971 14.6667 3.51014 14.6667 3.33333C14.6667 3.15652 14.5965 2.98695 14.4714 2.86193C14.3464 2.7369 14.1769 2.66667 14 2.66667ZM7.33337 1.33333H8.66671C9.08022 1.33384 9.48346 1.46225 9.82112 1.70096C10.1588 1.93967 10.4143 2.27699 10.5527 2.66667H5.44737C5.58576 2.27699 5.84131 1.93967 6.17897 1.70096C6.51662 1.46225 6.91986 1.33384 7.33337 1.33333ZM12 12.6667C12 13.1971 11.7893 13.7058 11.4143 14.0809C11.0392 14.456 10.5305 14.6667 10 14.6667H6.00004C5.46961 14.6667 4.9609 14.456 4.58583 14.0809C4.21075 13.7058 4.00004 13.1971 4.00004 12.6667V4H12V12.6667Z"
              fill="#F9594B"
            />
            <path
              d="M6.66667 12.0003C6.84348 12.0003 7.01305 11.9301 7.13807 11.8051C7.2631 11.68 7.33333 11.5105 7.33333 11.3337V7.33366C7.33333 7.15685 7.2631 6.98728 7.13807 6.86225C7.01305 6.73723 6.84348 6.66699 6.66667 6.66699C6.48986 6.66699 6.32029 6.73723 6.19526 6.86225C6.07024 6.98728 6 7.15685 6 7.33366V11.3337C6 11.5105 6.07024 11.68 6.19526 11.8051C6.32029 11.9301 6.48986 12.0003 6.66667 12.0003Z"
              fill="#F9594B"
            />
            <path
              d="M9.33329 12.0003C9.5101 12.0003 9.67967 11.9301 9.8047 11.8051C9.92972 11.68 9.99996 11.5105 9.99996 11.3337V7.33366C9.99996 7.15685 9.92972 6.98728 9.8047 6.86225C9.67967 6.73723 9.5101 6.66699 9.33329 6.66699C9.15648 6.66699 8.98691 6.73723 8.86189 6.86225C8.73686 6.98728 8.66663 7.15685 8.66663 7.33366V11.3337C8.66663 11.5105 8.73686 11.68 8.86189 11.8051C8.98691 11.9301 9.15648 12.0003 9.33329 12.0003Z"
              fill="#F9594B"
            />
          </g>
          <defs>
            <clipPath id="clip0_1667_3361">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Tooltip>
    </IconButton>
  );
};

export const CustomLogsIcon = ({
  title,
  onClick,
  disabled = false,
  ...props
}) => {
  return (
    <IconButton
      onClick={onClick}
      size="small"
      aria-label={title}
      disabled={disabled}
      {...props}
    >
      <Tooltip title={title} placement="top" arrow>
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={disabled ? "disabled-icon" : ""}
        >
          <path
            d="M15.5 0H4.5C2.019 0 0 2.019 0 4.5V20.5C0 22.43 1.57 24 3.5 24H15.5C17.981 24 20 21.981 20 19.5V4.5C20 2.019 17.981 0 15.5 0ZM19 4.5V17H5V1H15.5C17.43 1 19 2.57 19 4.5ZM1 4.5C1 2.742 2.308 1.296 4 1.051V17H3.5C2.52 17 1.636 17.407 1 18.058V4.5ZM15.5 23H3.5C2.121 23 1 21.878 1 20.5C1 19.122 2.121 18 3.5 18H19V19.5C19 21.43 17.43 23 15.5 23ZM12 10C13.654 10 15 8.654 15 7C15 5.346 13.654 4 12 4C10.346 4 9 5.346 9 7C9 8.654 10.346 10 12 10ZM12 5C13.103 5 14 5.897 14 7C14 8.103 13.103 9 12 9C10.897 9 10 8.103 10 7C10 5.897 10.897 5 12 5ZM7.025 14.342C7.037 14.309 8.183 11 12 11C15.817 11 16.963 14.309 16.975 14.342C17.062 14.603 16.921 14.885 16.66 14.973C16.607 14.991 16.554 14.999 16.501 14.999C16.293 14.999 16.099 14.868 16.027 14.66C15.99 14.552 15.083 12 12.001 12C8.919 12 8.012 14.551 7.975 14.66C7.885 14.921 7.604 15.062 7.342 14.973C7.08 14.885 6.94 14.603 7.027 14.342H7.025Z"
            fill="#2C2C57"
          />
        </svg>
      </Tooltip>
    </IconButton>
  );
};

export const CustomResultIcon = ({
  title,
  onClick,
  disabled = false,
  ...props
}) => {
  return (
    <IconButton
      className="v-center"
      onClick={onClick}
      size="small"
      aria-label={title}
      disabled={disabled}
      {...props}
    >
      <Tooltip className="v-center" title={title} placement="top" arrow>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          className={disabled ? "disabled-icon" : ""}
        >
          <path
            d="M3.07961 8.35658C4.20036 8.37322 4.95398 7.13731 4.44538 6.15052L6.1113 4.4846C6.60531 4.83828 7.26603 4.86242 7.78384 4.5516L9.42289 5.40711C9.5666 6.10042 10.1821 6.62311 10.9174 6.62311C11.9263 6.64023 12.6872 5.60263 12.3759 4.64692L14.1393 2.88348C15.1274 3.41131 16.3778 2.65197 16.3625 1.52634C16.3625 0.684787 15.6778 0.00012897 14.8363 0.00012897C13.7266 -0.0144073 12.9667 1.20273 13.4621 2.19016L11.7997 3.85266C11.0664 3.31085 9.96641 3.58962 9.56602 4.38869L8.42221 3.79167C8.63163 3.24585 8.51708 2.60316 8.07773 2.1638C6.944 1.04839 5.08749 2.18567 5.53867 3.68682L3.76017 5.46532C3.18899 5.18128 2.47574 5.27654 2.00041 5.75187C1.03959 6.70833 1.73918 8.36566 3.07961 8.35658ZM14.8363 0.969214C15.1435 0.969214 15.3935 1.21914 15.3935 1.52637C15.3654 2.26449 14.307 2.2643 14.2791 1.52637C14.2791 1.21914 14.5291 0.969214 14.8363 0.969214ZM10.9174 4.53968C11.095 4.53968 11.2531 4.6235 11.3552 4.75333C11.3716 4.78789 11.3919 4.82104 11.417 4.85169C11.6029 5.20644 11.3204 5.66398 10.9174 5.654C10.1793 5.62602 10.1794 4.56759 10.9174 4.53968ZM6.60453 2.84898C6.82177 2.63174 7.17526 2.63174 7.39249 2.84898C7.61025 3.0666 7.60963 3.42155 7.39104 3.63827C6.84861 4.1377 6.10319 3.38963 6.60453 2.84898ZM2.68562 6.43705C2.90288 6.21984 3.25631 6.21981 3.47358 6.43705C3.69082 6.65429 3.69082 7.00778 3.47358 7.22501C2.93183 7.72713 2.1836 6.97864 2.68562 6.43705Z"
            fill="#495057"
          />
          <path
            d="M16.7819 15.0302H16.2537V5.3947C16.2537 5.1271 16.0367 4.91016 15.7691 4.91016H13.9032C13.6356 4.91016 13.4187 5.1271 13.4187 5.3947V15.0302H12.3347V8.72693C12.3347 8.45933 12.1178 8.24239 11.8502 8.24239H9.98432C9.71673 8.24239 9.49978 8.45933 9.49978 8.72693V15.0302H8.41583V7.50281C8.41583 7.23522 8.19888 7.01827 7.93128 7.01827H6.06544C5.79784 7.01827 5.5809 7.23522 5.5809 7.50281V15.0301H4.49694V9.95444C4.49694 9.68684 4.28 9.4699 4.0124 9.4699H2.14656C1.87896 9.4699 1.66202 9.68684 1.66202 9.95444V15.0301H1.22111C0.953518 15.0301 0.736572 15.2471 0.736572 15.5147C0.736572 15.7823 0.953518 15.9992 1.22111 15.9992H16.7819C17.4181 15.9782 17.4292 15.0558 16.7819 15.0302ZM2.6311 15.0302V10.439H3.52786V15.0301H2.6311V15.0302ZM6.55002 15.0302V7.98736H7.44677V15.0301H6.55002V15.0302ZM10.4689 15.0302V9.21147H11.3657V15.0302H10.4689ZM14.3878 15.0302V5.87924H15.2846V15.0302H14.3878Z"
            fill="#495057"
          />
        </svg>
      </Tooltip>
    </IconButton>
  );
};

export const CustomExecuteIcon = ({
  title,
  onClick,
  disabled = false,
  ...props
}) => {
  return (
    <IconButton
      className="v-center"
      onClick={onClick}
      size="small"
      aria-label={title}
      disabled={disabled}
      {...props}
    >
      <Tooltip className="v-center" title={title} placement="top" arrow>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className={disabled ? "disabled-icon" : ""}
        >
          <path
            d="M16.4293 6.97518L9.10589 1.72452C8.53391 1.31526 7.85709 1.06879 7.1505 1.01243C6.44392 0.956078 5.73516 1.09204 5.10282 1.40525C4.47048 1.71846 3.93928 2.19668 3.56811 2.78687C3.19693 3.37706 3.0003 4.05617 3 4.7489V15.2502C3.00013 15.9432 3.19677 16.6227 3.56812 17.2132C3.93946 17.8036 4.47098 18.282 5.10368 18.5952C5.73639 18.9084 6.44553 19.0441 7.15241 18.9874C7.85929 18.9307 8.53626 18.6837 9.10819 18.2738L16.4316 13.0232C16.9177 12.6748 17.313 12.2195 17.5855 11.6941C17.858 11.1686 18 10.5878 18 9.9988C18 9.40975 17.858 8.82898 17.5855 8.30355C17.313 7.77811 16.9177 7.32277 16.4316 6.97443L16.4293 6.97518ZM15.5204 11.8133L8.19699 17.0639C7.85387 17.3089 7.44805 17.4564 7.02446 17.49C6.60088 17.5235 6.17604 17.4419 5.79699 17.2542C5.41793 17.0664 5.09943 16.7798 4.87675 16.4261C4.65408 16.0725 4.5359 15.6655 4.5353 15.2502V4.7489C4.53104 4.33286 4.64699 3.92408 4.86982 3.56955C5.09266 3.21501 5.41334 2.92909 5.79501 2.74465C6.11965 2.5833 6.47864 2.49901 6.84286 2.49862C7.331 2.50044 7.80566 2.6553 8.19699 2.94042L15.5204 8.19108C15.8117 8.40012 16.0486 8.67324 16.2118 8.98833C16.3751 9.30341 16.4602 9.65163 16.4602 10.0048C16.4602 10.358 16.3751 10.7062 16.2118 11.0213C16.0486 11.3364 15.8117 11.6095 15.5204 11.8185V11.8133Z"
            fill="#4840C5"
          />
        </svg>
      </Tooltip>
    </IconButton>
  );
};
