import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedId: null
};

export const defaultActionsSlice = createSlice({
	name: "defaultActions",
	initialState,
	reducers: {
		setSelectedId: (state, action) => {
			state.selectedId = action.payload;
		}
	},
});

export const {
	setSelectedId
} = defaultActionsSlice.actions;

export default defaultActionsSlice.reducer;
