import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { Box, FormControl, Grid, IconButton, MenuItem, Paper, Select, Tooltip } from "@mui/material";
import { mdiFilterRemove } from "@mdi/js";
import Icon from "@mdi/react";
import APIServices from "../../services/app.service";
import { useTranslation } from "react-i18next";
import { UserDetailsContext } from "../../services/UserDetailsContext";
import { SnackbarContext } from "../../App";
import CustomAgGridPagination from "./CustomAgGridPagination";

const AgGridSSRM = React.memo(
  ({
    gridRef,
    closePreview = () => null,
    setSelected = () => null,
    setRows = () => [],
    setSelectedRowId = () => null,
    apiMethod = "",
    headCells = [],
    context = {},
    payload = null,
    previewId = null,
    onClickRow = () => null,
  }) => {
    const { t } = useTranslation();
    const { userDetails } = useContext(UserDetailsContext);
    const { setSnack } = useContext(SnackbarContext);
    const [gridApi, setGridApi] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [resetStatus, setResetStatus] = useState([]);
    const [rowHeadCells, setRowHeadCells] = useState(headCells);

    const defaultColDef = useMemo(
      () => ({
        resizable: true,
        menuTabs: ["filterMenuTab", "columnsMenuTab"],
      }),
      [],
    );

    const customLocaleText = useMemo(
      () => ({
        page: t("Page"),
        to: t("to"),
        of: t("of"),
        more: t("more"),
        first: t("First"),
        previous: t("Previous"),
        next: t("Next"),
        last: t("Last"),
      }),
      [t],
    );

    const gridOptions = useMemo(
      () => ({
        context,
        domLayout: "autoHeight",
        suppressCellFocus: true,
        // getRowId: (data) => data?.data?.id || data?.data?._id,
        isRowSelectable: (rowNode) => {
          return rowNode?.data?.UserId === userDetails?.UserId || userDetails?.RoleName === "Admin";
        },
        localeText: customLocaleText,
      }),
      [context, userDetails?.UserId, userDetails?.RoleName, customLocaleText],
    );

    const rowClassRules = useMemo(
      () => ({
        "preview-highlight": (params) => params?.data?.preview || false,
      }),
      [],
    );

    useEffect(() => {
      const api = gridRef?.current?.api;
      if (api) {
        api.forEachNode((rowNode) => {
          const data = rowNode.data;
          const isPreview = data?.id === previewId || data?._id === previewId;
          rowNode.setSelected(isPreview);
        });
      }
    }, [previewId, gridRef]);

    const onSelectionChanged = useCallback(() => {
      const gridApi = gridRef?.current?.api;
      if (!gridApi) {
        return;
      }

      const renderNodes = gridApi
        .getRenderedNodes()
        .filter((node) => node?.selected)
        .map((node) => node?.rowIndex)
        .map(String);

      setSelectedRowId?.(renderNodes);
      const allRowNodes = gridApi.getRenderedNodes();
      const selectedNodes = allRowNodes
        .map((nodeKey) => (nodeKey?.selected ? nodeKey?.data?.id || nodeKey?.data?._id || nodeKey?.data?.Id : null))
        .filter(Boolean);
      setSelected?.(selectedNodes);
    }, [gridRef, setSelected, setSelectedRowId]);

    const clearFilters = useCallback(() => {
      gridRef.current?.api.setFilterModel(null);
    }, [gridRef]);

    const onFilterChanged = useCallback(() => {
      const filterModel = gridRef.current?.api.getFilterModel();
      setResetStatus(Object.keys(filterModel));
      gridRef.current?.api.hideOverlay();
      gridRef.current?.api.deselectAll();
    }, [gridRef]);

    const onFirstDataRendered = useCallback(() => {
      gridRef?.current?.api?.sizeColumnsToFit();
    }, [gridRef]);

    const onPageSizeChanged = useCallback(
      (e) => {
        const value = e.target.value;
        setPageSize(value);
        gridRef.current?.api?.paginationSetPageSize(Number(value));
      },
      [gridRef],
    );

    const onPaginationChanged = useCallback((e) => {
      e.api.deselectAll();
      if (gridRef.current.api) {
        const Page = gridRef.current.api.paginationGetCurrentPage() + 1;
        setCurrentPage(Page);
        const pages = gridRef.current.api.paginationGetTotalPages();
        setTotalPages(pages);
      }
    }, []);

    const translatedHeadCells = useMemo(
      () =>
        rowHeadCells.map((column) => ({
          ...column,
          headerName: t(column.headerName),
          headerTooltip: t(column.headerName),
        })),
      [rowHeadCells, t],
    );

    const onRowClicked = useCallback(
      (e) => {
        onClickRow?.(e.data);
      },
      [onClickRow],
    );

    const onGridReady = useCallback(
      (params) => {
        gridRef?.current?.api?.sizeColumnsToFit();
        setGridApi(params.api);
      },
      [gridRef],
    );

    useEffect(() => {
      if (gridApi) {
        const datasource = {
          getRows(params) {
            closePreview?.();
            const { startRow, endRow, filterModel, sortModel } = params.request;
            const data = { ...payload };

            if (sortModel?.length) {
              const { colId, sort } = sortModel[0];
              data.sort = colId;
              data.order = sort;
            }

            if (Object.keys(filterModel).length) {
              data.filter = filterModel;
            }

            data.start = startRow;
            data.limit = endRow - startRow;

            APIServices[apiMethod](data)
              .then((response) => {
                const rowCount = response?.data?.rowCount || response?.data?.data?.length || 0;
                params.success({
                  rowData: response?.data?.data,
                  rowCount,
                });
                setRows(response?.data?.data);
                if (headCells.length === 0 && response?.data?.data.length > 0) {
                  const columns = Object.keys(response?.data?.data[0]);
                  const rowHeaders = [];
                  for (const column of columns) {
                    rowHeaders.push({
                      field: column,
                      headerName: column,
                      sortable: false,
                      headerTooltip: column,
                    });
                  }
                  setRowHeadCells(rowHeaders);
                } else {
                  setRowHeadCells(headCells);
                }

                if (!rowCount) params.api.showNoRowsOverlay();
                else params.api.hideOverlay();
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
                setSnack({
                  message: error?.response?.data?.message ?? error.message,
                  open: true,
                  colour: "error",
                });
                params.fail();
              });
          },
        };

        gridApi.setServerSideDatasource(datasource);
      }
    }, [gridApi]);

    // Override console.error to suppress specific ag-Grid licensing messages
    const originalError = console.error;
    console.error = (...args) => {
      if (
        !args.some(
          (arg) =>
            (typeof arg === "string" || Array.isArray(arg)) &&
            (arg.includes("trial license") ||
              arg.includes("AG Grid Enterprise") ||
              arg.includes("License Key Not Found") ||
              arg.includes("it is not licensed for development") ||
              arg.includes("******************************************************")),
        )
      ) {
        originalError(...args);
      }
    };

    return (
      <>
        {resetStatus?.length > 0 && (
          <Box className="refreshBtn">
            <IconButton
              color="error"
              size="small"
              variant="outlined"
              onClick={clearFilters}
              disabled={!resetStatus.length}>
              <Tooltip title="Clear Filters" placement="right">
                <Icon path={mdiFilterRemove} size={0.5} />
              </Tooltip>
            </IconButton>
          </Box>
        )}
        <Grid container>
          <Grid xs={12} item>
            <Box className="ag-theme-balham" component={Paper}>
              <Box position="relative">
                <AgGridReact
                  ref={gridRef}
                  rowModelType="serverSide"
                  cacheBlockSize={pageSize}
                  maxConcurrentDatasourceRequests={1}
                  infiniteInitialRowCount={0}
                  onGridReady={onGridReady}
                  onSelectionChanged={onSelectionChanged}
                  suppressPaginationPanel={true}
                  suppressScrollOnNewData={true}
                  columnDefs={translatedHeadCells}
                  animateRows={true}
                  rowClassRules={rowClassRules}
                  defaultColDef={defaultColDef}
                  onFilterChanged={onFilterChanged}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  gridOptions={gridOptions}
                  paginationPageSize={pageSize}
                  pagination={true}
                  onPaginationChanged={onPaginationChanged}
                  onFirstDataRendered={onFirstDataRendered}
                  tooltipShowDelay={10}
                  onRowClicked={onRowClicked}
                />
                <Box>
                  <FormControl className="rowsDisplay" fullWidth size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pageSize}
                      onChange={onPageSizeChanged}>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                  <CustomAgGridPagination gridRef={gridRef} currentPage={currentPage} totalPages={totalPages} />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  },
);

AgGridSSRM.displayName = "Server Side AG-Grid";

export default AgGridSSRM;
