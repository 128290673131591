import { Box, Typography } from "@mui/material";
import Logo from "../../images/dq-gateway-logo.png";
import styles from "./ADLoginLoading.module.css";
const ADLoginLoading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}>
      <img src={Logo} width="200" alt="logo-notavailable" />

      <div className={styles.centerdiv}>
        <ul>
          <li></li>
          <li></li> <li></li> <li></li> <li></li>
        </ul>
      </div>

      <Typography variant="body1" className={styles.loadingMessage}>
        Logging in, please wait...
      </Typography>
    </Box>
  );
};

export default ADLoginLoading;
