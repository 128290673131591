import InnerHeader from "../components/Layout/InnerHeader";
import { Box, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SnackbarContext } from "../App";
import QueryEntityForm from "../components/Forms/QueryEntityForm";
import { getConnectionByDataSourceType } from "../services/dataSources.services"; // Assume this function exists
import { ApiService } from "../services/app.service";
const apiService = new ApiService();

const fields = [
    {
        name: "entityName",
        label: "Entity Name",
        type: "text",
        required: true,
        rules: {
            required: "Entity Name is required",
            minLength: { value: 2, message: "Minimum length is 2" }
        }
    },
    {
        name: "datasource",
        label: "Datasource",
        type: "select",
        options: [], // This will be updated dynamically
        rules: {}
    },
    {
        name: "table",
        label: "Table",
        type: "select",
        options: [], // This will be updated dynamically based on datasource
        rules: {}
    },
    {
        name: "description",
        label: "Description",
        type: "textarea",
        rows: 6,
        rules: {
            minLength: { value: 10, message: "Description must be at least 10 characters long" }
        }
    }
];

export default function QueryEntityCreate() {
    const dispatch = useDispatch();
    const { setSnack } = useContext(SnackbarContext);
    const [searchParams] = useSearchParams();
    const [defaultValues,] = useState({
        entityName: "",
        datasource: "",
        connection: null,
        selectedTables: [],
        loader: false,
        description: ""
    });
    const [formFields, setFormFields] = useState(fields);
    const [columns, setColumns] = useState([]);

    console.log(searchParams);
    const onChageFormValues = (data) => {
        console.log(data, 65);
    };

    useEffect(() => {
        getDatasorces();
    }, []);

    const getDatasorces = async () => {
        try {
            const result = await dispatch(getConnectionByDataSourceType());
            const options = (result?.payload || []).map((e) => ({
                label: e.connectionName,
                value: e.id
            }));
            setFormFields((prevFields) =>
                prevFields.map((field) =>
                    field.name === "datasource" ? { ...field, options } : field
                )
            );
        } catch (error) {
            setSnack({
                message: error?.message,
                open: true,
                color: "error",
            });
        }
    };

    const handleDatasourceChange = async (selectedDatasource) => {
        let options = [];
        try {
            const tablesResponse = await apiService.TableListByConnection(selectedDatasource);
            options = (tablesResponse?.data?.Tables || []).map((e) => ({
                label: e.TableName,
                value: e.TableName
            }));
            setFormFields((prevFields) =>
                prevFields.map((field) =>
                    field.name === "table" ? { ...field, options } : field
                )
            );
        } catch (error) {
            setFormFields((prevFields) =>
                prevFields.map((field) =>
                    field.name === "table" ? { ...field, options } : field
                )
            );
            setSnack({
                message: error?.message,
                open: true,
                color: "error",
            });
        }
    };

    const selectedTableAttributes = async (value, allValues) => {
        console.log(value, allValues, 120);
        try {
            const tablesAttributeData = await apiService.TableAttributes({
                connectionId: allValues.datasource,
                tablesNames: [value],
            });
            const columnsDetails = tablesAttributeData.data.find((e) => e.TableName === value);
            setColumns(columnsDetails);

        } catch (e) {
            setSnack({
                message: e.message,
                open: true,
                colour: "error",
            });
        }
    };


    const onSubmit = (data) => {
        console.log(data, 76);
        // Handle form submission
    };

    return (
        <Box sx={{ width: "100%" }}>
            <InnerHeader>
                <Typography variant="h6">Query Entity</Typography>
            </InnerHeader>
            <Box sx={{ py: 2, flex: 1, display: "flex", flexDirection: "column" }} className="pt74">
                <QueryEntityForm
                    fields={formFields}
                    defaultValues={defaultValues}
                    columns={columns}
                    onSubmit={onSubmit}
                    onChageFormValues={onChageFormValues}
                    handleFieldChange={(field, value, allValues) => {
                        if (field === "datasource") {
                            handleDatasourceChange(value);
                        } else if (field === "table") {
                            selectedTableAttributes(value, allValues);
                        }
                    }}
                />
            </Box>
        </Box>
    );
}
