import { Grid, TextField } from "@mui/material";

function isValidHttpUrl(string) {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (error) {
    return false;
  }
}

export default function HBaseForm({ register, errors, formData }) {
  return (
    <>
      <Grid item sm={12}>
        <TextField
          fullWidth
          size="small"
          name="Title"
          label="Description"
          multiline
          rows={4}
          {...register("Description")}
          defaultValue={formData?.Description}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          defaultValue={formData?.host}
          {...register("host", { required: true })}
          label="Host"
          fullWidth
          error={Boolean(errors?.host)}
          variant="outlined"
          size="small"
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          defaultValue={formData?.httppath}
          {...register("httppath", {
            required: true,
            validate: {
              validate: (value) => isValidHttpUrl(value) || "Invalid URL",
            },
          })}
          helperText={errors?.httppath?.message}
          label="Http path"
          fullWidth
          error={Boolean(errors.httppath)}
          variant="outlined"
          size="small"
        />
      </Grid>
    </>
  );
}
