import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  // Checkbox,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
// import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import { SnackbarContext } from "../../../App";
import { formatDate } from "../../../_helpers/utils";
import ApiService from "../../../services/app.service";
import SkeletonLoader from "../../SkeletonLoader";
// import CustomLoadingButton from "../../CustomComponents/CustomsButtons/CustomLoadingButton";

const PreviewDBConnection = ({ connectionDetails, tablesList, closePreview, tablesLoading }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchInput = searchParams.get("searchInput") || "";

  // const [tables, setTables] = useState([]);
  const [selectedtable, setSelectedTable] = useState("");
  // const [sobjects, setSobjects] = useState([]);
  const [databaseTables, setDatabaseTables] = useState([]);
  const { setSnack } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  // const [loadingTables, setLoadingTables] = useState(true);
  // const [customObjects, setCustomObjects] = useState(false);

  const getTableData = async (intailTableData) => {
    setLoading(true);
    setDatabaseTables([]);
    setSelectedTable(intailTableData);
    try {
      const response = await ApiService.ConnectionDetailsDataValidation({
        connectionId: connectionDetails.id,
        tableName: [intailTableData],
        rowsPerPage: 25,
      });
      setDatabaseTables(response?.data?.tablesData);
    } catch (error) {
      setSnack({
        message: error?.response?.data?.message ?? error.message,
        open: true,
        colour: "error",
      });
    }
    setLoading(false);
  };

  const onChangeSearchInput = (e) => {
    setSearchParams((prev) => {
      const newState = new URLSearchParams(prev);
      newState.set("searchInput", e.target.value || "");
      return newState;
    });
  };
  return (
    <Box className="previewConnection">
      <DialogTitle className="previewConTitleContainer" component="div">
        <IconButton onClick={closePreview} size="small" color="error" className="prevConCloseIcon">
          <CancelOutlinedIcon />
        </IconButton>
        {["My SQL", "SQL", "PostgreSQL", "Snowflake", "Azure SQL", "SAP HANA", undefined].includes(
          connectionDetails?.connectionType,
        ) && (
          <Grid container>
            <Grid md={3} item className="titleItem">
              <Typography>{t("Data Source Name")}: </Typography>
              <Typography>{connectionDetails?.connectionName}</Typography>
            </Grid>
            <Grid md={3} item className="titleItem">
              <Typography>{t("Server")}: </Typography>
              <Typography>{connectionDetails?.server}</Typography>
            </Grid>

            <Grid md={2} item className="titleItem">
              <Typography>{t("Database")}: </Typography>
              <Typography>{connectionDetails?.dataBase}</Typography>
            </Grid>
            <Grid md={2} item className="titleItem">
              <Typography>{t("Schema")}: </Typography>
              <Typography>{connectionDetails?.schema}</Typography>
            </Grid>

            <Grid md={2} item className="titleItem">
              <Typography>{t("Created At")}: </Typography>
              <Typography>{formatDate(connectionDetails?.createdDate)}</Typography>
            </Grid>
          </Grid>
        )}

        {["Salesforce"].includes(connectionDetails?.connectionType) && (
          <Grid container>
            <Grid item xs>
              <Box>
                <Typography variant="bold">{t("Data Source Name")} :</Typography>
                <Typography>{connectionDetails?.connectionName}</Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box>
                <Typography variant="bold">{t("Domain")} : </Typography>
                <Typography>{connectionDetails?.Domain} </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box>
                <Typography variant="bold">{t("Saleforce Cloud")} :</Typography>
                <Typography>{"Sales "}</Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent className="previewConContent">
        {tablesLoading ? (
          <SkeletonLoader />
        ) : (
          <Grid container sx={{ mt: 2 }}>
            <Grid className="prevConTablesContainer" item md={3}>
              <Box sx={{ maxHeight: 550 }}>
                <Box className="space-between prevConTablesTitle">
                  <Box>
                    <Typography variant="bold">Tables</Typography>
                    <Typography sx={{ ml: 2 }} color="success.main" variant="bold">
                      Total : {tablesList?.length}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ p: 1 }}>
                  <InputBase
                    className="prevConSearchInput"
                    size="small"
                    placeholder={t("Search here")}
                    value={searchInput}
                    onChange={onChangeSearchInput}
                  />
                </Box>
                <Box className="prevConTableList">
                  {tablesList
                    ?.filter((each) => each?.table_name?.toLowerCase()?.includes(searchInput?.toLowerCase()))
                    ?.map((each) => (
                      <Box className="v-center prevConTableItem" key={each.table_name}>
                        <Typography
                          onClick={() => getTableData(each?.table_name)}
                          style={{
                            opacity: loading ? 0.5 : 1,
                            pointerEvents: loading ? "none" : "auto",
                            backgroundColor: selectedtable === each.table_name ? "#f0f0f0" : "transparent",
                            fontWeight: selectedtable === each.table_name ? "bold" : "normal",
                          }}>
                          {each?.table_name}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Grid>

            <Grid item md={9}>
              <Box className="prevConColumnsContainer">
                {loading && <SkeletonLoader />}
                {databaseTables?.map((row, index) => {
                  const labelId = `enhanced-table-acordian-${index}`;
                  const databseTableName = Object.keys(row);
                  const databseTableValus = Object.values(row);

                  return (
                    <Accordion key={index} elevation={0} expanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <Box className="prevConTableExpandIcon">
                            <KeyboardArrowDownOutlinedIcon />
                          </Box>
                        }
                        id={labelId}
                        className="prevConTableInfo">
                        <Grid container>
                          <Grid item sm={9}>
                            <Typography>
                              <b>{databseTableName[0]}</b>
                            </Typography>
                            <Box className="v-center" gap={2}>
                              <Typography color={"primary"}>
                                {t("Rows")} : {databseTableValus[0]?.rowsCount}
                              </Typography>
                              <Typography color={"error"}>
                                {t("Cols")} : {databseTableValus[0].columnCount}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer sx={{ height: "100%", maxWidth: "70vw" }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {databseTableValus[0]?.Columns.map((v, i) => (
                                  <TableCell key={i}>{v.COLUMN_NAME}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {databseTableValus[0]?.rows?.map((itemRow, ki) => (
                                <TableRow key={ki}>
                                  {databseTableValus[0]?.Columns?.map((v, i) => (
                                    <TableCell
                                      sx={{
                                        minWidth: "100px",
                                        maxWidth: "auto",
                                      }}
                                      key={i}>
                                      {itemRow[v.COLUMN_NAME]}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}

                              {databseTableValus[0]?.rows.length === 0 && (
                                <TableRow>
                                  <TableCell
                                    sx={{ textAlign: "center" }}
                                    colSpan={databseTableValus[0]?.Columns?.length}>
                                    {t("No records to display")}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Box>
  );
};

export default PreviewDBConnection;
