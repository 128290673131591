import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const generateDataset = createAsyncThunk("dataset/create", async (arg, thunkAPI) => {
  try {
    const response = await axios.post("/entities/create/syntheticdata", {
      ...arg,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAllDatasets = createAsyncThunk("dataset/getAll", async (arg, thunkAPI) => {
  try {
    const response = await axios.post(`/datasets/get-all`, { ...arg });
    return response.data;
  } catch (error) {
    console.log("Error in get all datasets ", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getDatasetCreationProgress = createAsyncThunk("dataset/getCreationProgress", async (arg, thunkAPI) => {
  try {
    const response = await axios.post(`/entities/pending-datasets`, {
      ...arg,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getDatasets = createAsyncThunk("dataset/getById", async (arg, thunkAPI) => {
  try {
    const response = await axios.post("/entities/get/datasets", { ...arg });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getAllDatasetsByIds = createAsyncThunk("dataset/getByIds", async (arg, thunkAPI) => {
  try {
    const { ids } = arg;
    const response = await axios.post("/entities/get-datasets-by-id", {
      entityIds: [...ids],
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getDatasetByQuery = createAsyncThunk("dataset/get-by-query", async (arg, thunkAPI) => {
  try {
    const response = await axios.post(`/entities/synthetic-data/Bytable`, {
      ...arg,
    });
    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const downloadDataset = createAsyncThunk("dataset/download", async (arg, thunkAPI) => {
  try {
    const response = await axios.post(`/entities/synthetic-data/start-download`, { ...arg });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const downloadProgress = createAsyncThunk("dataset/downloadProgress", async (arg, thunkAPI) => {
  try {
    const response = await axios.post(`/entities/synthetic-data/check-download-status/${arg.downloadId}`, { ...arg });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const deleteDataset = createAsyncThunk("dataset/delete", async (arg, thunkAPI) => {
  try {
    const { datasetId, type } = arg;
    const response = await axios.post(`/datasets/delete`, {
      id: datasetId,
      type,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const datasetsSlice = createSlice({
  name: "datasets",
  initialState: {
    datasets: [],
    datasetsProgress: [],
    status: "idle",
    error: null,
    loading: false,
    downloadId: "",
  },
  reducers: {
    setEntity: (state, action) => {
      state.entities = action.payload;
    },
    setCurrentEntity: (state, action) => {
      state.currentEntity = { ...state.currentEntity, ...action.payload };
    },
    updateEntityInfo: (state, action) => {
      // state.entities.push(action.payload);
      state.currentEntity[action.payload.key] = action.payload.value;
    },
    updateEntitySuccess: (state, action) => {
      const updatedEntity = action.payload;
      const index = state.entities.findIndex((e) => e.id === updatedEntity.id);
      if (index !== -1) {
        state.entities[index] = updatedEntity;
      }
    },
    resetCurrentEntityInfo: (state) => {
      state.currentEntity = {
        entityName: "",
        entityDisplayName: "",
        entityType: "custom",
        entityRelations: [],
        entityDescription: "",
        attributes: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateDataset.pending, (state) => {
        state.status = "loading";
      })
      .addCase(generateDataset.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.datasets = action.payload;
      })
      .addCase(generateDataset.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAllDatasets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllDatasets.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.datasets = action.payload;
      })
      .addCase(getAllDatasets.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getDatasetCreationProgress.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.datasetsProgress = [];
      })
      .addCase(getDatasetCreationProgress.fulfilled, (state, action) => {
        state.loading = false;
        state.status = "succeeded";
        state.datasetsProgress = [...action.payload];
        console.log("getDatasetCreationProgress", state.datasetsProgress);
      })
      .addCase(getDatasetCreationProgress.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        state.error = action.payload;
        state.datasetsProgress = [];
      })
      .addCase(deleteDataset.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDataset.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.datasets = action.payload.data;
      })
      .addCase(deleteDataset.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getDatasetByQuery.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDatasetByQuery.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = action.payload;
      })
      .addCase(getDatasetByQuery.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(downloadDataset.pending, (state) => {
        state.status = "loading";
      })
      .addCase(downloadDataset.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.downloadId = action.payload?.downloadId;
      })
      .addCase(downloadDataset.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { updateEntityInfo, setCurrentEntity, updateEntitySuccess, setEntity, resetCurrentEntityInfo } =
  datasetsSlice.actions;

export default datasetsSlice.reducer;
